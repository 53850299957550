import { getStorageItem, setStorageItem } from '@app/web/src/utils/storage';
import { differenceInHours } from 'date-fns';

import { QuizProfileQuestionType } from '../reducers/quizReducer/types';

export const CURRENT_PROFILE_QUESTION_KEY = 'aha-profile-question';

export const getProfileQuestionType = (
  profile: Member,
  goal?: GetGoalRes,
  playlistHistory?: PageResponse<GetMemberQuizRes>
) => {
  if (playlistHistory && playlistHistory.data.totalCount === 0) {
    return null;
  }

  const { feedbackAt } = profile;
  if (feedbackAt && differenceInHours(new Date(), new Date(feedbackAt)) < 24) {
    return null;
  }

  const questionsMap = new Map<QuizProfileQuestionType, boolean>([
    [QuizProfileQuestionType.Username, true],
    [QuizProfileQuestionType.FullName, true],
    [QuizProfileQuestionType.Location, true],
    [QuizProfileQuestionType.Birthday, true],
    [QuizProfileQuestionType.Gender, true],
    [QuizProfileQuestionType.Bio, true],
    [QuizProfileQuestionType.HighSchool, true],
    [QuizProfileQuestionType.HighSchoolGraduation, true],
    [QuizProfileQuestionType.DreamCollege, true],
    [QuizProfileQuestionType.GoalTargetDate, true],
    [QuizProfileQuestionType.GoalTargetScore, true],
  ]);
  if (profile.isUseDefaultDistinctName === true)
    questionsMap.set(QuizProfileQuestionType.Username, false);
  if (profile.fullName === null)
    questionsMap.set(QuizProfileQuestionType.FullName, false);
  if (profile.city === null || profile.country === null)
    questionsMap.set(QuizProfileQuestionType.Location, false);
  if (profile.birthday === null || profile.birthday === '')
    questionsMap.set(QuizProfileQuestionType.Birthday, false);
  if (profile.gender === null)
    questionsMap.set(QuizProfileQuestionType.Gender, false);
  if (profile.bio === null)
    questionsMap.set(QuizProfileQuestionType.Bio, false);
  if (profile.highSchoolGraduation === null)
    questionsMap.set(QuizProfileQuestionType.HighSchoolGraduation, false);
  if (profile.highSchool === null)
    questionsMap.set(QuizProfileQuestionType.HighSchool, false);
  if (profile.dreamCollegeId === null)
    questionsMap.set(QuizProfileQuestionType.DreamCollege, false);

  if (!goal) {
    questionsMap.set(QuizProfileQuestionType.GoalTargetDate, false);
    questionsMap.set(QuizProfileQuestionType.GoalTargetScore, false);
  } else {
    if (goal.targetDate === null)
      questionsMap.set(QuizProfileQuestionType.GoalTargetDate, false);
    if (goal.targetScore === null)
      questionsMap.set(QuizProfileQuestionType.GoalTargetScore, false);
  }

  const currentQuestion = getStorageItem<QuizProfileQuestionType | null>(
    CURRENT_PROFILE_QUESTION_KEY,
    null
  );

  let currentQuestionIndex = -1;
  const allQuestions = Array.from(questionsMap.keys());
  if (currentQuestion) {
    currentQuestionIndex = allQuestions.indexOf(currentQuestion);
  }

  for (let i = currentQuestionIndex + 1; i < allQuestions.length; i++) {
    const questionType = allQuestions[i];
    if (!questionsMap.get(questionType)) {
      setStorageItem<QuizProfileQuestionType>(
        CURRENT_PROFILE_QUESTION_KEY,
        questionType
      );
      return questionType;
    }
  }

  // back to first questions
  for (let i = 0; i <= currentQuestionIndex; i++) {
    const questionType = allQuestions[i];
    if (!questionsMap.get(questionType)) {
      setStorageItem<QuizProfileQuestionType>(
        CURRENT_PROFILE_QUESTION_KEY,
        questionType
      );
      return questionType;
    }
  }
  return null;
};
