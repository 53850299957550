import { Box, BoxProps, Typography } from '@mui/material';
import { Icon, SquareAvatar } from '@front/ui';

import DeltaArrow from '../../../components/DeltaArrow';

import {
  TooltipListItemType,
  TooltipListNameType,
  TooltipListValueType,
} from './types';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) auto',
    gap: 1,
  },
  propertyWithoutName: {
    gridTemplateColumns: 'auto',
  },
  name: {
    display: 'flex',
    gap: 0.5,
    whiteSpace: 'nowrap',
    svg: {
      flexShrink: 0,
    },
  },
  value: {
    display: 'grid',
    gridTemplateColumns: 'minmax(max-content, 0) auto',
    gap: 0.5,
    alignItems: 'flex-start',
  },
  valueContent: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, max-content) 16px',
    gap: 0.5,
    alignItems: 'center',
  },
  valueContentWithoutSuffix: {
    gridTemplateColumns: 'auto',
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  avatarText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  normalWrap: {
    whiteSpace: 'normal',
  },
};

type PropertyNameProps = TooltipListNameType;

function PropertyName({
  icon,
  text,
  avatar,
  sxProps,
  wrap,
}: PropertyNameProps) {
  const sx = Array.isArray(sxProps) ? sxProps : [sxProps];
  return (
    <Box
      sx={[styles.name, !!wrap && styles.normalWrap, ...sx]}
      className="tooltip-list-item-name"
    >
      {icon && <Icon name={icon} width={16} height={16} />}
      {avatar && (
        <SquareAvatar src={avatar} size={16}>
          {text}
        </SquareAvatar>
      )}
      <Typography variant="caption">{text}</Typography>
    </Box>
  );
}

type PropertyValueProps = TooltipListValueType;

function PropertyValue(props: PropertyValueProps) {
  const sxProps = Array.isArray(props.sxProps)
    ? props.sxProps
    : [props.sxProps];

  if (props.type === 'icon') {
    return (
      <Box sx={[...sxProps]}>
        <Icon name={props.icon} width={16} height={16} />
      </Box>
    );
  }

  if (props.type === 'iconText') {
    return (
      <Box sx={[styles.value, ...sxProps]}>
        <Icon name={props.icon} width={16} height={16} />
        <Box
          sx={[
            styles.valueContent,
            !props.suffixIcon && styles.valueContentWithoutSuffix,
          ]}
        >
          <Typography
            variant="caption"
            sx={[styles.text, !!props.wrap && styles.normalWrap]}
          >
            {props.text}
          </Typography>
          {props.suffixIcon && (
            <Icon name={props.suffixIcon} width={16} height={16} />
          )}
        </Box>
      </Box>
    );
  }
  if (props.type === 'avatarText') {
    return (
      <Box sx={[styles.value, ...sxProps]}>
        <SquareAvatar src={props.avatarUrl} size={16}>
          {props.avatarText}
        </SquareAvatar>
        <Box
          sx={[
            styles.valueContent,
            !props.suffixIcon && styles.valueContentWithoutSuffix,
          ]}
        >
          <Typography
            variant="caption"
            sx={[styles.avatarText, !!props.wrap && styles.normalWrap]}
          >
            {props.avatarText}
          </Typography>
          {props.suffixIcon && (
            <Icon name={props.suffixIcon} width={16} height={16} />
          )}
        </Box>
      </Box>
    );
  }

  if (props.type === 'deltaArrowText') {
    return (
      <Box sx={[styles.value, ...sxProps]}>
        <DeltaArrow {...props} />
        <Typography variant="caption">{props.text}</Typography>
      </Box>
    );
  }

  if (props.type === 'element') {
    return (
      <Typography sx={sxProps} variant="caption" component="div">
        {props.value}
      </Typography>
    );
  }

  return (
    <Typography
      variant="caption"
      sx={[styles.text, !!props.wrap && styles.normalWrap, ...sxProps]}
    >
      {props.text}
    </Typography>
  );
}

type TooltipListItemProps = TooltipListItemType & {
  sx?: BoxProps['sx'];
};

export default function TooltipListItem({
  name,
  value,
  sx,
}: TooltipListItemProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[styles.root, !name && styles.propertyWithoutName, ...sxProps]}>
      {name && <PropertyName {...name} />}
      {value && <PropertyValue {...value} />}
    </Box>
  );
}
