import { Button } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { CtaButton } from '../types';

type FeedCtaButtonProps = CtaButton;

export default function FeedCtaButton({
  icon,
  label,
  color,
  variant,
  action,
  buttonSx,
  metadata,
  HTMLAttributes,
}: FeedCtaButtonProps) {
  const { getIaAction, iaRoutingAction } = useIaAction();

  const handleClick = () => {
    if (!action) return;

    if (action.type === 'link') {
      void iaRoutingAction(action.value);
    } else {
      const onClickAction = getIaAction<typeof metadata>(action.value);
      onClickAction?.action?.(metadata);
    }
  };

  const prefixIcon = icon ? (
    <Icon name={icon} width={16} height={16} />
  ) : undefined;

  return (
    <Button
      color={color}
      prefixIcon={prefixIcon}
      onClick={handleClick}
      variant={variant}
      sx={buttonSx}
      className="button-cta"
      {...HTMLAttributes}
    >
      {label}
    </Button>
  );
}
