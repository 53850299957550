import { MouseEvent, useCallback, useMemo } from 'react';
import { SxProps } from '@mui/material';
import { nonNullable } from '@front/helper';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import {
  TableLayoutConfig,
  TableLayoutPlugin,
  TableLayoutRow,
  TableLayoutRowMoreAction,
} from '@lib/ia/src/layouts/TableLayout/types';
import { mapValues } from 'lodash';

import useIaTablePluginActions, {
  GetPluginCellMap,
} from '../components/IaTablePlugin/useIaTablePluginActions';

export const useActionableIds = ({
  selectedIds,
  groupIdToChildIds,
}: {
  selectedIds: Set<string>;
  groupIdToChildIds: Record<string, string[]>;
}) => {
  const childIdToGroupId = useMemo(() => {
    const result: Record<string, string> = {};
    for (const [groupId, childIds] of Object.entries(groupIdToChildIds)) {
      for (const childId of childIds) {
        result[childId] = groupId;
      }
    }
    return result;
  }, [groupIdToChildIds]);

  return useMemo(() => {
    return Array.from(selectedIds).filter((id) => {
      const groupId = childIdToGroupId[id];
      return !selectedIds.has(groupId); // when groupId is selected, we don't need this child as actionable id
    });
  }, [childIdToGroupId, selectedIds]);
};

type ReturnAction = {
  icon?: string;
  iconSxProps?: SxProps;
  hint?: string;
  text: string;
  value: string;
  subActions?: ReturnAction[];
  plugin?: TableLayoutPlugin;
  onHover?: (ev: MouseEvent) => void;
  onClick: (ev: MouseEvent) => Promise<void>;
};

export const useTableToolbarActions = ({
  selectedIds,
  rows,
  childRows,
  emptyStateToolbarActions,
  onActionPerformed,
  toolbarSettings,
}: {
  selectedIds: Set<string>;
  rows: TableLayoutRow[];
  childRows: Record<string, TableLayoutRow[]>;
  onActionPerformed?: () => void;
  emptyStateToolbarActions?: TableLayoutRowMoreAction[];
  toolbarSettings?: TableLayoutConfig['settings']['toolbar'];
}): ReturnAction[] => {
  const groupIdToChildIds = useMemo(
    () => mapValues(childRows, (value) => value.map((row) => row.id)),
    [childRows]
  );
  const actionableIds = useActionableIds({
    selectedIds,
    groupIdToChildIds,
  });

  const { handleHover: handleTriggerPlugin, handleClick: handleExecutePlugin } =
    useIaTablePluginActions();
  const idToRowMap = useMemo(() => {
    const map: Record<string, TableLayoutRow> = {};
    rows.forEach((r) => {
      map[r.id] = r;
    });
    Object.values(childRows).forEach((values) => {
      values.forEach((r) => {
        map[r.id] = r;
      });
    });
    return map;
  }, [childRows, rows]);

  const buildPluginTarget = useCallback(
    (plugin: TableLayoutPlugin, ids: string[]) => {
      return ids
        .map((id) => {
          const row = idToRowMap[id];
          const cell = Object.values(row.cells).find((c) => c.type === plugin);
          return cell
            ? {
                id: row.id,
                cell: cell as GetPluginCellMap[typeof plugin],
              }
            : null;
        })
        .filter(nonNullable);
    },
    [idToRowMap]
  );

  const { getIaAction } = useIaAction();

  return useMemo(() => {
    const excludeActions = toolbarSettings?.excludeActions || [];
    if (actionableIds.length === 0) {
      return [];
    }

    const [firstId, ...restIds] = actionableIds;
    const firstRow = idToRowMap[firstId];

    if (!firstRow) {
      return [];
    }

    // find those actions every selected id has
    const actionsAcrossAllSelectedRow = (
      moreActions: TableLayoutRowMoreAction[],
      parentActionValue?: string
    ): TableLayoutRowMoreAction[] => {
      return moreActions
        .filter(({ value, type }) => {
          return (
            type === 'event' &&
            restIds
              .map((id) => idToRowMap[id])
              .filter(Boolean)
              .every((row) => {
                if (parentActionValue) {
                  const parentAction = row.moreActions?.find(
                    (action) => action.value === parentActionValue
                  );

                  return parentAction?.subActions?.some(
                    (subAction) => subAction.value === value
                  );
                }

                return row.moreActions?.some(
                  (action) => action.value === value
                );
              })
          );
        })
        .filter(({ value }) => {
          if (excludeActions.length === 0) return true;
          return !excludeActions.some((action) => action === value);
        })
        .map((action) => ({
          ...action,
          subActions: action.subActions
            ? actionsAcrossAllSelectedRow(action.subActions, action.value)
            : undefined,
        }));
    };

    const mapToAcrossAction = ({
      icon,
      iconSxProps,
      hint,
      text,
      value,
      subActions,
      plugin,
      ...rest
    }: {
      icon?: string;
      iconSxProps?: SxProps;
      hint?: string;
      text: string;
      value: string;
      subActions?: {
        icon?: string;
        text: string;
        value: string;
      }[];
      plugin?: TableLayoutPlugin;
    }): ReturnAction => {
      return {
        icon,
        iconSxProps,
        text,
        value,
        hint,
        subActions: subActions?.map(mapToAcrossAction),
        onHover: (ev: MouseEvent) => {
          if (plugin) {
            const selectedItems = buildPluginTarget(plugin, actionableIds);

            if (selectedItems.length) {
              handleTriggerPlugin(
                ev,
                { plugin, value },
                selectedItems,
                'toolbar'
              );
            }
          }
        },
        onClick: async (ev: MouseEvent) => {
          if (plugin) {
            const selectedItems = buildPluginTarget(plugin, actionableIds);

            if (selectedItems.length) {
              ev.stopPropagation();
              ev.preventDefault();
              handleExecutePlugin({ plugin, value }, selectedItems);
            }
            return;
          }

          const iaAction = getIaAction<{ id: string }>(value);
          if (!iaAction) {
            return;
          }

          if (iaAction.batchAction) {
            await iaAction.batchAction(
              actionableIds.map((id) => ({
                id,
              }))
            );
            onActionPerformed?.();
          } else {
            for (const id of actionableIds) {
              await iaAction.action({ id });
            }
            onActionPerformed?.();
          }
        },
        ...rest,
      };
    };

    const displayActions = firstRow.moreActions
      ? actionsAcrossAllSelectedRow(firstRow.moreActions).map(mapToAcrossAction)
      : [];

    if (
      displayActions.length === 0 &&
      !!emptyStateToolbarActions &&
      emptyStateToolbarActions?.length > 0
    )
      return emptyStateToolbarActions.map(mapToAcrossAction);
    return displayActions;
  }, [
    toolbarSettings?.excludeActions,
    actionableIds,
    idToRowMap,
    emptyStateToolbarActions,
    buildPluginTarget,
    handleTriggerPlugin,
    getIaAction,
    handleExecutePlugin,
    onActionPerformed,
  ]);
};
