import Box, { BoxProps } from '@mui/material/Box';
import { Icon } from '@front/ui';

export type BreadcrumbsItemProps = {
  text?: string;
  icon?: React.ReactNode | string;
  customizeComponent?: React.ReactNode;
  sx?: BoxProps['sx'];
};

const styles = {
  root: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'min-content 1fr',
    gap: 1,
    minWidth: 0,
    paddingRight: '4px',

    // To support dynamic component, we use order to select the right component
    '& > *:nth-of-type(1)': {
      // icon
      display: 'flex',
      alignItems: 'center',
    },
    '& > *:nth-of-type(2)': {
      // text
      minWidth: 0,
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};
export default function BreadcrumbsItem({
  text,
  icon,
  customizeComponent,
  sx,
}: BreadcrumbsItemProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  if (!customizeComponent) {
    return (
      <Box className="breadcrumbs-item" sx={[styles.root, ...sxProps]}>
        <Box>{typeof icon === 'string' ? <Icon name={icon} /> : icon}</Box>
        <Box>{text}</Box>
      </Box>
    );
  }

  if (text || icon) {
    console.warn(
      'BreadcrumbsItem: text and icon will be ignored if component is provided'
    );
  }

  return (
    <Box className="breadcrumbs-item" sx={[styles.root, ...sxProps]}>
      {customizeComponent}
    </Box>
  );
}
