import { TFunction } from 'next-i18next';

const getPracticePageConfig = (t: TFunction) => ({
  practiceResults: {
    title: t('practice.results.title'),
    icon: t('practice.results.icon'),
  },
  practiceResultsAwards: {
    title: t('practice.results_awards.title'),
    icon: t('practice.results_awards.icon'),
  },
});

export default getPracticePageConfig;
