import {
  cloneElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  RefObject,
  useRef,
  useState,
} from 'react';
import {
  Box,
  ButtonBaseProps,
  PopperPlacementType,
  SxProps,
  Typography,
} from '@mui/material';
import { ActionChevronDown as ActionChevronDownIcon } from '@front/icon';
import {
  Icon,
  InfoTooltip,
  PopperProps,
  ResponsiveDropdown,
  SimpleTooltip,
  useTruncated,
} from '@front/ui';

const styles = {
  dropdownPopper: {
    zIndex: 20,
    '& .popper-content': {
      minWidth: 'max-content',
    },
    '& .MuiButtonBase-root.Mui-disabled': {
      // to able to show tooltip
      pointerEvents: 'auto',
    },
    '& .MuiButtonBase-root': {
      minWidth: '100%',
      width: 'max-content',
    },
  },
  option: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr auto',
    gap: 1,
    alignItems: 'center',
    fontSide: 14,
  },
  optionDisabled: {
    opacity: 0.5,
    cursor: 'default',
  },
  optionTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export type DropdownButtonOption = {
  display: string;
  icon: string | ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  tooltip?: {
    icon: string;
    title: string;
    content: ReactNode;
  };
  suffix?: ReactNode;
};

type RenderOptionProps = Omit<DropdownButtonOption, 'onClick'>;

const RenderOption = ({
  display,
  icon,
  disabled,
  tooltip,
  suffix,
}: RenderOptionProps) => {
  const { containerRef, isTruncated } = useTruncated();

  const option = (
    <Box sx={[styles.option, !!disabled && styles.optionDisabled]}>
      {typeof icon === 'string' ? <Icon name={icon} size={16} /> : icon}
      <SimpleTooltip title={isTruncated ? display : ''} disableInteractive>
        <Typography variant="body2" sx={styles.optionTitle} ref={containerRef}>
          {display}
        </Typography>
      </SimpleTooltip>
      {suffix && <Box>{suffix}</Box>}
    </Box>
  );
  if (!tooltip) return option;
  return (
    <InfoTooltip
      title={tooltip.title}
      titleIcon={<Icon name={tooltip.icon} width={16} height={16} />}
      content={tooltip.content}
      followCursor
    >
      {option}
    </InfoTooltip>
  );
};

type DropdownProps = {
  open: boolean;
  anchorEl?: HTMLElement | null;
  options?: DropdownButtonOption[];
  onClick: (option: DropdownButtonOption) => void;
  onClose: () => void;
  configs?: {
    sx?: SxProps;
    placement?: PopperPlacementType;
    popperOptions?: PopperProps['popperOptions'];
  };
};

function Dropdown({
  open,
  anchorEl,
  options = [],
  onClick,
  onClose,
  configs = {},
}: DropdownProps) {
  const { sx, placement = 'bottom-start' } = configs;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <ResponsiveDropdown
      open={open}
      options={options}
      onClick={(option, ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        onClick(option);
      }}
      onClose={onClose}
      menuDropdownProps={{
        anchorEl: anchorEl,
        popperProps: {
          sx: [styles.dropdownPopper, ...sxProps],
          placement,
          popperOptions: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ],
            ...configs.popperOptions,
          },
        },
      }}
      renderOption={(ev, disabled) => (
        <RenderOption
          display={ev.display}
          icon={ev.icon}
          suffix={ev.suffix}
          tooltip={ev.tooltip}
          disabled={disabled}
        />
      )}
      isOptionDisabled={(option) => !!option.disabled}
    />
  );
}

type RenderButtonProps = {
  tooltip?: {
    title: string;
    icon: string | ReactNode;
    content: ReactNode;
  };
  tooltipDisabled?: boolean;
  children: ReactElement<ButtonBaseProps & { suffixIcon?: ReactNode }>;
  onClick?: () => void;
  suffixIcon?: ReactNode;
  buttonRef?: RefObject<HTMLButtonElement>;
};

function RenderButton({
  tooltip,
  tooltipDisabled,
  suffixIcon,
  onClick,
  buttonRef,
  children,
}: RenderButtonProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const suffixIconProp =
    !!suffixIcon || !!children.props.suffixIcon
      ? { suffixIcon: suffixIcon || children.props.suffixIcon }
      : {};

  const button = cloneElement(children, {
    onClick: (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onClick?.();
      children.props.onClick?.(e);
    },
    onMouseDown: (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      children.props.onMouseDown?.(e);
    },
    ref: buttonRef,
    ...suffixIconProp,
  });

  if (!tooltip) return button;
  return (
    <InfoTooltip
      title={tooltip.title}
      titleIcon={
        typeof tooltip.icon === 'string' ? (
          <Icon name={tooltip.icon} width={16} height={16} />
        ) : (
          tooltip.icon
        )
      }
      content={tooltip.content}
      disableHoverListener
      open={tooltipOpen && !tooltipDisabled}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      {button}
    </InfoTooltip>
  );
}

type DropdownButtonProps = {
  options?: DropdownButtonOption[];
  tooltip?: {
    title: string;
    icon: string | ReactNode;
    content: ReactNode;
  };
  buttonRef?: RefObject<HTMLButtonElement>;
  children: ReactElement<ButtonBaseProps & { suffixIcon?: ReactNode }>;
  dropdownProps?: {
    sx?: SxProps;
    placement?: PopperPlacementType;
    popperOptions?: PopperProps['popperOptions'];
  };
  suffixIconDisabled?: boolean;
};

export default function DropdownButton({
  children,
  options = [],
  tooltip,
  buttonRef,
  dropdownProps = {},
  suffixIconDisabled = false,
}: DropdownButtonProps) {
  const localRef = useRef<HTMLButtonElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClick = () => {
    if (options.length) {
      setDropdownOpen((prev) => !prev);
      return;
    }
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  const handleDropdownClick = (option: DropdownButtonOption) => {
    setDropdownOpen(false);
    option.onClick?.();
  };

  const ref = buttonRef || localRef;

  return (
    <>
      <RenderButton
        buttonRef={ref}
        onClick={handleClick}
        suffixIcon={
          !suffixIconDisabled && options.length && <ActionChevronDownIcon />
        }
        tooltip={tooltip}
        tooltipDisabled={dropdownOpen}
      >
        {children}
      </RenderButton>
      <Dropdown
        open={dropdownOpen}
        anchorEl={ref.current}
        onClose={handleCloseDropdown}
        onClick={handleDropdownClick}
        options={options}
        configs={dropdownProps}
      />
    </>
  );
}
