import { useRef } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { extractNumber } from '@lib/ia/src/utils/number';

import { TableLayoutNumberCell, TableLayoutRow } from '../../types';
import IaTextBasedEditableCell from '../IaTableEditable/IaTextBasedEditableCell';

export type IaNumberCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutNumberCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    /**
     * when wrap is on, we would like to make number align top by alignItems: 'flex-start',
     * but this will make number not align to center when there is only one line,
     * so we add a small pt to make it align to center
     */
    pt: 0.5,
    justifyContent: 'flex-end',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  clickable: {
    cursor: 'pointer',
  },
};
export default function IaNumberCell({
  row,
  columnKey,
  cell,
}: IaNumberCellProps) {
  const { getIaAction } = useIaAction();
  const {
    value,
    fractionDigits,
    keepFractionDigitsWhenZero = false,
    asSequence = false,
    editable,
    actionMap,
    placeholder,
    sx,
  } = cell;

  let displayValue;

  if (value === null) {
    displayValue = '';
  } else if (asSequence) {
    displayValue = value.toString();
  } else if (value === 0 && !keepFractionDigitsWhenZero) {
    displayValue = 0;
  } else {
    displayValue = value.toFixed(fractionDigits);
  }

  const clickAction = getIaAction<{ row: TableLayoutRow }>(actionMap?.click);
  const maybeClickableProps = clickAction
    ? { onClick: () => clickAction.action({ row }) }
    : {};
  const ref = useRef<HTMLDivElement>();

  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <>
      <Box
        sx={[styles.root, !!clickAction && styles.clickable, ...sxProps]}
        ref={ref}
        {...maybeClickableProps}
      >
        <Typography variant="body2" sx={styles.text}>
          {displayValue}
        </Typography>
      </Box>
      <IaTextBasedEditableCell<number | null>
        containerRef={ref}
        actionMap={actionMap}
        editable={editable}
        placeholder={placeholder}
        columnKey={columnKey}
        row={row}
        initValue={value}
        filterValue={(val: string) => extractNumber(val)}
        inputSx={{
          '& .MuiInputBase-input': {
            textAlign: 'right',
          },
        }}
      />
    </>
  );
}
