import { createRoot } from 'react-dom/client';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ExamQuestionMaterialType } from '@lib/web/apis';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { ChartRenderBox } from '@lib/web/quiz';
import {
  translateBreakline,
  translateFigure,
  translateFormula,
  translatePassage,
} from '@lib/web/utils';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { Attrs } from 'prosemirror-model';
import { v4 } from 'uuid';

import ThemeProvider from '../../../../components/ThemeProvider';

const BREAK_LINES_CLASS = 'break-line';
export const FOLLOW_BREAK_LINES_CLASS = 'follow-break-line';

const styles = {
  passageContent: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
    '& > p': {
      mt: 0,
      mb: 3,
      textIndent: '40px',
      '&:last-child': {
        mb: 0,
      },
    },
    '& .break-line': {
      opacity: 0,
      visibility: 'hidden',
      width: 0,
      height: 0,
      display: 'inline-block',
      overflow: 'hidden',
    },
  },
  passageInnerContent: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
  },

  chartContent: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
    '& > p': {
      mt: 0,
      mb: 3,
      textIndent: '40px',
      '&:last-child': {
        mb: 0,
      },
    },
    '& .break-line': {
      opacity: 0,
      visibility: 'hidden',
      width: 0,
      height: 0,
      display: 'inline-block',
      overflow: 'hidden',
    },
  },
  chartInnerContent: {
    '& .chart-render-inner': {
      p: 0,
    },
  },
};

type BlockProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

export const legacyMaterialBlockDefaultProps = {
  content: {
    default: '',
  },
  type: {
    default: '',
  },
  subType: {
    default: '',
  },
};

const LegacyMaterialContent = ({ attrs }: { attrs: Attrs }) => {
  return (
    <ThemeProvider mode="dark">
      {attrs.type === ExamQuestionMaterialType.Passage && (
        <Box sx={styles.passageContent} pl={3}>
          <Typography
            sx={styles.passageInnerContent}
            dangerouslySetInnerHTML={{
              __html: translateBreakline(
                translatePassage(attrs.content),
                BREAK_LINES_CLASS
              ),
            }}
          />
        </Box>
      )}

      {attrs.type !== ExamQuestionMaterialType.Passage && (
        <Box sx={styles.chartContent}>
          <ChartRenderBox
            sx={styles.chartInnerContent}
            mappedMaterials={{ material: attrs as GetQuizMaterialRes }}
            tex={translateFormula(
              translateFigure('{%material%}', {
                material: attrs as GetQuizMaterialRes,
              })
            )}
          />
        </Box>
      )}
    </ThemeProvider>
  );
};

const LegacyMaterial = ({ node }: BlockProps) => {
  return (
    <NodeViewWrapper
      data-content-type={node.type.name}
      className="blockContent"
    >
      <LegacyMaterialContent attrs={node.attrs} />
    </NodeViewWrapper>
  );
};

export type LegacyMaterialOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: LegacyMaterialOptions;
    node: ProseMirrorNode;
  }) => string;
};

export const LegacyMaterialBlock = Node.create<LegacyMaterialOptions>({
  name: BasicBlockTypes.LegacyMaterial,
  group: 'blockContent',
  inline: false,
  selectable: false,
  atom: true,

  addAttributes() {
    return legacyMaterialBlockDefaultProps;
  },

  parseHTML() {
    return [
      {
        tag: 'legacy-material',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { content, ...rest } = HTMLAttributes;
    const id = v4();

    setTimeout(() => {
      const el = document.querySelector(`[data-material-id="${id}"]`);

      if (!el) return;

      const root = createRoot(el);
      root.render(<LegacyMaterialContent attrs={HTMLAttributes} />);
    });

    return [
      'div',
      mergeAttributes(rest, {
        'data-content-type': this.name,
        'data-material-id': id,
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(LegacyMaterial);
  },
});

export default LegacyMaterialBlock;
