import { useState } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';

import { Spotlight } from '../../atoms';

import PinMessage, { PinMessageProps } from './components/PinMessage';
import PinStep, { PinStepProps } from './components/PinStep';

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    opacity: 0,
    userSelect: 'none',
    transitionDuration: '0.3s',
    transitionProperty: 'opacity',
  },
  coveredBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(8, 8, 8, 0.75)',
  },
};
export type PinChatProps = PinStepProps &
  Omit<PinMessageProps, 'progress'> & {
    x?: number;
    y?: number;
  };

export const getPinChatVariant = (step: number, currentStep: number) => {
  if (step === currentStep) {
    return 'active';
  }
  if (step < currentStep) {
    return 'viewed';
  }
  return 'new';
};

export default function PinChat({
  variant,
  step,
  totalStep,
  creatorDisplay,
  creatorAvatarComponent,
  message,
  arrowDirection = 'left-bottom',
  x,
  y,
  onHide,
  onNext,
  onFinish,
}: PinChatProps) {
  const [clicked, setClicked] = useState(false); // mobile only
  const progress = step && totalStep ? step / totalStep : 0;
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const show = x !== undefined && y !== undefined;

  const updatedVariant =
    variant === 'active' && mdDown && !clicked ? 'new' : variant;
  const isActive = variant === 'active';
  const showMessageBox = updatedVariant === 'active';

  return (
    <>
      {showMessageBox && mdDown && <Box sx={styles.coveredBackground} />}
      {isActive && (
        <Spotlight disablePortal x={x} y={y} onClick={() => setClicked(true)} />
      )}
      <Box
        sx={[
          styles.root,
          {
            opacity: show ? 1 : 0,
            transform: show ? `translate(${x}px, ${y}px)` : undefined,
            zIndex: isActive ? 'tooltip' : 'modal',
          },
        ]}
      >
        <PinStep
          variant={updatedVariant}
          arrowDirection={arrowDirection}
          step={step}
          onClick={() => setClicked(true)}
        />

        {isActive && (
          <PinMessage
            x={x}
            y={y}
            open={showMessageBox}
            progress={progress}
            arrowDirection={arrowDirection}
            step={step}
            totalStep={totalStep}
            creatorDisplay={creatorDisplay}
            creatorAvatarComponent={creatorAvatarComponent}
            message={message}
            onFinish={onFinish}
            onHide={onHide}
            onNext={onNext}
            onRevert={() => setClicked(false)}
          />
        )}
      </Box>
    </>
  );
}
