import { Box, ButtonBase, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';
import { Button } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { useIaItemStatus } from '@lib/ia/src/core/IaItemStatus/useIaItemStatus';

import Icon from '../../../../components/Icon';
import {
  DisplayTableLayoutButtonCell,
  DisplayTableLayoutRow,
} from '../../types';

export type IaDisplayTableIconCellProps = DisplayTableLayoutButtonCell & {
  row: DisplayTableLayoutRow;
};

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    pl: 1,
  },
  button: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 1,
    '&.display-table-button': {
      minWidth: 0,
      minHeight: 0,
      px: '5px',
      gap: 0.5,
      '& .MuiTypography-root': {
        fontSize: 12,
      },
    },
  },
  text: {
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaDisplayTableButtonCell({
  row,
  icon,
  text,
  disabledText,
  action,
  buttonComponent,
  buttonSxProps,
}: IaDisplayTableIconCellProps) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { getIaAction } = useIaAction();
  const { getItemStatus } = useIaItemStatus<DisplayTableLayoutRow>();
  const iaAction = getIaAction<DisplayTableLayoutRow>(action);
  const eventActionProps = {
    onClick: () => iaAction?.action(row),
  };

  const loading =
    typeof iaAction?.inProgress === 'function'
      ? iaAction?.inProgress(row)
      : iaAction?.inProgress;

  const { disabled = false } = getItemStatus ? getItemStatus(row) : {};

  const buttonSx = Array.isArray(buttonSxProps)
    ? buttonSxProps
    : [buttonSxProps];

  const Component = buttonComponent === 'button' ? Button : ButtonBase;
  const className =
    buttonComponent === 'button'
      ? 'display-table-button'
      : 'display-table-button-base';

  const showButtonText = !mdDown || !icon;

  return (
    <Box sx={styles.root}>
      <Component
        sx={[styles.button, ...buttonSx]}
        disableRipple
        size="sm"
        className={className}
        disabled={disabled}
        loading={loading}
        {...eventActionProps}
      >
        {icon && <Icon name={icon} width={16} height={16} />}
        {showButtonText && (
          <Typography variant="body2" sx={styles.text}>
            {disabled ? disabledText || text : text}
          </Typography>
        )}
      </Component>
    </Box>
  );
}
