import React, { ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BottomDropdown, {
  BottomDropdownProps,
} from '../BottomDropdown/BottomDropdown';
import MenuDropdown from '../MenuDropdown';
import { MenuDropdownProps } from '../MenuDropdown/MenuDropdown';

type BaseOption<T> = {
  display: ReactNode;
  options?: T[];
  section?: string;
};
export type ResponsiveDropdownProps<T extends BaseOption<T>> = {
  options: T[];
  open: boolean;
  renderOption?: MenuDropdownProps<T>['renderOption'];
  isOptionDisabled?: MenuDropdownProps<T>['isOptionDisabled'];
  isOptionEqualToValue?: MenuDropdownProps<T>['isOptionEqualToValue'];
  onClick: MenuDropdownProps<T>['onClick'];
  onHover?: MenuDropdownProps<T>['onHover'];
  onClose: () => void;
  menuDropdownProps?: Pick<MenuDropdownProps<T>, 'anchorEl' | 'popperProps'>;
  bottomDropdownProps?: Pick<
    BottomDropdownProps<T>,
    'label' | 'maxShowItem' | 'bottomSheetProps'
  >;
  breakpoint?: 'sm' | 'md' | 'lg';
};

export default function ResponsiveDropdown<T extends BaseOption<T>>({
  breakpoint = 'md',
  menuDropdownProps = {},
  bottomDropdownProps = {},
  onHover,
  ...rest
}: ResponsiveDropdownProps<T>) {
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(breakpoint)
  );

  if (isDesktop)
    return <MenuDropdown onHover={onHover} {...rest} {...menuDropdownProps} />;
  return <BottomDropdown {...rest} {...bottomDropdownProps} />;
}
