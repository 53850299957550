import { useCallback, useMemo } from 'react';
import { nonNullable } from '@front/helper';
import {
  buildHookMutate,
  QueryThreadViewType,
  ThreadViewOrderBy,
  ThreadViewSortBy,
  useAuth,
  useThreadViews,
} from '@lib/web/apis';
import { useDefaultDmEveryoneThreadView } from '@lib/web/thread/hooks/defaultView/useDefaultDmEveryoneThreadView';

import { generateEmptyDmView, parseFilterConfig } from '../../utils/viewUtils';
import { useThread } from '../core/useThread';
import { useDefaultDmSelfThreadView } from '../defaultView/useDefaultDmSelfThreadView';
import { useSuggestionThreadViews } from '../defaultView/useSuggestionThreadViews';

export const useDirectMessageViews = () => {
  const { member } = useAuth();
  const { data, isLoading, mutate } = useThreadViews({
    type: QueryThreadViewType.DmAll,
    sortBy: ThreadViewSortBy.Desc,
    orderBy: ThreadViewOrderBy.CreatedAt,
  });
  const dataViews = useMemo(() => data?.data || [], [data?.data]);
  const { defaultSelfView, defaultSelfViewHasChannels } =
    useDefaultDmSelfThreadView();
  const { getThreadUser } = useThread();

  const { defaultEveryoneView } = useDefaultDmEveryoneThreadView();

  const myMemberId = member?.memberId || '';

  const isSelfView = useCallback(
    (view: GetThreadViewRes): boolean => {
      const { memberIds } = parseFilterConfig(
        view.threadViewUsers[0]?.filterConfig
      );
      return memberIds.length === 1 && memberIds[0] === myMemberId;
    },
    [myMemberId]
  );

  const isMultiMemberView = useCallback(
    (view: GetThreadViewRes): boolean => {
      const { memberIds } = parseFilterConfig(
        view.threadViewUsers[0]?.filterConfig
      );
      return memberIds.length > 1 && memberIds.includes(myMemberId);
    },
    [myMemberId]
  );

  const views = useMemo<GetThreadViewRes[]>(() => {
    const selfViews = dataViews.filter(isSelfView);
    const otherDMViews = dataViews.filter(isMultiMemberView);

    return [
      defaultEveryoneView,
      selfViews[0] ?? defaultSelfView,
      ...otherDMViews,
    ].filter(nonNullable);
  }, [
    dataViews,
    defaultEveryoneView,
    defaultSelfView,
    isMultiMemberView,
    isSelfView,
  ]);

  const reloadViews = useMemo(() => buildHookMutate(mutate), [mutate]);

  // How do we fetch suggestion is not listed in PRF-1020, we can check the logic if other PRF describe for this
  const suggestionViews = useSuggestionThreadViews({
    showSuggestions: views.length === 2, // when there are only two everyone and self view, show the suggestions
  });

  const findDmView = useCallback(
    (viewIdOrMemberId: string) => {
      const existingView = views.find((v) => v.id === viewIdOrMemberId);
      if (existingView) {
        return existingView;
      }

      const suggestionView = suggestionViews.find(
        (v) => v.id === viewIdOrMemberId
      );
      if (suggestionView) {
        return suggestionView;
      }

      // From a user or agent that has not yet chatted with
      const threadUser = getThreadUser(viewIdOrMemberId);
      // Check whether it is a real user or a real agent
      if (threadUser?.accountStatus === 'active') {
        return generateEmptyDmView({
          myMemberId,
          memberId: threadUser.id || '',
        });
      }
    },
    [getThreadUser, myMemberId, suggestionViews, views]
  );

  return {
    views,
    suggestionViews,
    firstHasChannelViewId: dataViews[0]
      ? dataViews[0]?.id
      : defaultSelfViewHasChannels
      ? defaultSelfView.id
      : null,
    isLoading,
    reloadViews,
    findDmView,
  };
};
