import { ReactNode } from 'react';

export type IaDisplayTableHeaderProps = {
  children: ReactNode;
};

export default function IaDisplayTableHeader({
  children,
}: IaDisplayTableHeaderProps) {
  return children;
}
