import { TFunction } from 'i18next';

const getProfilePageConfig = (t: TFunction) => ({
  account: {
    title: t('profile.account.title'),
    icon: t('profile.account.icon'),
  },
  accountEdit: {
    title: t('profile.account_edit.title'),
    icon: t('profile.account_edit.icon'),
  },
  discountCode: {
    title: t('profile.discountCode.title'),
    icon: t('profile.discountCode.icon'),
  },
  discountCodeManagement: {
    title: t('profile.discountCode_management.title'),
    icon: t('profile.discountCode_management.icon'),
  },
  myPlan: {
    title: t('profile.myPlan.title'),
    icon: t('profile.myPlan.icon'),
  },
  awards: {
    title: t('profile.awards.title'),
    icon: t('profile.awards.icon'),
  },
  awardsAlmostThere: {
    title: t('profile.awards_almostThere.title'),
    icon: t('profile.awards_almostThere.icon'),
  },
  awardsByCategory: {
    title: t('profile.awards_byCategory.title'),
    icon: t('profile.awards_byCategory.icon'),
  },
  myAvatars: {
    title: t('profile.myAvatars.title'),
    icon: t('profile.myAvatars.icon'),
  },
  settings: {
    title: t('profile.settings.title'),
    icon: t('profile.settings.icon'),
  },
  wallet: {
    title: t('profile.wallet.title'),
    icon: t('profile.wallet.icon'),
  },
  walletAhaPoints: {
    title: t('profile.wallet_ahaPoints.title'),
    icon: t('profile.wallet_ahaPoints.icon'),
  },
});

export default getProfilePageConfig;
