import { PropertyType } from '@front/ui';
import { SortFieldsConfig } from '@lib/ia/src/sort/types';
import { useLocalesInfo } from '@lib/web/apis';

import { SortType } from '../types';

type AdditionalData = {
  locales: LocaleInfo[];
};

const GlobalSortConfigs = (
  data: AdditionalData
): Record<SortType, SortFieldsConfig | null> => ({
  club: {
    fields: [
      {
        label: 'User Locale',
        name: 'priorityLocale',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        customDirectionOptions: data.locales.map((locale) => ({
          type: 'rawQuery',
          value: `customDir_${locale.id}`,
          label: locale.name,
          rawQueryParams: {
            search: `priorityLocale:${locale.id}`,
            searchFields: 'priorityLocale:in',
          },
        })),
      },
      {
        label: 'Club Type',
        name: 'officialType',
        type: PropertyType.Status,
        customDirectionOptions: [
          {
            value: 'customDir_official',
            label: 'Official',
            direction: 'desc',
          },
          {
            value: 'customDir_nonOfficial',
            label: 'Non-Official',
            direction: 'asc',
          },
        ],
      },
      {
        label: 'Club Name',
        name: 'clubName',
        type: PropertyType.ShortText,
      },
      {
        label: 'Club Members',
        name: 'clubMemberCount',
        type: PropertyType.Number,
      },
      {
        label: 'Joined Date',
        name: 'joinedAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
    ],
  },
  clubGroup: {
    fields: [
      {
        label: 'Club Group Name',
        name: 'name',
        type: PropertyType.ShortText,
      },
      {
        label: 'Club Group Type',
        name: 'officialType',
        type: PropertyType.Status,
        customDirectionOptions: [
          {
            value: 'customDir_official',
            label: 'Official',
            direction: 'desc',
          },
          {
            value: 'customDir_nonOfficial',
            label: 'Non-Official',
            direction: 'asc',
          },
        ],
      },
    ],
  },
  agent: {
    fields: [
      {
        label: 'AI Name',
        name: 'agentName',
        type: PropertyType.ShortText,
      },
    ],
  },
  playlist: {
    fields: [
      {
        label: 'Answer Result',
        name: 'practiceResult',
        type: PropertyType.Select,
        customIcon: 'TextEditorCheck',
      },
      {
        label: 'Overtime Status',
        name: 'overtimeStatus',
        type: PropertyType.Status,
      },
      {
        label: 'Question Number',
        name: 'questionNumber',
        type: PropertyType.Number,
      },
      {
        label: 'Question Content',
        name: 'questionContent',
        type: PropertyType.LongText,
      },
      {
        label: 'Reaction',
        name: 'emoji',
        type: PropertyType.Icon,
      },
    ],
  },
  playlistDetailRhs: {
    fields: [
      {
        label: 'Answer Result',
        name: 'practiceResult',
        type: PropertyType.Select,
        customIcon: 'TextEditorCheck',
      },
      {
        label: 'Overtime Status',
        name: 'overtimeStatus',
        type: PropertyType.Status,
      },
      {
        label: 'Question Number',
        name: 'questionNumber',
        type: PropertyType.Number,
      },
      {
        label: 'Question Content',
        name: 'questionContent',
        type: PropertyType.LongText,
      },
      {
        label: 'Reaction',
        name: 'emoji',
        type: PropertyType.Icon,
      },
    ],
  },
  marketplace: {
    fields: [
      {
        label: 'Date Listed',
        name: 'dateListed',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: 'Price',
        name: 'price',
        type: PropertyType.Number,
        customIcon: 'OtherDollar',
      },
    ],
  },
  quiz: {
    fields: [
      {
        label: 'Quiz Name',
        name: 'name',
        type: PropertyType.ShortText,
      },
      {
        label: 'Quiz Question Count',
        name: 'questionCount',
        type: PropertyType.Number,
      },
      {
        label: 'Quiz Tokens',
        name: 'questionCount',
        type: PropertyType.Number,
      },
    ],
  },
  creatorQuiz: {
    fields: [
      {
        label: 'Created By',
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  emojiQuiz: {
    fields: [
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  hashtagQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'hashtag',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  followingQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'hashtag',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Score',
        name: 'name',
        type: PropertyType.Number,
        customIcon: 'TestScoreTally',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  historyQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created By',
        name: 'creator:displayName',
        type: PropertyType.Person,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: 'Saved Status',
        name: 'savedStatus',
        type: PropertyType.Status,
      },
    ],
  },
  challengeQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created By',
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: 'Saved Status',
        name: 'savedStatus',
        type: PropertyType.Status,
      },
      {
        label: 'Challenge Attempts',
        name: 'attempts',
        type: PropertyType.Number,
        customIcon: 'MainChallenge',
      },
      {
        label: 'Challenge End Date',
        name: 'endDate',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: 'Challengers',
        name: 'challengers',
        type: PropertyType.Person,
      },
      {
        label: 'Challenge Rank',
        name: 'rank',
        type: PropertyType.Number,
        customIcon: 'TestTrophy',
      },
    ],
  },
  bookmarkQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created By',
        name: 'creator:displayName',
        type: PropertyType.Person,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: 'Saved Status',
        name: 'savedStatus',
        type: PropertyType.Status,
      },
    ],
  },
  playlistQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: 'Practice Count',
        name: 'practiceCount',
        type: PropertyType.Number,
      },
      {
        label: 'Question Tokens',
        name: 'questionTokenNum',
        type: PropertyType.Number,
      },
    ],
  },
  discountCodeManagement: {
    fields: [
      {
        label: 'Discount Code',
        name: 'code',
        type: PropertyType.ShortText,
      },
      {
        label: 'Discount Type',
        name: 'discountType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
      },
      {
        label: 'Value',
        name: 'discountVal',
        type: PropertyType.Number,
      },
      {
        label: 'Usage Type',
        name: 'usageType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
      },
      {
        label: 'Usage Limit',
        name: 'redeemLimit',
        type: PropertyType.Number,
      },
      {
        label: 'Purchase Type',
        name: 'purchaseType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
      },
      {
        label: 'Start Date',
        name: 'startedAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: 'Expiration Date',
        name: 'expiredAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: 'Created By',
        name: 'creator',
        type: PropertyType.Person,
      },
    ],
  },
  threadChannel: {
    fields: [
      {
        label: 'Created By',
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Visibility',
        name: 'visibility',
        type: PropertyType.Select,
        customIcon: 'LoginSee',
      },
      {
        label: 'Member Count',
        name: 'memberCount',
        type: PropertyType.Number,
        customIcon: 'PrivacyFriends',
      },
      {
        label: 'Last activity Time',
        name: 'lastActivityTime',
        type: PropertyType.DateOrTime,
      },
    ],
  },
  threadMessage: {
    fields: [
      {
        label: 'Message Text',
        name: 'messageText',
        type: PropertyType.ShortText,
      },
      {
        label: 'Reply Count',
        name: 'replyCount',
        type: PropertyType.Number,
      },
      {
        label: 'User',
        name: 'userId',
        type: PropertyType.Person,
      },
      {
        label: 'Created Time',
        name: 'createdAt',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Updated Time',
        name: 'updatedAt',
        type: PropertyType.DateOrTime,
      },
    ],
  },
});

export default function useGlobalSortConfigs() {
  const { data: localeData } = useLocalesInfo();
  const locales = localeData?.data || [];
  return GlobalSortConfigs({ locales });
}
