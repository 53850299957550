import { camelize } from './string';

export const getObjFromElementAttr = (
  valueObj: { [name: string]: string },
  dataKey = 'data-metadata'
) => {
  return Object.keys(valueObj)
    .filter((key) => key.includes(dataKey))
    .reduce((acc, cur) => {
      const curKey = camelize(cur.replace(`${dataKey}-`, ''));
      return {
        ...acc,
        [curKey]: valueObj[cur],
      };
    }, {});
};
