import { useCallback, useEffect, useState } from 'react';
import { apis, useAuth, useMemberInfo } from '@lib/web/apis';
import { call } from '@lib/web/utils';
import { StreamChat, TokenOrProvider, User } from 'stream-chat';

/**
 * useClient is mostly reference from official sample
 * @param apiKey
 * @param user
 * @param tokenOrProvider
 */
const useClient = ({
  apiKey,
  user,
  tokenOrProvider,
}: {
  apiKey?: string;
  user?: User | null;
  tokenOrProvider: TokenOrProvider;
}): StreamChat | undefined => {
  const [chatClient, setChatClient] = useState<StreamChat>();

  useEffect(() => {
    if (!apiKey || !user) return;

    const client = StreamChat.getInstance(apiKey, {
      /**
       *   the default timeout is 3 seconds, which might be too short in some cases
       *   e.g. for specific user, it might have too many channels to query, or the filter is too complex
       *   so we increase the timeout to 10 seconds
       *   see https://support.getstream.io/hc/en-us/articles/11705543817751-Timeout-of-3000ms-exceeded-what-to-do for more details
       */
      timeout: 10000,
    });

    // prevents application from setting stale client (user changed, for example)
    let didUserConnectInterrupt = false;

    try {
      client
        .connectUser(user, tokenOrProvider)
        .then(() => {
          if (!didUserConnectInterrupt) {
            setChatClient(client);
          }
        })
        .catch((e) => {
          console.warn('cannot connect to stream chat', e);
        });
    } catch (e) {
      console.warn('cannot connect to stream chat', e);
    }

    return () => {
      didUserConnectInterrupt = true;
      setChatClient(undefined);
      try {
        void client.disconnectUser();
      } catch (e) {
        console.warn('cannot disconnect from stream chat', e);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should re-run only if user.id changes
  }, [apiKey, user?.id, tokenOrProvider]);

  return chatClient;
};

export const useStreamClient = () => {
  const { member } = useAuth();
  const { data: userData } = useMemberInfo(undefined, member?.memberId);
  const nftAvatar = userData?.data.nftAvatar || userData?.data.avatar;
  const nftDisplay = userData?.data.displayName || userData?.data.distinctName;

  const chatUser = member && {
    id: member.memberId,
    name: nftDisplay,
    image: nftAvatar,
  };
  return {
    chatClient: useClient({
      apiKey: process.env.STREAM_KEY,
      user: chatUser,
      tokenOrProvider: useCallback(async () => {
        const [res] = await call(apis.thread.getToken());

        if (!res?.data.token) {
          console.warn('cannot get stream user token');
        }
        return res?.data.token as string;
      }, []),
    }),
    chatUser,
  };
};
