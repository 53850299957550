export const enum AgentViewSlug {
  ProfileRhsAgentDefault = 'profile_rhs_agent_default',
  ProfileCenterAgentDefault = 'profile_center_agent_default',
  CardCenterAgentDefault = 'card_center_agent_default',
  SettingAgentDefault = 'setting_agent_default', // front-end defined
  ThreadViewAgentDefault = 'thread_view_agent_default', // front-end defined
}

export const enum AgentUserViewSlug {
  ListRhsAgentUserDefault = 'list_rhs_agent_user_default',
  SearchAgentUserDefault = 'search_agent_user_default', // front-end defined
  ThreadComposerUserDefault = 'thread_composer_agent_user_default', // front-end defined, used for thread composer ai action
  ThreadMentionAgentDefault = 'thread_mention_agent_default', // front-end defined, used for thread mention
}

export const enum AgentPromptViewSlug {
  TableCenterPromptDefault = 'table_center_prompt_default',
}

export const enum AiModelViewSlug {
  CardCenterLlmDefault = 'card_center_llm_default',
}

export const enum AgentPermission {
  Unknown = 0,
  CanView = 1,
  CanEdit = 2,
}

export const enum AgentVisibility {
  Unknown = 0,
  Shown = 1,
  Hidden = -1,
}

export const enum AgentStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
  Deleted = -4,
}

export const enum AgentPromptStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
  Deleted = -4,
}

export const enum AgentIsAdd {
  IsAdded = 1,
  NotAdded = -1,
}

export const enum AiModelStatus {
  Inactive = -1,
  Unknown = 0,
  Active = 1,
}

export const enum AgentDataSourceType {
  Playlist = 1,
  Question = 2,
  Link = 3,
  File = 4,
  Text = 5,
}

export const enum AgentPlaylistJoinedStatus {
  Joined = 1,
  NotJoined = -1,
}

export const enum AgentDataSourceStatus {
  Enable = 1,
  Disable = -1,
}

export const enum AgentDataSourceResPublishStatus {
  Published = 1,
  NeedPublish = -1,
}
