import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { TableLayoutFooterTextCell } from '../../types';

export type IaFooterTextCellProps = {
  cell: TableLayoutFooterTextCell;
};

const styles = {
  root: {
    px: 1,
    py: '5.5px',
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
    color: 'alpha.lightA75',
  },
};
export default function IaFooterTextCell({ cell }: IaFooterTextCellProps) {
  const { value } = cell;

  return (
    <Box sx={[styles.root]}>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
}
