import React, {
  Fragment,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { ListSubheader } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { alpha, Theme } from '@mui/material/styles';
import { ActionChevronFilledDown as ActionChevronFilledDownIcon } from '@front/icon';
import { uniq } from 'lodash';

import Popper, { PopperProps } from '../../../components/Popper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Option = Record<string, any>;
type ItemProps<T extends Option> = {
  option: T;
  renderOption?: (option: T, disabled: boolean) => ReactNode;
  isOptionDisabled?: (option: T) => boolean;
  isOptionEqualToValue?: (option: T) => boolean;
  onClick?: (option: T, ev: MouseEvent) => void;
  onHover?: (option: T, ev: MouseEvent) => void;
};

type ItemGroupProps<T extends Option> = ItemProps<T> & {
  rootOpen: boolean;
};
type ItemContainerProps<T extends Option> = ItemGroupProps<T>;

export type MenuDropdownProps<T extends Option> = {
  options: T[];
  open: boolean;
  sx?: PopperProps['sx'];
  anchorEl?: PopperProps['anchorEl'];
  renderOption?: (option: T, disabled: boolean) => ReactNode;
  isOptionDisabled?: (option: T) => boolean;
  isOptionEqualToValue?: (option: T) => boolean;
  onClick?: (option: T, ev: MouseEvent) => void;
  onHover?: (option: T, ev: MouseEvent) => void;
  onClose?: () => void;
  popperProps?: Omit<PopperProps, 'children' | 'open' | 'anchorEl'>;
};

const styles = {
  button: {
    typography: 'body2',
    lineHeight: '24px',
    padding: '2px 12px',
    width: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  selected: {
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  popper: {
    '& .popper-content': {
      mt: 1,
    },
  },
  subPopper: {
    '& .popper-content': {
      ml: 1,
    },
  },
  hoverableButton: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  selectedButton: {
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  toggleIcon: {
    ml: 'auto',
    width: 24,
    height: 24,
    transitionDuration: '0.3s',
  },
  activeToggleIcon: {
    transform: 'rotate(-90deg)',
  },
  main: {
    flex: 1,
    minWidth: 0,
  },
  closeBg: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

function Item<T extends Option>({
  option,
  onClick,
  onHover,
  renderOption,
  isOptionDisabled,
  isOptionEqualToValue,
}: ItemProps<T>) {
  const selected = isOptionEqualToValue?.(option) || false;
  const handleClick = (ev: MouseEvent<HTMLElement>) => {
    onClick?.(option, ev);
    ev.stopPropagation();
  };
  const handleHover = (ev: MouseEvent<HTMLElement>) => {
    onHover?.(option, ev);
  };
  const disabled = isOptionDisabled ? isOptionDisabled(option) : false;
  return (
    <ButtonBase
      sx={[
        styles.button,
        !selected && styles.hoverableButton,
        selected && styles.selectedButton,
      ]}
      onClick={handleClick}
      onMouseEnter={handleHover}
      disabled={disabled}
    >
      <Box sx={styles.main}>
        {renderOption ? renderOption(option, disabled) : option.display}
      </Box>
    </ButtonBase>
  );
}

function ItemGroup<T extends Option>({
  rootOpen,
  option,
  renderOption,
  isOptionDisabled,
  isOptionEqualToValue,
  onClick,
  onHover,
}: ItemGroupProps<T>) {
  const [open, setOpen] = React.useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const selected = isOptionEqualToValue?.(option);

  useEffect(() => {
    // only first time
    if (selected && buttonRef.current) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!option.options) return null;

  const handleClickAway = () => {
    setOpen(false);
  };

  const disabled = isOptionDisabled ? isOptionDisabled(option) : false;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box ref={buttonRef}>
        <ButtonBase
          sx={[styles.button, open && styles.selected]}
          onClick={() => setOpen((st) => !st)}
        >
          <Box sx={styles.main}>
            {renderOption ? renderOption(option, disabled) : option.display}
          </Box>
          <Box sx={[styles.toggleIcon, open && styles.activeToggleIcon]}>
            <ActionChevronFilledDownIcon />
          </Box>
        </ButtonBase>
        <Popper
          sx={styles.subPopper}
          open={open && rootOpen}
          anchorEl={buttonRef.current}
          placement="right-start"
        >
          {option.options?.map((subOption: T, i: number) => (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            <ItemContainer
              key={i}
              option={subOption}
              onClick={onClick}
              onHover={onHover}
              rootOpen={rootOpen}
              renderOption={renderOption}
              isOptionDisabled={isOptionDisabled}
              isOptionEqualToValue={isOptionEqualToValue}
            />
          ))}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}

function ItemContainer<T extends Option>({
  rootOpen,
  option,
  ...rest
}: ItemContainerProps<T>) {
  if (option.options)
    return <ItemGroup rootOpen={rootOpen} option={option} {...rest} />;
  return <Item option={option} {...rest} />;
}

export default function MenuDropdown<T extends Option>({
  options,
  sx,
  open,
  anchorEl,
  onClick,
  onHover,
  onClose,
  renderOption,
  isOptionDisabled,
  isOptionEqualToValue,
  popperProps = {},
}: MenuDropdownProps<T>) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  const sectionDisplays = uniq(options.map((option) => option.section));

  const sections = sectionDisplays.map((display) => ({
    display: display || '',
    sectionOptions: options.filter((option) => option.section === display),
  }));

  const dropdownOptions = (
    <Box>
      {sections.map(({ display, sectionOptions }) => (
        <Fragment key={display}>
          {display && <ListSubheader>{display}</ListSubheader>}
          {sectionOptions.map((option, i) => (
            <ItemContainer
              key={i}
              option={option}
              rootOpen={open}
              onClick={onClick}
              onHover={onHover}
              renderOption={renderOption}
              isOptionEqualToValue={isOptionEqualToValue}
              isOptionDisabled={isOptionDisabled}
            />
          ))}
        </Fragment>
      ))}
    </Box>
  );

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      sx={[styles.popper, ...sxProps]}
      placement="bottom-start"
      {...popperProps}
    >
      {onClose ? (
        <ClickAwayListener onClickAway={onClose}>
          {dropdownOptions}
        </ClickAwayListener>
      ) : (
        <Box>{dropdownOptions}</Box>
      )}
    </Popper>
  );
}
