import React from 'react';

export default function ActionArrowRight({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.3356 6.7526C14.7484 6.38568 15.3805 6.42286 15.7474 6.83565L19.7474 11.3356C20.0842 11.7145 20.0842 12.2855 19.7474 12.6644L15.7474 17.1644C15.3805 17.5772 14.7484 17.6143 14.3356 17.2474C13.9229 16.8805 13.8857 16.2484 14.2526 15.8356L16.7732 13L5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11L16.7732 11L14.2526 8.16437C13.8857 7.75159 13.9229 7.11952 14.3356 6.7526Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
