import { isNil } from 'lodash';

import {
  ClubJoinRequestStatus,
  ClubJoinRequestType,
  FollowingFilterType,
  OrderByFollowType,
} from '../../types/enums';

import generateHook, { generateInfiniteHook } from './swr.helper';

export const useClubList = (pageSize = 10) =>
  generateInfiniteHook<GetClubRes>(
    (index) => `v2/club?page=${index + 1}&limit=${pageSize}`,
    pageSize
  );

export const useClubOnlineMemberCount = (clubSlug: string) =>
  generateHook<GetClubOnlineMemberCountRes>(
    clubSlug ? `v2/club/${clubSlug}/online-member/count` : '',
    {
      auth: true,
    }
  );

export const useNonJoinedFriends = (clubSlug: string) =>
  generateHook<GetNonJoinedFriendsRes[]>(
    clubSlug ? `/v2/club/${clubSlug}/friend/non-join` : '',
    {
      auth: true,
    }
  );

export const useClubInvitationLink = (clubSlug: string) =>
  generateHook<GetClubInvitationLinkRes>(
    clubSlug ? `/v2/club/${clubSlug}/invitation/link` : '',
    {
      auth: true,
    }
  );

export const getSearchClubMembersKey = ({
  clubSlug,
  keyword,
  isExcludeMe,
  isOrderByRole,
  isJoined,
  isGetRole,
  isExistingPlaylist,
  followingFilterType,
  orderByFollowType,
  pageSize,
  page,
}: {
  clubSlug?: string;
  keyword?: string;
  isExcludeMe?: boolean;
  isOrderByRole?: boolean;
  isJoined?: boolean;
  isGetRole?: boolean;
  isExistingPlaylist?: boolean;
  followingFilterType?: FollowingFilterType;
  orderByFollowType?: OrderByFollowType;
  pageSize?: number;
  page?: number;
}) => {
  const params = new URLSearchParams();
  if (!isNil(keyword)) params.set('keywordFuzzy', encodeURIComponent(keyword));
  if (isExcludeMe) params.set('isExcludeMe', 'true');
  if (!isNil(isJoined)) params.set('isJoined', `${isJoined}`);
  if (isGetRole) params.set('isGetRole', 'true');
  if (isExistingPlaylist) params.set('isExistingPlaylist', 'true');
  if (!isNil(isOrderByRole)) params.set('isOrderByRole', `${isOrderByRole}`);
  if (followingFilterType)
    params.set('followingFilterType', `${followingFilterType}`);
  if (orderByFollowType)
    params.set('orderByFollowType', `${orderByFollowType}`);
  if (page) params.set('page', `${page}`);
  if (pageSize) params.set('limit', `${pageSize}`);

  return clubSlug && !isNil(keyword)
    ? `v2/club/${clubSlug}/member?${params.toString()}`
    : '';
};

export const useSearchClubMembers = (
  {
    clubSlug,
    keyword,
    isExcludeMe,
    isOrderByRole,
    isJoined,
    isGetRole,
    isExistingPlaylist,
    followingFilterType,
    orderByFollowType,
  }: {
    clubSlug?: string;
    keyword?: string;
    isExcludeMe?: boolean;
    isOrderByRole?: boolean;
    isJoined?: boolean;
    isGetRole?: boolean;
    isExistingPlaylist?: boolean;
    followingFilterType?: FollowingFilterType;
    orderByFollowType?: OrderByFollowType;
  },
  pageSize = 10
) =>
  generateInfiniteHook<GetSearchClubMembersRes>(
    (index) =>
      getSearchClubMembersKey({
        clubSlug,
        keyword,
        isExcludeMe,
        isOrderByRole,
        isJoined,
        isGetRole,
        isExistingPlaylist,
        followingFilterType,
        orderByFollowType,
        pageSize,
        page: index + 1,
      }),
    pageSize,
    {
      auth: true,
      immutable: true,
    }
  );

export const useClubInvitationInfo = ({ clubSlug }: { clubSlug: string }) =>
  generateHook<GetClubInvitationInfoRes>(
    clubSlug ? `/v2/club/${clubSlug}/invitation/info` : ''
  );

export const useClubInvitationList = ({
  clubSlug,
  pageSize = 50,
}: {
  clubSlug?: string;
  pageSize?: number;
}) =>
  generateInfiniteHook<GetClubInvitationListRes>(
    (index) =>
      clubSlug
        ? `v2/club/${clubSlug}/invitation/sent/me?page=${
            index + 1
          }&limit=${pageSize}`
        : '',
    pageSize,
    {
      auth: true,
    }
  );

export const useRelatedClubs = (clubSlug: string) =>
  generateHook<GetRelatedClubRes[]>(
    clubSlug ? `/v2/club/${clubSlug}/related` : '',
    {
      auth: true,
      immutable: true,
      errorRetryCount: 0,
    }
  );

export const useUserJoinedClubs = (userId?: string) =>
  generateHook<GetClubRes[]>(
    userId ? `v2/club/joined/${userId}?isGetExtra=true` : null,
    {
      auth: true,
    }
  );

export const useClubAnswerFormatPreference = (clubId: string) =>
  generateHook<GetClubAnswerFormatPreferenceRes[]>(
    clubId ? `v2/club/answer-format/preference?clubId=${clubId}` : null,
    {
      auth: true,
    }
  );

export const useClubMembers = (
  {
    clubSlug,
    keyword,
    isGetRole,
    isJoined,
  }: {
    clubSlug: string;
    keyword?: string;
    isGetRole?: boolean;
    isJoined?: boolean;
  },
  pageSize = 50
) => {
  const params = new URLSearchParams();
  params.set('limit', String(pageSize));

  if (keyword !== undefined) {
    params.set('keywordFuzzy', keyword);
  }
  if (isGetRole !== undefined) {
    params.set('isGetRole', String(isGetRole));
  }
  if (isJoined !== undefined) {
    params.set('isJoined', String(isJoined));
  }
  return generateInfiniteHook<GetClubMemberRes>(
    (index) => {
      params.set('page', String(index + 1));

      return clubSlug
        ? `/v2/club/${clubSlug}/member?${params.toString()}`
        : null;
    },
    pageSize,
    {
      auth: true,
      paginate: true,
    }
  );
};

export const useClubJoinRequest = (
  {
    clubSlug,
    type,
    status,
  }: {
    clubSlug?: string;
    type?: ClubJoinRequestType;
    status?: ClubJoinRequestStatus;
  },
  pageSize = 10
) => {
  const params = new URLSearchParams();
  params.set('limit', String(pageSize));

  if (clubSlug !== undefined) {
    params.set('clubSlug', String(clubSlug));
  }

  if (type !== undefined) {
    params.set('type', String(type));
  }
  if (status !== undefined) {
    params.set('status', String(status));
  }

  return generateInfiniteHook<GetClubJoinRequestRes>(
    (index) => {
      params.set('page', String(index + 1));

      return `v2/club/join-request?${params.toString()}`;
    },
    pageSize,
    {
      auth: true,
    }
  );
};

export const useClubSetting = (clubSlug: string) => {
  return generateHook<GetClubSettingRes>(
    clubSlug ? `/v2/club/setting?clubSlug=${clubSlug}` : null,
    {
      auth: true,
    }
  );
};

export const useActiveUserCount = (clubSlug: string) => {
  return generateHook<GetActiveUserCount>(
    clubSlug ? `/v2/club/member/active/count?clubSlug=${clubSlug}` : null
  );
};

export const useSuggestionClubMembers = (clubSlug?: string, limit = 15) => {
  const params = new URLSearchParams();
  params.set('limit', String(limit));
  if (clubSlug) {
    params.set('clubSlug', clubSlug);
  }
  return generateHook<GetSuggestionClubMemberRes[]>(
    `v2/member/share/suggestion?${params.toString()}`,
    {
      auth: true,
    }
  );
};

export const useClubBots = (
  clubSlug: string,
  agentType: number,
  pageSize = 100
) => {
  return generateHook<GetClubBotsRes>(
    clubSlug
      ? `/v2/club/${clubSlug}/agent?agentType=${agentType}&limit=${pageSize}`
      : '',
    {
      auth: true,
    }
  );
};

export const useClubPopularQuestions = (clubSlug: string) => {
  return generateHook<GetPopularQuestionsRes[]>(
    clubSlug ? `/v2/club/${clubSlug}/popular-questions` : '',
    {
      auth: true,
    }
  );
};
