/* eslint-disable no-param-reassign */
import {
  resendEmail,
  resetResendEmail,
  updateBlockerOffset,
  updatePracticeIncognito,
} from '@app/web/src/actions/layoutActions';
import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  isEmailSending: boolean;
  blockerLeftOffset: number;
  blockerTopOffset: number;

  practiceIncognito: boolean;
} = {
  isEmailSending: true,
  blockerLeftOffset: 0,
  blockerTopOffset: 0,

  practiceIncognito: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resendEmail, (state) => {
        state.isEmailSending = true;
      })
      .addCase(resetResendEmail, (state) => {
        state.isEmailSending = false;
      })
      .addCase(updatePracticeIncognito, (state, action) => {
        state.practiceIncognito = action.payload;
      })
      .addCase(updateBlockerOffset, (state, action) => {
        if (action.payload.left !== undefined) {
          state.blockerLeftOffset = action.payload.left;
        }
        if (action.payload.top !== undefined) {
          state.blockerTopOffset = action.payload.top;
        }
      });
  },
});

export default layoutSlice.reducer;
