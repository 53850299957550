import { useMemo, useRef } from 'react';
import Link from 'next/link';
import { Box, Divider, Theme, Typography } from '@mui/material';
import { alpha } from '@mui/system';
import { useDimension } from '@front/helper';
import RichText from '@lib/ia/src/components/RichText';

import { useIaAction } from '../../../core/IaAction/useIaAction';
import FeedCta from '../cta/components/FeedCta';
import { FeedCtaConfig } from '../cta/types';
import { FeedLayoutMessage } from '../types';

import SenderAvatar from './SenderAvatar';

const BASE_HEIGHT = 24;

const styles = {
  root: {
    px: '20px',
    py: 1,
    width: '100%',
    position: 'relative',
    display: 'block',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  newMessage: {
    background: 'rgba(255, 41, 81, 0.10)',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gap: 1,
    alignItems: 'start',
  },
  alignCenter: {
    alignItems: 'center',
  },
  newIndicator: {
    px: '20px',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transform: 'translateY(-50%)',

    typography: 'caption',
    color: (theme: Theme) => theme.palette.error.dark,
    '& .MuiDivider-root::before': {
      width: '100%',
      borderColor: (theme: Theme) => alpha(theme.palette.error.dark, 0.3),
    },
  },
  sender: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  senderPadding: {
    pt: 0.5,
  },
  main: {
    display: 'grid',
    gap: 1,
  },
  inner: {
    display: 'grid',
    gridTemplateColumns: '1fr 36px',
    gap: 1,
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    lineHeight: '120%',
  },
  sentTime: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  sentTimeText: {
    color: 'rgba(214, 214, 214, 0.50)',
  },

  cta: {
    width: '100%',
    display: 'grid',
    gap: 2,
    mb: '4px',
  },
  ctaItem: {
    display: 'inline-flex',
    '& > .number-card': {
      maxWidth: '179px',
      width: '179px',
    },
  },
};

type CtaContainerProps = {
  cta?: FeedCtaConfig;
};

function CtaContainer({ cta }: CtaContainerProps) {
  if (!cta || !cta.items || cta.items.length === 0) return null;
  return (
    <Box
      sx={[
        styles.cta,
        !!cta.gridTemplateAreas && {
          gridTemplateAreas: `'${cta.gridTemplateAreas}'`,
        },
      ]}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {cta.items.map((item, index) => (
        <Box
          sx={[
            styles.ctaItem,
            !!cta.gridTemplateAreas && { gridArea: `cta${index + 1}` },
          ]}
          key={index}
        >
          <FeedCta {...item} />
        </Box>
      ))}
    </Box>
  );
}

type MessageProps = Omit<FeedLayoutMessage, 'type'>;

export default function Message({
  avatar: sender,
  sentTime,
  content,
  isNew = false,
  showNewIndicator = false,
  cta,
  clickAction,
}: MessageProps) {
  const contentRef = useRef();
  const { height } = useDimension(contentRef);
  const { getIaAction } = useIaAction();

  const clickActionProps = useMemo(() => {
    if (!clickAction) return {};
    if (clickAction.type === 'link') {
      return {
        href: clickAction.value,
        component: Link,
      };
    }
    const action = getIaAction<Record<string, string>>(clickAction.value);
    return {
      onClick: () => {
        // prevent onClick when selecting text
        const selection = window.getSelection();
        if (selection?.toString().length !== 0) return;

        action?.action(clickAction.metadata);
      },
    };
  }, [clickAction, getIaAction]);

  const multiLines = height > BASE_HEIGHT;

  return (
    <Box
      sx={[styles.root, isNew && styles.newMessage]}
      {...clickActionProps}
      className="feed-message-item"
    >
      {isNew && showNewIndicator && (
        <Box sx={styles.newIndicator}>
          <Divider textAlign="right">New</Divider>
        </Box>
      )}
      <Box sx={[styles.container, !multiLines && styles.alignCenter]}>
        <Box sx={[styles.sender, multiLines && styles.senderPadding]}>
          <SenderAvatar {...sender} />
        </Box>
        <Box sx={styles.main} ref={contentRef}>
          <Box sx={styles.inner}>
            <Box sx={styles.content}>
              <RichText text={content} variant="body2" inline />
            </Box>
            <Box sx={styles.sentTime}>
              <Typography variant="body2" sx={styles.sentTimeText}>
                {sentTime}
              </Typography>
            </Box>
          </Box>
          <CtaContainer cta={cta} />
        </Box>
      </Box>
    </Box>
  );
}
