import { useCallback, useEffect, useRef, useState } from 'react';
import { nonNullable } from '@front/helper';
import { useTopScrolled } from '@lib/web/hooks';
import { useAddWatchingEvent } from '@lib/web/thread/hooks/core/useAddWatchingEvent';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel, Event } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';
import { v4 } from 'uuid';

import { useThread } from '../../hooks/core/useThread';

const MESSAGE_PER_PAGE = 30;

export const useThreadChatMessages = ({ channel }: { channel: Channel }) => {
  const { typingEffectMessageIdsRef } = useThread();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [status, setStatus] = useState<'init' | 'loading' | 'loaded'>('init');
  const [channelMessages, setChannelMessages] = useState<
    StreamMessage<StreamChatGenerics>[]
  >([]);

  const scrollToBottom = useCallback(() => {
    // use setTimeout to wait for component rendered
    setTimeout(() => {
      scrollRef.current?.scrollTo({
        top: scrollRef.current.scrollHeight,
      });
    });
  }, []);

  const maybeStickOnScrollBottom = useCallback(() => {
    if (!scrollRef.current) return;

    const { offsetHeight, scrollTop, scrollHeight } = scrollRef.current;

    /**
     * means it is very close to bottom (1px for a bit of buffer because sometimes the new message cause the scroll to not be at the bottom)
     */
    if (offsetHeight + scrollTop + 1 >= scrollHeight) {
      scrollToBottom();
    }
  }, [scrollToBottom]);

  const appendNewMessages = useCallback(
    (messages: StreamMessage<StreamChatGenerics>[]) => {
      setChannelMessages((prev) => [...prev, ...messages]);
      maybeStickOnScrollBottom();
    },
    [maybeStickOnScrollBottom]
  );

  const prependNewMessages = useCallback(
    (messages: StreamMessage<StreamChatGenerics>[]) => {
      setChannelMessages((prev) => [...messages, ...prev]);
    },
    []
  );

  const updateMessage = useCallback(
    (updatedMessage: StreamMessage<StreamChatGenerics>): void => {
      setChannelMessages((prevMessages) => {
        const existingMessage = prevMessages.find(
          (m) => m.id === updatedMessage.id
        );

        // Determine if typing effect should be shown based on childChannelCid
        const shouldShowTypingEffect =
          existingMessage &&
          existingMessage.childChannelCid === updatedMessage.childChannelCid;

        if (shouldShowTypingEffect) {
          typingEffectMessageIdsRef.current.add(updatedMessage.id);
        }

        // Replace the old message with the new one
        return prevMessages
          .map((m) => {
            return m.id === updatedMessage.id
              ? updatedMessage.type !== 'deleted'
                ? updatedMessage
                : null
              : m;
          })
          .filter(nonNullable);
      });
    },
    [typingEffectMessageIdsRef]
  );

  const upsertMessage = useCallback(
    (message: StreamMessage<StreamChatGenerics>) => {
      setChannelMessages((prev) => {
        const messageIndex = prev.findIndex(
          (m) =>
            m.id === message.id ||
            (message.customId && m.customId === message.customId)
        );
        if (messageIndex > -1) {
          return [
            ...prev.slice(0, messageIndex),
            message,
            ...prev.slice(messageIndex + 1),
          ];
        } else {
          return [...prev, message];
        }
      });
    },
    []
  );

  const [chatRoomId] = useState(v4());

  useAddWatchingEvent({
    scope: 'chat-room-new-message',
    /**
     * instead of simply use channel.cid, we add chatRoomId here, because the same channel might appear in centre and rhs at the same time
     */
    key: `${channel.cid}-${chatRoomId}`,
    callback: (event: Event) => {
      if (event.channel_id !== channel.id || event.type !== 'message.new') {
        return;
      }

      maybeStickOnScrollBottom();
      upsertMessage(event.message as StreamMessage<StreamChatGenerics>);
    },
  });

  useAddWatchingEvent({
    scope: 'chat-room-message-update',
    key: `${channel.cid}-${chatRoomId}`,
    callback: (event: Event) => {
      if (event.channel_id !== channel.id || event.type !== 'message.updated') {
        return;
      }

      const eventMessage = event.message;

      if (!eventMessage) return;

      updateMessage(eventMessage as StreamMessage<StreamChatGenerics>);
    },
  });

  useAddWatchingEvent({
    scope: 'message-deleted',
    key: `${channel.cid}-${chatRoomId}`,
    callback: (event) => {
      if (event.channel_id !== channel.id || event.type !== 'message.deleted') {
        return;
      }

      const eventMessage = event.message;

      if (!eventMessage) return;

      updateMessage(event.message as StreamMessage<StreamChatGenerics>);
    },
  });

  useEffect(() => {
    const fetchInitialMessages = async () => {
      setStatus('loading');
      const resp = await channel.query({
        messages: { limit: MESSAGE_PER_PAGE },
      });
      const filteredMessages = (
        resp.messages as StreamMessage<StreamChatGenerics>[]
      ).filter((message) => message.type !== 'deleted');
      appendNewMessages(filteredMessages);
      setStatus('loaded');
    };

    if (status === 'init') {
      void fetchInitialMessages();
    }
  }, [appendNewMessages, channel, status]);

  const handleScrollTop = useCallback(async () => {
    const lastMessageId = channelMessages?.[0].id;
    const resp = await channel.query({
      messages: { limit: MESSAGE_PER_PAGE, id_lt: lastMessageId },
    });
    prependNewMessages(resp.messages as StreamMessage<StreamChatGenerics>[]);
  }, [channel, channelMessages, prependNewMessages]);

  useTopScrolled({
    scrollRef,
    onScrolledTop: handleScrollTop,
  });

  return {
    scrollRef,
    messageStatus: status,
    channelMessages,
    appendNewMessages,
  };
};
