import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  alpha,
  Box,
  keyframes,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useDimension } from '@front/helper';

import { Button, TextButton } from '../../../atoms';
import BottomSheet from '../../BottomSheet';
import { PinArrowDirection, PinChatVariant } from '../types';

const CONTAINER_WIDTH = 375;
const BOX_GAP = 8;
const SPACING = 60;
const ARROW_SIZE = 32;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const styles = {
  sheet: {
    zIndex: 'tooltip',
    '& .bottom-sheet-bg': {
      bgcolor: 'transparent',
    },
  },

  gap: {
    height: 8,
    display: { xs: 'none', md: 'block' },
  },
  boxWrap: {
    display: 'grid',
    gap: { md: 1 },
  },
  box: (theme: Theme) => ({
    px: '12px',
    py: 2,
    bgcolor: 'background.menu',
    [theme.breakpoints.up('md')]: {
      borderRadius: 1,
      border:
        theme.palette.mode === 'light'
          ? '1px solid rgba(8, 8, 8, 0.05)'
          : '1px solid rgba(255, 255, 255, 0.1)',
    },
  }),
  boxMessage: {
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    gap: 1,
  },
  boxAction: {
    pb: '6px',
    pt: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  cta: {
    minWidth: 60,
    px: '10.5px',
  },
  progress: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 4,
  },
  getProgressBarStyle: (progress: number) => ({
    background: (theme: Theme) => theme.palette.gradient.primary,
    width: `${progress * 100}%`,
    height: '100%',
  }),
  container: {
    display: 'flex',
    flexDirection: 'column',
    opacity: 0,
    animation: `${fadeIn} 0.3s ease forwards`,
  },
};

export type PinMessageProps = {
  variant?: PinChatVariant;
  top?: number | string;
  left?: number | string;
  progress: number;
  step: number;
  totalStep: number;
  creatorDisplay: string;
  creatorAvatarComponent: ReactNode;
  message: string;
  arrowDirection?: PinArrowDirection;
  onHide?: () => void;
  onNext?: () => void;
  onFinish?: () => void;
};

const PinMessage = ({
  progress,
  creatorAvatarComponent,
  creatorDisplay,
  step,
  message,
  totalStep,
  onFinish,
  onHide,
  onNext,
}: PinMessageProps) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.boxWrap}>
        <Box sx={[styles.box, styles.boxMessage]}>
          {creatorAvatarComponent}
          <Box display="grid" gap={0.5}>
            <Typography fontWeight={700} lineHeight={1}>
              {creatorDisplay}
            </Typography>

            <Typography variant="chatBody">{message}</Typography>
          </Box>
        </Box>

        <Box sx={[styles.box, styles.boxAction]}>
          <Box sx={styles.progress}>
            <Box sx={styles.getProgressBarStyle(progress)} />
          </Box>
          <Typography variant="chatBody">{`${step} of ${totalStep}`}</Typography>

          <Box sx={styles.buttons}>
            {progress === 1 ? (
              <Button sx={styles.cta} onClick={onFinish}>
                Finish
              </Button>
            ) : (
              <>
                <TextButton onClick={onHide}>Hide</TextButton>
                <Button sx={styles.cta} onClick={onNext}>
                  Next
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function DesktopPinMessage({
  arrowDirection,
  x,
  y,
  ...rest
}: PinMessageProps & {
  arrowDirection: PinArrowDirection;
  x?: number;
  y?: number;
}) {
  const boxRef = useRef<HTMLDivElement>();
  const { width, height } = useDimension(boxRef);

  const [messagePosition, setMessagePosition] = useState<
    | {
        top?: number | string;
        left?: number | string;
      }
    | undefined
  >();

  const updatePosition = useCallback(() => {
    if (x === undefined || y === undefined) return;

    // Pin icon should always be at the top and left aligned by default.
    // Message box will be at the bottom of the pin and aligned to the left of the pin by default.
    const { innerWidth, innerHeight } = window;

    const isAlignTop =
      arrowDirection === 'right-bottom' || arrowDirection === 'left-bottom';
    const isAlignLeft =
      arrowDirection === 'right-top' || arrowDirection === 'right-bottom';

    let top = isAlignTop ? BOX_GAP : ARROW_SIZE + BOX_GAP;
    let left = isAlignLeft ? -ARROW_SIZE : 0;

    if (y + height + BOX_GAP > innerHeight - SPACING) {
      if (isAlignTop) top = -ARROW_SIZE - height - BOX_GAP;
      else top = -height - BOX_GAP;
    }

    if (x + width > innerWidth - SPACING) {
      if (isAlignLeft) left = -width;
      else left = -width + ARROW_SIZE;
    }

    setMessagePosition({
      top,
      left,
    });
  }, [arrowDirection, width, height, x, y]);

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [updatePosition]);

  return (
    <Box
      ref={boxRef}
      width={CONTAINER_WIDTH}
      sx={{
        transform: messagePosition
          ? `translate(${messagePosition.left}px, ${messagePosition.top}px)`
          : undefined,
      }}
    >
      <PinMessage {...rest} />
    </Box>
  );
}
export default function PinMessageRoot({
  onRevert,
  open,
  x,
  y,
  ...rest
}: PinMessageProps & {
  onRevert: () => void;
  open: boolean;
  arrowDirection: PinArrowDirection;
  x?: number;
  y?: number;
}) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (mdDown) {
    return (
      <BottomSheet
        sx={styles.sheet}
        open={open}
        fixedHeight
        bottomSpacing={0}
        onClose={onRevert}
      >
        <PinMessage {...rest} />
      </BottomSheet>
    );
  }
  return <DesktopPinMessage x={x} y={y} {...rest} />;
}
