import combineSearch from '@lib/ia/src/utils/combineSearch';
import { ClubGroupViewSlug, useAuth, useIaClubGroups } from '@lib/web/apis';
import { useClubGroupId } from '@lib/web/hooks';

export default function useMemberClubGroupRole() {
  const groupId = useClubGroupId();
  const { dataset } = useIaClubGroups(
    {
      viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
      ...combineSearch([`id:${groupId}`, 'id:eq']),
    },
    {
      enable: !!groupId,
    }
  );
  const clubGroup = dataset[0];

  const { member } = useAuth();
  const clubCreatorSourceId = clubGroup?.creator?.clubCreatorSourceId;
  const isCreator = clubCreatorSourceId === member?.userId;

  return {
    creatorId: clubCreatorSourceId,
    isCreator,
  };
}
