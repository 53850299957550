import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { useOutsideClick } from '@front/helper';

import config from '../config';
import useBaseLayout from '../hooks/useBaseLayout';

import BaseLayoutRightPanelResizer from './BaseLayoutRightPanelResizer';

const styles = {
  rightPanel: (theme: Theme) => ({
    transitionDuration: `${config.rightPanelAnimationDurationInMs}ms`,
    transitionTimingFunction: 'ease',
    transitionProperty: 'transform',
    position: 'fixed',
    right: 0,
    top: { xs: 0, sm: 'var(--announcement-bar-height)' },
    bottom: 0,
    zIndex: 1,
    transform: 'translate(100%)',
    maxWidth: { md: config.rightPanelMaxWidth },
    minWidth: { md: config.rightPanelMinWidth },

    background: theme.palette.background.body,
    backgroundPosition: 'right center',
    backgroundAttachment: 'fixed',
    backgroundSize: '100vw 100vh',

    '@media (hover:hover)': {
      '&:not(:disabled):hover': {
        '& .resizer-btn': {
          display: 'flex',
        },
      },
    },
  }),

  rightPanelOpened: {
    transform: 'translate(0%)',
  },
};
export type BaseLayoutRightPanelContainerProps = {
  sx?: BoxProps['sx'];
  children?: ReactNode;
};
export default function BaseLayoutRightPanelContainer({
  sx,
  children,
}: BaseLayoutRightPanelContainerProps) {
  const {
    rightPanelOpened,
    setRightPanel,
    rightPanelWidth,
    rightPanelResizable,
    rightPanelAutoClose,
    rightPanelMaximizeWidth,
    isResizing,
    closeRightPanel,
  } = useBaseLayout();
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [inheritWidth, setInheritWidth] = useState(false);

  const handleOutsideClick = useCallback(
    (ev: Element) => {
      if (rightPanelOpened && rightPanelAutoClose) {
        if (!ev.closest('[class*="right-panel-"]')) {
          closeRightPanel();
        }
      }
    },
    [rightPanelOpened, rightPanelAutoClose, closeRightPanel]
  );

  const ref = useOutsideClick(handleOutsideClick);

  useEffect(() => {
    setRightPanel(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rightPanelOpened && (rightPanelResizable || rightPanelMaximizeWidth)) {
      // when open any RHS panel and it's resizable -> RHS panels after it will be inherited width from it
      setInheritWidth(true);
    }
    if (!rightPanelOpened) {
      // reset when close the RHS panel
      setInheritWidth(false);
    }
  }, [rightPanelOpened, rightPanelResizable, rightPanelMaximizeWidth]);

  const width = rightPanelMaximizeWidth
    ? config.rightPanelMaxWidth
    : rightPanelResizable || inheritWidth
    ? rightPanelWidth
    : config.rightPanelMinWidth;

  return (
    <Box
      ref={ref}
      sx={[
        styles.rightPanel,
        rightPanelOpened && styles.rightPanelOpened,
        !isResizing && { transitionProperty: 'transform, width' },
        ...sxProps,
      ]}
      style={{ width: mdDown ? '100%' : `${width}px` }}
      className="base-right-panel-container"
    >
      {children}
      {rightPanelOpened && rightPanelResizable && (
        <BaseLayoutRightPanelResizer panelContainerRef={ref} />
      )}
    </Box>
  );
}
