import React from 'react';
import { alpha, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '@lib/ia/src/components/Icon';

export type IaTableAddNewRowProps = {
  text: string;
  icon?: string;
  onClick: () => void;
};

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'max-content', // for position:sticky

    '@media (hover: hover)': {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  content: {
    position: 'sticky',
    left: '24px',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    gap: 1,
    px: 1,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
  },
  icon: {
    width: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
export default function IaTableAddNewRow({
  text,
  icon = 'TestAdd',
  onClick,
}: IaTableAddNewRowProps) {
  return (
    <Box sx={styles.root} onClick={onClick}>
      <Box sx={styles.content}>
        <Box sx={styles.icon}>
          <Icon name={icon} width={16} height={16} />
        </Box>
        <Typography variant="body2">{text}</Typography>
      </Box>
    </Box>
  );
}
