import { IconButton, SquareAvatar } from '@front/ui';
import { getAgentIconPath } from '@lib/web/utils';

type AgentIconButtonProps = {
  agent: GetIaAgentUserThreadComposerDefaultRes['agent'];
  selected?: boolean;
  onClick?: () => void;
};

const AgentIcon = ({
  agent,
  selected = false,
  onClick = () => {},
}: AgentIconButtonProps) => {
  return (
    <IconButton customSize={24} selected={selected} onClick={() => onClick()}>
      <SquareAvatar
        size={16}
        src={agent?.agentPhoto || getAgentIconPath(agent?.agentName ?? '')}
      >
        {agent?.agentName}
      </SquareAvatar>
    </IconButton>
  );
};

export default AgentIcon;
