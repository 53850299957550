import { getObjFromElementAttr } from '@front/helper';
import { HTMLReactParserOptions } from 'html-react-parser';

export function getComposerRenderOptions<MentionData, AudioData>({
  mentionRender,
  audioRender,
}: {
  mentionRender?: (metadata: MentionData) => React.ReactNode;
  audioRender?: (metadata: AudioData) => React.ReactNode;
}) {
  if (!mentionRender && !audioRender) return {} as HTMLReactParserOptions;
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        domNode.type === 'tag' &&
        domNode.name === 'span' &&
        !!domNode.attribs['data-render-ui']
      ) {
        const metadata = getObjFromElementAttr(domNode.attribs);
        if (
          mentionRender &&
          domNode.attribs['data-render-ui'] === 'mentionTag'
        ) {
          return mentionRender(metadata as any) as any as Element;
        }

        if (audioRender && domNode.attribs['data-render-ui'] === 'audioBlock') {
          return audioRender(metadata as any) as any as Element;
        }
      }
    },
  };

  return options;
}
