import { Box, BoxProps, SxProps, Tooltip } from '@mui/material';

import Icon from '../Icon';

type IndicatorItem = { icon: string; name: string; sx?: SxProps };
export type IndicatorGroupProps = {
  size?: number;
  yOffset?: number;
  sx?: BoxProps['sx'];
  indicators?: IndicatorItem[];
};

const styles = {
  root: {
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    px: 0.25,
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '0 2px',
    },
  },
  iconWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

function getIndicatorSxProps(indicator: IndicatorItem) {
  return Array.isArray(indicator.sx) ? indicator.sx : [indicator.sx];
}

export default function IndicatorGroup({
  sx,
  size = 24,
  yOffset = -4,
  indicators,
}: IndicatorGroupProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  if (!indicators || indicators.length === 0) return null;

  return (
    <Box component="span" sx={[styles.root, ...sxProps]} gap={0.5}>
      {indicators.map((indicator) => (
        <Tooltip
          key={indicator.name}
          title={indicator.name}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, yOffset],
                  },
                },
              ],
              disablePortal: true,
            },
          }}
        >
          <Box
            component="span"
            sx={[styles.iconWrapper, ...getIndicatorSxProps(indicator)]}
          >
            <Icon name={indicator.icon} width={size} height={size} />
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
}
