import { ParsedUrlQuery } from 'querystring';

export const getAllBreadcrumbsValues = (
  pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const clubGroupId = query.groupId as string;
  const prefix = clubGroupId
    ? `/club-group/form/${clubGroupId}`
    : '/club-group/form';

  return {
    settings: {
      text: t('breadcrumb::clubGroup.create.Settings', 'Club Group'),
      icon: 'MainClubGroup',
      href: `${prefix}/settings`,
    },
    clubs: {
      text: t('breadcrumb::clubGroup.create.Clubs', 'Clubs'),
      icon: 'MainApps',
      href: `${prefix}/clubs`,
    },
  };
};
