import { useContext, useMemo } from 'react';
import { useDateFormat } from '@front/helper';
import {
  DisplayTableLayoutConfig,
  DisplayTableLayoutRow,
  DisplayTableLayoutSection,
} from '@lib/ia/src/layouts/DisplayTableLayout/types';
import { useAuth, useIaClubs } from '@lib/web/apis';
import { ThreadCurrentLocationContext } from '@lib/web/thread';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useRenderThreadMessageToHtml } from '@lib/web/thread/hooks/message/useRenderThreadMessageToHtml';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { getClubIconPath } from '@lib/web/utils';
import { Channel } from 'stream-chat';

const useChannelClub = (channel: Channel) => {
  const { data } = useIaClubs(
    {
      filter: 'clubLogo;clubName;clubSlug',
      search: `clubId:${channel.data?.clubId}`,
      searchFields: 'clubId:eq',
      limit: 1,
    },
    {
      enable: !!channel.data?.clubId,
    }
  );
  return data?.data.items[0];
};

export const useThreadChildChannelPropertiesLayoutConfig = ({
  parentChannel,
  mode,
  sendPublicly,
}: {
  parentChannel: Channel<StreamChatGenerics>;
  mode: 'expandableBrief' | 'expandableFull' | 'full';
  sendPublicly: boolean;
  expandable?: boolean;
}) => {
  const { displayDatetimeFormat } = useDateFormat();
  const { getThreadUser, getThreadLocationDisplay } = useThread();
  const { getChannelInformation } = useChannelInformation();
  const { renderThreadMessageToHtml } = useRenderThreadMessageToHtml();
  const channelClub = useChannelClub(parentChannel);
  const { currentLocationDetail } = useContext(ThreadCurrentLocationContext);
  const { member } = useAuth();
  const myMemberId = member?.memberId;

  return useMemo(() => {
    const now = new Date().toString();
    const meUser = getThreadUser(myMemberId);
    const locationDetail = currentLocationDetail;
    const tag = parentChannel.data?.tag;
    const { channelMemberIds } = getChannelInformation(parentChannel);

    const rowCreatedBy: DisplayTableLayoutRow = {
      id: 'createdBy',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'MainProfileSolid',
          },
          text: 'Created By',
        },
        value: {
          type: 'avatarText',
          avatar: meUser?.image,
          text: meUser?.name,
        },
      },
      state: 'active',
    };

    const rowCreatedTime: DisplayTableLayoutRow = {
      id: 'createdTime',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PropertyTypeDateOrTime',
          },
          text: 'Created Time',
        },
        value: {
          type: 'text',
          text: displayDatetimeFormat(now),
        },
      },
    };

    const rowVisibility: DisplayTableLayoutRow = {
      id: 'visibility',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'LoginSee',
          },
          text: 'Visibility',
        },
        value: {
          type: 'tag',
          text: sendPublicly ? 'Public' : 'Private',
        },
      },
    };
    const rowMembers: DisplayTableLayoutRow = {
      id: 'members',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PrivacyFriends',
          },
          text: 'Members',
        },
        value: sendPublicly
          ? {
              type: 'tag',
              icon: {
                type: 'icon',
                value: 'TestRanking',
              },
              text: 'Everyone',
            }
          : {
              type: 'avatarGroup',
              avatars: channelMemberIds.map((memberId) => {
                const memberUser = getThreadUser(memberId);
                return {
                  name: memberUser?.name || '',
                  avatarUrl: memberUser?.image || '',
                };
              }),
              maxDisplayNumber: 5,
            },
      },
    };
    const rowActiveMembers: DisplayTableLayoutRow = {
      id: 'activeMembers',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PrivacyFriends',
          },
          text: 'Active Members',
        },
        value: {
          type: 'avatarGroup',
          avatars: [
            {
              name: meUser?.name || '',
              avatarUrl: meUser?.image || '',
            },
          ],
          maxDisplayNumber: 5,
        },
      },
    };
    const rowReplies: DisplayTableLayoutRow = {
      id: 'replies',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ThreadsThread',
          },
          text: 'Replies',
        },
        value: {
          type: 'text',
          text: '0',
        },
      },
    };
    const rowLastReply: DisplayTableLayoutRow = {
      id: 'lastReply',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'MainProfileSolid',
          },
          text: 'Last Reply',
        },
        value: { type: 'emptyText' },
      },
    };
    const rowLastActivity: DisplayTableLayoutRow = {
      id: 'lastActivity',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PropertyTypeDateOrTime',
          },
          text: 'Last Activity Time',
        },
        value: { type: 'emptyText' },
      },
    };
    const rowClub: DisplayTableLayoutRow = {
      id: 'club',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'MainApps',
          },
          text: 'Thread’s Club',
        },
        value: channelClub
          ? {
              type: 'avatarTag',
              avatarUrl:
                channelClub.clubLogo || getClubIconPath(channelClub.clubName),
              text: channelClub.clubName,
              clickAction: 'openClub',
              metadata: {
                clubSlug: channelClub.clubSlug,
              },
            }
          : { type: 'emptyText' },
      },
    };
    const locationDisplay = getThreadLocationDisplay(locationDetail);
    const rowLocation: DisplayTableLayoutRow = {
      id: 'location',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'TextEditorLineLocationPoint',
          },
          text: 'Thread’s Location',
        },
        value: locationDisplay
          ? locationDisplay.type === 'icon'
            ? {
                type: 'tag',
                icon: {
                  type: 'icon',
                  value: locationDisplay.icon,
                },
                text: locationDisplay.name,
              }
            : {
                type: 'avatarTag',
                text: locationDisplay.name,
                avatarUrl: locationDisplay.avatarUrl,
              }
          : { type: 'emptyText' },
      },
    };

    const rowQuestionId: DisplayTableLayoutRow = {
      id: 'questionId',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ChatQuestionList',
          },
          text: 'Question ID',
        },
        value:
          channelClub && locationDetail.shortId
            ? {
                type: 'avatarTag',
                avatarUrl:
                  channelClub.clubLogo || getClubIconPath(channelClub.clubName),
                text: `Question ${locationDetail.shortId}`,
              }
            : { type: 'emptyText' },
      },
    };

    const rowHeadThumbnail: DisplayTableLayoutRow = {
      id: 'headThumbnail',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ChatThread',
          },
          text: 'Head Thumbnail',
        },
        value: {
          type: 'tag',
          text: 'Created By',
          icon: {
            type: 'icon',
            value: 'MainProfileSolid',
          },
        },
      },
    };

    const rowThreadTag: DisplayTableLayoutRow = {
      id: 'threadTag',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PropertyTypeTag',
          },
          text: 'Thread Tag',
        },
        value:
          tag === 'solution'
            ? {
                type: 'tag',
                icon: {
                  type: 'icon',
                  value: 'TestSolution',
                },
                text: 'Solution',
              }
            : { type: 'emptyText' },
      },
    };

    const rowParentThread: DisplayTableLayoutRow = {
      id: 'parentThread',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ThreadsThread',
          },
          text: 'Parent Thread',
        },
        value: {
          type: 'avatarText',
          avatar: getThreadUser(parentChannel.data?.created_by?.id)?.image,
          text: `${getThreadUser(parentChannel.data?.created_by?.id)?.name}:`,
          emphasisText: true,
          textSuffixHtml: renderThreadMessageToHtml(
            parentChannel.data?.firstMessageText,
            parentChannel.data?.firstMessageCustomBlocks
          ),
        },
      },
    };

    const rowChildThread: DisplayTableLayoutRow = {
      id: 'childThread',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ThreadsThread',
          },
          text: 'Child Thread',
        },
        value: {
          type: 'emptyText',
        },
      },
    };

    const rowHideButton = {
      gridTemplateColumns: '80px 1fr',
      cells: [
        {
          type: 'textButton',
          action: 'hide',
          text: 'Hide',
          suffixIcon: 'ActionChevronUp',
        },
      ],
    };
    const rowShowButton = {
      gridTemplateColumns: '120px 1fr',
      cells: [
        {
          type: 'textButton',
          action: 'show',
          text: 'See more',
          suffixIcon: 'ActionChevronRightSmall',
        },
      ],
    };

    const rows = mode === 'expandableBrief' ? [rowMembers, rowLocation] : [];
    const sections: DisplayTableLayoutSection[] =
      mode === 'expandableFull' || mode === 'full'
        ? [
            {
              id: 'overview',
              title: 'Overview',
              defaultOpen: true,
              rows: [
                rowClub,
                rowLocation,
                rowQuestionId,
                rowVisibility,
                rowHeadThumbnail,
                rowThreadTag,
              ],
            },
            {
              id: 'activity',
              title: 'Activity',
              rows: [
                rowCreatedBy,
                rowCreatedTime,
                rowMembers,
                rowActiveMembers,
                rowReplies,
                rowLastReply,
                rowLastActivity,
              ],
            },
            {
              id: 'hierarchy',
              title: 'Hierarchy',
              rows: [rowParentThread, rowChildThread],
            },
          ]
        : [];

    const footerRow =
      mode === 'expandableFull'
        ? rowHideButton
        : mode === 'expandableBrief'
        ? rowShowButton
        : undefined;

    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns: {
            xs: 'minmax(0, 1fr) minmax(0, 2fr)',
            md: 'minmax(0, 1fr) minmax(0, 1fr)',
          },
          columnOrder: ['label', 'value'],
          sections,
          rows,
          footerRow,
        },
        settings: {
          rowHeight: 33,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [
    channelClub,
    currentLocationDetail,
    displayDatetimeFormat,
    getChannelInformation,
    getThreadLocationDisplay,
    getThreadUser,
    mode,
    myMemberId,
    parentChannel,
    renderThreadMessageToHtml,
    sendPublicly,
  ]);
};
