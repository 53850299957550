import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { alpha, Box, Theme, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { truncateTextByWords, useLatestValueRef } from '@front/helper';
import { EditorAI as EditorAIIcon } from '@front/icon';
import { Scrollbar, TitleBar, toast } from '@front/ui';
import BlockTag from '@lib/ia/src/components/BlockTag';
import ThemeProvider from '@lib/ia/src/components/ThemeProvider';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import { apis, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { EditorBlockTypes } from '@lib/web/editor';
import EditorThreadComposerRenderer from '@lib/web/editor/EditorThreadTextComposer/EditorThreadComposerRenderer';
import { useCopyToClipboard, useGetHelpUrl } from '@lib/web/hooks';
import {
  ThreadComposerContextValue,
  ThreadComposerProvider,
} from '@lib/web/thread/contexts/threadComposerContext';
import { useChannel } from '@lib/web/thread/hooks/channel/useChannel';
import { useCreateNewChannelWithMessage } from '@lib/web/thread/hooks/channel/useCreateNewChannelWithMessage';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { useLocationThreadViews } from '@lib/web/thread/hooks/views/useLocationThreadViews';
import ThreadChat from '@lib/web/thread/ThreadChat';
import ThreadChatToolbar from '@lib/web/thread/ThreadChat/ThreadChatToolbar';
import ThreadComposer from '@lib/web/thread/ThreadComposer';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { ToolbarButtonItem } from '@lib/web/ui';
import { call } from '@lib/web/utils';
import { Editor, mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { sanitize } from 'dompurify';
import { StreamMessage } from 'stream-chat-react';
import { v4 } from 'uuid';

export type InlineAIOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineAIOptions;
    node: ProseMirrorNode;
  }) => string;
};

const MAX_SHORTCUT_WORDS = 100; // mechanism limitation for now

const styles = {
  chatContainer: {
    width: 375,

    '.thread-composer-root': {
      background: 'unset',
    },
    '.thread-chat-message-list': {
      height: 375,
    },
  },
  paper: {
    border: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,
    backgroundColor: 'rgba(21, 26, 40, 1)',
  },
  titleBar: {
    flex: 1,
    minWidth: 0,
    px: 1.5,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  toolbar: {
    ml: 'auto',
  },
  active: {
    backgroundColor: 'alpha.primaryLightA30',
  },
};

type AIProps = {
  editor: Editor;
  node: ProseMirrorNode;
  getPos: (() => number) | boolean;
  updateAttributes: (attributes: Record<string, any>) => void;
};

const AI = ({ editor, node, getPos, updateAttributes }: AIProps) => {
  const getHelpUrl = useGetHelpUrl();

  const [show, setShow] = useState(!!node.attrs.defaultShow);
  const showRef = useLatestValueRef(show);
  const [creatingNewChannel, setCreatingNewChannel] = useState(false);
  const [composerText, setComposerText] = useState<string>('');
  const prompt = truncateTextByWords(node.attrs.text, MAX_SHORTCUT_WORDS);
  const [insertedTextInfo, setInsertedTextInfo] = useState<
    { id: string; text: string }[]
  >([]);

  const { view } = useLocationThreadViews();
  const { channelData: defaultChannelData } = useThreadViewDetail(view);
  const { createNewChannelWithMessage } = useCreateNewChannelWithMessage();
  const { member } = useAuth();
  const { openMentionMenuMap } = useThread();

  const { channel } = useChannel({ channelCid: node.attrs.channelCid });

  const anchorRef = useRef<HTMLDivElement | null>(null);

  // XXX: ideally we should use 'useCommonToolbarItems', but that is defined in apps/web
  const toolbarItems = useMemo<ToolbarButtonItem[]>(() => {
    return [
      {
        type: 'Help',
        onClick: () => window.open(getHelpUrl, '_blank', 'noreferrer'),
      },
    ];
  }, [getHelpUrl]);

  const openPopper = useCallback((): void => {
    setShow(true);
  }, []);

  const handleRemoveNode = (): void => {
    if (typeof getPos === 'function') {
      const pos = getPos();
      // remove this node
      const startPos = insertedTextInfo.length > 1 ? pos - 1 : pos;
      editor.commands.deleteRange({ from: startPos, to: pos + node.nodeSize });
    }
  };

  const closePopper = (): void => {
    setShow(false);

    updateAttributes({
      text: composerText,
    });

    if (node.attrs.isMagicWand && insertedTextInfo.length === 0) {
      handleRemoveNode();
      editor.commands.insertContent(node.attrs.text);
    }

    if (insertedTextInfo.length > 0) {
      handleRemoveNode();
      // insert text after close thread popper
      insertedTextInfo.forEach((textInfo) => {
        editor.commands.setHardBreak();
        editor.commands.insertContent(textInfo.text);
      });
      editor.commands.setHardBreak();
    }
  };

  const [, copy] = useCopyToClipboard();

  const availableEventNames = useMemo(() => {
    if (insertedTextInfo.length > 0) {
      return [
        'copyMessage',
        'insertBelow',
        'replaceSelection',
        'tryAgain',
        'prevMessagePage',
        'nextMessagePage',
        'callOut',
      ];
    } else {
      return [
        'copyMessage',
        'insert',
        'tryAgain',
        'prevMessagePage',
        'nextMessagePage',
        'callOut',
      ];
    }
  }, [insertedTextInfo]);

  const threadChatAvailableActions = {
    copyMessage: {
      action: (
        _id: string,
        metadata: {
          plainText: string;
          handler: () => void;
        }
      ): void => {
        const { plainText, handler } = metadata;
        void copy(plainText);
        handler();
      },
    },
    insert: {
      action: (
        _id: string,
        metadata: {
          plainText: string;
        }
      ): void => {
        const { plainText } = metadata;
        setInsertedTextInfo([{ id: v4(), text: plainText }]);
      },
    },
    insertBelow: {
      action: (
        _id: string,
        metadata: {
          plainText: string;
        }
      ): void => {
        if (insertedTextInfo.length === 1 && typeof getPos === 'function') {
          const pos = getPos();
          editor.commands.focus(pos);
          editor.commands.setHardBreak();
        }
        const { plainText } = metadata;
        const newTextInfo = insertedTextInfo.concat({
          id: v4(),
          text: plainText,
        });
        setInsertedTextInfo(newTextInfo);
      },
    },
    replaceSelection: {
      action: (
        _id: string,
        metadata: {
          plainText: string;
        }
      ): void => {
        // remove previous hard break by insertBelow
        if (typeof getPos === 'function') {
          const pos = getPos();
          editor.commands.deleteRange({
            from: pos - 1,
            to: pos,
          });
        }

        const { plainText } = metadata;
        setInsertedTextInfo([{ id: v4(), text: plainText }]);
      },
    },
    tryAgain: {
      action: (
        _id: string,
        metadata?: {
          channelType: 'public' | 'team';
          channelId?: string;
          message: StreamMessage<StreamChatGenerics>;
          handler?: () => void;
        }
      ): void => {
        if (!metadata) {
          return;
        }

        const { channelType, channelId, message } = metadata;
        void call(
          apis.func.threadRecompletion({
            channelType,
            channelId: channelId || '',
            messageId: message.id,
            requestUserId: member?.memberId || '',
            agentUserId: message.user?.id || '',
          })
        );
      },
    },
    prevMessagePage: {
      action: (
        _id: string,
        metadata: {
          handler: (action: 'prev' | 'next') => void;
        }
      ): void => {
        const { handler } = metadata;
        handler('prev');
      },
    },
    nextMessagePage: {
      action: (
        _id: string,
        metadata: {
          handler: (action: 'prev' | 'next') => void;
        }
      ): void => {
        const { handler } = metadata;
        handler('next');
      },
    },
    mentionFriends: {
      action: (): void => {
        const openMentionMenu = openMentionMenuMap.current.get(
          node.attrs.channelCid
        );
        openMentionMenu?.();
      },
    },
  };

  const handleSubmit = async ({
    contextValue: { text, aiActionState },
    blocks,
  }: {
    contextValue: ThreadComposerContextValue;
    blocks: ComposerBlock[];
  }): Promise<void> => {
    setComposerText('');
    setCreatingNewChannel(true);

    const newChannelResult = await createNewChannelWithMessage({
      message: text,
      blocks,
      sendPublicly: false,
      channelData: defaultChannelData,
      agentId: aiActionState.selectedAgentId,
      agentMaterials: [{ type: 'text', value: prompt }],
    });

    if (!newChannelResult) {
      toast.error('Create Thread Failed');
      setCreatingNewChannel(false);
      return;
    }

    // prevent "flushSync was called" error
    setTimeout(() => {
      if (node.attrs.isMagicWand && !showRef.current) {
        // InlineAI node has been destroyed
        return;
      }
      updateAttributes({
        channelCid: newChannelResult.newChannel.cid,
      });
      setCreatingNewChannel(false);
    });
  };

  useEffect(() => {
    if (node.attrs.defaultShow) {
      setTimeout(() => {
        updateAttributes({
          defaultShow: false,
        });
      });
    }
  }, [node.attrs.defaultShow, updateAttributes]);

  const displayState =
    insertedTextInfo.length > 0
      ? 'insertedText'
      : node.attrs.isMagicWand
      ? 'magicWand'
      : 'aiButton';

  return (
    <NodeViewWrapper
      style={
        displayState === 'aiButton'
          ? { display: 'inline-block', padding: '0 6px' }
          : { display: 'inline' }
      }
      className="inline-ai"
    >
      <ThemeProvider mode="light">
        <Box ref={anchorRef} contentEditable={false} component="span">
          {displayState === 'insertedText' &&
            insertedTextInfo.map((textInfo) => (
              <Typography
                sx={[show && styles.active]}
                key={textInfo.id}
                component="span"
              >
                {textInfo.text}
              </Typography>
            ))}
          {displayState === 'magicWand' && (
            <Typography
              sx={[show && styles.active]}
              component="span"
              dangerouslySetInnerHTML={{ __html: sanitize(node.attrs.text) }}
            />
          )}
          {displayState === 'aiButton' && (
            <BlockTag
              active={show}
              icon={<EditorAIIcon />}
              onClick={openPopper}
              disabled={!editor.isEditable}
            >
              AI
            </BlockTag>
          )}
        </Box>
      </ThemeProvider>

      <ThemeProvider mode="dark">
        <Popover
          open={show && Boolean(anchorRef.current)}
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: -8,
            horizontal: 'left',
          }}
          slotProps={{
            paper: { sx: styles.paper },
          }}
          onClose={closePopper}
        >
          <Scrollbar sx={styles.chatContainer}>
            {node.attrs.channelCid ? (
              <>
                <TitleBar
                  sx={styles.titleBar}
                  toolComponent={
                    <Box sx={[styles.title, styles.toolbar]}>
                      <ThreadChatToolbar
                        channelCid={node.attrs.channelCid}
                        toolbarItems={toolbarItems}
                      />
                    </Box>
                  }
                />
                <IaActionContextProvider
                  availableActions={threadChatAvailableActions}
                >
                  <EditorThreadComposerRenderer styleProvider>
                    <ThreadChat
                      channelCid={node.attrs.channelCid}
                      channel={channel}
                      showThreadProperties={false}
                      availableEventNames={availableEventNames}
                    />
                  </EditorThreadComposerRenderer>
                </IaActionContextProvider>
              </>
            ) : (
              <ThreadComposerProvider
                text={composerText}
                setText={setComposerText}
                defaultAction="ai"
                actionChangeable={false}
                sendPubliclyCheckboxEnable={false}
              >
                {(contextValue) => (
                  <ThreadComposer
                    disabled={creatingNewChannel}
                    onChange={setComposerText}
                    onSubmit={({ blocks }) =>
                      handleSubmit({ contextValue, blocks })
                    }
                  />
                )}
              </ThreadComposerProvider>
            )}
          </Scrollbar>
        </Popover>
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineAI = Node.create<InlineAIOptions>({
  name: EditorBlockTypes.InlineAi,

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      text: {
        default: '',
      },
      channelCid: {
        default: '',
      },
      defaultShow: {
        default: true,
      },
      isMagicWand: {
        default: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'inline-ai',
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    const elem = document.createElement('span');
    elem.textContent = node.attrs.value;
    return ['span', mergeAttributes(HTMLAttributes), elem];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AI);
  },
});
