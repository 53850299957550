import { PropertyType } from '@front/ui';

import { FilterOperator, FilterOperatorConfig, FilterValues } from './types';

enum MatchType {
  eq = 'eq',
  neq = 'neq',
  in = 'in',
  contain = 'contain',
  ncontain = 'ncontain',
  startwith = 'startwith',
  endwith = 'endwith',
  set = 'set',
  nset = 'nset',
  gt = 'gt',
  lt = 'lt',
  gte = 'gte',
  lte = 'lte',
  between = 'between',
  containval = 'containval',
  ncontainval = 'ncontainval',
}

/**
 * This map contains default configurations for each filter operator:
 * - label: operator label
 * - operandCount: number of operands for that operator.
 *    E.g. 1: field1 Contains 'abc' => the operator "Contains" has 2 operands.
 *    E.g. 2: field2 IsEmpty => the operator "IsEmpty" has only 1 operand.
 *    E.g. 3: field3 IsBetween 1, 100 => the operator "IsBetween" has 3 operands.
 * - toMatchType: is a function that receives an input param PropertyType to determine exactly the match type that will be returned.
 *    E.g.: If the operator is "Is" and the property type is one of the text types then the match type will be "=".
 *    Meanwhile if the operator is "Is" but the property type is "Status" then the match type will be "in"
 *
 * For more information [read this](https://www.notion.so/rootdomain/API-Spec-IA-Request-Format-bd9eb1f4d9f14c61b5299f807af89089)
 */
export const FILTER_OPERATOR_MAP: Record<FilterOperator, FilterOperatorConfig> =
  {
    Is: {
      label: 'Is',
      operandCount: 2,
      toMatchType: (p: PropertyType) => {
        switch (p) {
          // pattern: `search=field1:content&searchFields=field1:eq`
          case PropertyType.ShortText:
          case PropertyType.LongText:
          case PropertyType.Url:
          case PropertyType.Email:
          case PropertyType.Phone:
          case PropertyType.Icon:
          case PropertyType.Status:
          case PropertyType.Person:
            return { searchFields: MatchType.eq };
          case PropertyType.Select:
            return { searchFields: MatchType.in };
        }
      },
    },
    IsNot: {
      label: 'Is not',
      operandCount: 2,
      disabled: false,
      isNegative: true,
      toMatchType: (p: PropertyType) => {
        switch (p) {
          case PropertyType.ShortText:
          case PropertyType.LongText:
          case PropertyType.Url:
          case PropertyType.Email:
          case PropertyType.Phone:
          case PropertyType.Icon:
          case PropertyType.Status:
            return { searchFields: MatchType.neq };
        }
      },
    },
    Contains: {
      label: 'Contains',
      operandCount: 2,
      toMatchType: (p: PropertyType) => {
        switch (p) {
          // pattern: `search=field1:content&searchFields=field1:contain`
          // only for text types
          case PropertyType.ShortText:
          case PropertyType.LongText:
          case PropertyType.Url:
          case PropertyType.Email:
          case PropertyType.Phone:
          case PropertyType.Icon:
            return { searchFields: MatchType.contain };
          // pattern: `search=field1:content&searchFields=field1:in`
          // only for types with options
          case PropertyType.Select:
          case PropertyType.MultiSelect:
          case PropertyType.Status:
          case PropertyType.Person:
          case PropertyType.Relation:
          case PropertyType.Hashtag:
            return { searchFields: MatchType.in };
        }
      },
    },
    DoesNotContains: {
      label: 'Does not contains',
      operandCount: 2,
      disabled: false,
      isNegative: true,
      toMatchType: () => {
        return { searchFields: MatchType.ncontain };
      },
    },
    StartsWith: {
      label: 'Starts with',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.startwith };
      },
    },
    EndsWith: {
      label: 'Ends with',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.endwith };
      },
    },
    Eq: {
      label: '=',
      operandCount: 2,
      toMatchType: () => {
        // all types with the same pattern: `search=field1:content&searchFields=field1:eq`
        return { searchFields: MatchType.eq };
      },
    },
    Neq: {
      label: '≠',
      operandCount: 2,
      disabled: false,
      toMatchType: () => {
        return { searchFields: MatchType.neq };
      },
    },
    Gt: {
      label: '>',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.gt };
      },
    },
    Lt: {
      label: '<',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.lt };
      },
    },
    Gte: {
      label: '≥',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.gte };
      },
    },
    Lte: {
      label: '≤',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.lte };
      },
    },
    IsSelectEqual: {
      label: 'Is select equal',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.eq };
      },
    },
    IsSelectNotEqual: {
      label: 'Is select not equal',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.neq };
      },
    },
    IsSelectContains: {
      label: 'Is select contains',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.containval };
      },
    },
    IsSelectNotContains: {
      label: 'Is select not contains',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.ncontainval };
      },
    },
    IsSelectIn: {
      label: 'Is select in',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.in };
      },
    },
    IsEqualTime: {
      label: 'Is equal time',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.eq };
      },
    },
    IsBeforeTime: {
      label: 'Is before time',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.lt };
      },
    },
    IsAfterTime: {
      label: 'Is after time',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.gt };
      },
    },
    IsEqualOrBeforeTime: {
      label: 'Is equal or before time',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.lte };
      },
    },
    IsEqualOrAfterTime: {
      label: 'Is equal or after time',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.gte };
      },
    },
    IsBetweenTime: {
      label: 'Is between time',
      operandCount: 3,
      toMatchType: () => {
        return { searchFields: MatchType.between };
      },
    },
    IsRelativeToToday: {
      label: 'Is relative to today',
      operandCount: 2,
      disabled: true,
      toMatchType: (p: PropertyType, values?: FilterValues) => {
        if (!values) return { searchFields: MatchType.eq };
        if (
          typeof values === 'object' &&
          'dateType' in values &&
          values.value &&
          Array.isArray(values.value) &&
          values.value.length > 1
        ) {
          return { searchFields: MatchType.between };
        }
        return { searchFields: MatchType.eq };
      },
    },
    IsTrue: {
      label: 'Is true',
      operandCount: 1,
      toMatchType: (p: PropertyType) => {
        if (p === PropertyType.Boolean) {
          // pattern: field1=true <=> `search=field1:true&searchFields=field1:eq
          return { search: 'true', searchFields: MatchType.eq };
        }
      },
    },
    IsFalse: {
      label: 'Is false',
      operandCount: 1,
      toMatchType: (p: PropertyType) => {
        if (p === PropertyType.Boolean) {
          // pattern: field1=false <=> `search=field1:false&searchFields=field1:eq`
          return { search: 'false', searchFields: MatchType.eq };
        }
      },
    },
    IsKeyEqual: {
      label: 'Is key equal',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.eq };
      },
    },
    IsKeyIn: {
      label: 'Is key in',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.in };
      },
    },
    IsKeyContains: {
      label: 'Is key contains',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.containval };
      },
    },
    IsEmpty: {
      label: 'Is empty',
      operandCount: 1,
      disabled: false,
      toMatchType: () => {
        return { searchFields: MatchType.nset };
      },
    },
    IsNotEmpty: {
      label: 'Is not empty',
      operandCount: 1,
      disabled: false,
      toMatchType: () => {
        return { searchFields: MatchType.set };
      },
    },
    IsKeyNotContains: {
      label: 'Is key not contain',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.ncontainval };
      },
    },
    Between: {
      label: 'Between',
      operandCount: 3,
      toMatchType: () => {
        return { searchFields: MatchType.between };
      },
    },
    IsIn: {
      label: 'Is in',
      operandCount: 2,
      disabled: true,
      toMatchType: () => {
        return { searchFields: MatchType.in };
      },
    },
  };

/**
 * This map contains default configurations for each property type:
 * - operators: all operator that allows for the property type.
 * - defaultOperator: default operator when we create a new filter condition.
 *
 * For more information [read this](https://www.notion.so/rootdomain/d12a322e316148b789642cf197df8cc3?v=9b0ea24f9a7145bc89f4b51bb34e4ec7)
 */
export const PROPERTY_OPERATOR_MAP: Record<
  PropertyType,
  { operators: FilterOperator[]; defaultOperator?: FilterOperator }
> = {
  [PropertyType.ShortText]: {
    operators: [
      'Contains',
      'DoesNotContains',
      'Is',
      'IsNot',
      'StartsWith',
      'EndsWith',
      'IsEmpty',
      'IsNotEmpty',
    ],
    defaultOperator: 'Contains',
  },
  [PropertyType.LongText]: {
    operators: [
      'Contains',
      'DoesNotContains',
      'Is',
      'IsNot',
      'StartsWith',
      'EndsWith',
      'IsEmpty',
      'IsNotEmpty',
    ],
    defaultOperator: 'Contains',
  },
  [PropertyType.Number]: {
    operators: [
      'Eq',
      'Neq',
      'Gt',
      'Lt',
      'Gte',
      'Lte',
      'IsEmpty',
      'IsNotEmpty',
      'Between',
      'IsIn',
    ],
    defaultOperator: 'Eq',
  },
  [PropertyType.Select]: {
    operators: [
      'IsEmpty',
      'IsNotEmpty',
      'IsSelectEqual',
      'IsSelectNotEqual',
      'IsSelectIn',
    ],
    defaultOperator: 'IsSelectIn',
  },
  [PropertyType.MultiSelect]: {
    operators: [
      'IsSelectContains',
      'IsSelectNotContains',
      'IsEmpty',
      'IsNotEmpty',
      'IsSelectIn',
    ],
    defaultOperator: 'IsSelectContains',
  },
  [PropertyType.DateOrTime]: {
    operators: [
      'IsBeforeTime',
      'IsAfterTime',
      'IsEqualOrBeforeTime',
      'IsEqualOrAfterTime',
      'IsBetweenTime',
      'IsRelativeToToday',
      'IsEmpty',
      'IsNotEmpty',
      'IsEqualTime',
    ],
    defaultOperator: 'IsEqualTime',
  },
  [PropertyType.Status]: {
    operators: ['Is', 'IsNot'],
    defaultOperator: 'Is',
  },
  [PropertyType.Person]: {
    operators: ['Contains', 'DoesNotContains', 'IsEmpty', 'IsNotEmpty'],
    defaultOperator: 'Contains',
  },
  [PropertyType.FilesMedia]: {
    operators: [],
  },
  [PropertyType.Boolean]: {
    operators: ['IsTrue', 'IsFalse'],
    defaultOperator: 'IsTrue',
  },
  [PropertyType.Url]: {
    operators: [
      'Contains',
      'DoesNotContains',
      'Is',
      'IsNot',
      'StartsWith',
      'EndsWith',
      'IsEmpty',
      'IsNotEmpty',
    ],
    defaultOperator: 'Contains',
  },
  [PropertyType.Email]: {
    operators: [
      'Contains',
      'DoesNotContains',
      'Is',
      'IsNot',
      'StartsWith',
      'EndsWith',
      'IsEmpty',
      'IsNotEmpty',
    ],
    defaultOperator: 'Contains',
  },
  [PropertyType.Phone]: {
    operators: [
      'Contains',
      'DoesNotContains',
      'Is',
      'IsNot',
      'StartsWith',
      'EndsWith',
      'IsEmpty',
      'IsNotEmpty',
    ],
    defaultOperator: 'Contains',
  },
  [PropertyType.ButtonCta]: { operators: [] },
  [PropertyType.LineColourStatus]: {
    operators: ['Is', 'IsNot'],
    defaultOperator: 'Is',
  },
  [PropertyType.Icon]: {
    operators: ['Contains', 'DoesNotContains', 'IsEmpty', 'IsNotEmpty'],
    defaultOperator: 'Contains',
  },
  [PropertyType.Role]: {
    operators: ['Is', 'IsNot'],
    defaultOperator: 'Is',
  },
  [PropertyType.Relation]: {
    operators: ['Contains', 'DoesNotContains', 'IsEmpty', 'IsNotEmpty'],
    defaultOperator: 'Contains',
  },
  [PropertyType.UUID]: {
    operators: ['Is', 'IsNot'],
    defaultOperator: 'Is',
  },
  [PropertyType.ShortId]: {
    operators: ['Is', 'IsNot'],
    defaultOperator: 'Is',
  },
  [PropertyType.Hashtag]: {
    operators: ['Contains', 'DoesNotContains', 'IsEmpty', 'IsNotEmpty'],
    defaultOperator: 'Is',
  },
};
