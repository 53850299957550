import { ReactNode, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import {
  ActionChevronLeft as ActionChevronLeftIcon,
  ActionChevronRight as ActionChevronRightIcon,
  ActionExpandPage as ActionExpandPageIcon,
} from '@front/icon';

import {
  Scrollbar,
  ScrollbarProps,
  SimpleTooltip,
  TipButton,
} from '../../atoms';
import { Icon } from '../../components';
import { useTruncated } from '../../hooks';
import { TitleBar } from '../../molecules';
import { BackIconType } from '../../molecules/TitleBar';

import useBaseRightPanel from './hooks/useBaseRightPanel';
import config from './config';

type IconTitleProps = {
  title?: ReactNode;
  titleIcon?: ReactNode;
};

export type BaseLayoutRightPanelProps = Omit<BoxProps, 'title'> &
  IconTitleProps & {
    inactive?: boolean;
    children?: ReactNode;
    backIcon?: BackIconType;
    onIconClick?: () => void;
    toolComponent?: ReactNode;
    actionComponent?: ReactNode;
    resizable?: boolean;
    maximizeWidth?: boolean;
    autoClose?: boolean;
    onExpandPageClick?: () => void;
  };

const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderLeft: {
      md: `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  }),
  inactive: {
    opacity: 0,
    visible: 'hidden',
    pointerEvents: 'none',
    userSelect: 'none',
    height: 0,
  },
  bar: {
    px: { xs: 2.5, md: '12px' },
  },
  title: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'min-content 1fr',
    gap: 1,
  },
  titleBox: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    position: 'relative',
    flex: 1,
  },
  scroll: {
    position: 'absolute !important', // to override [data-simplebar] position: relative
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  scrollContent: {
    px: { xs: 2.5, md: '12px' },
    pb: 2.5,
  },
  action: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    px: { xs: 2.5, md: 1.5 },
    py: 1.5,
  },

  searchWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  searchInput: {
    px: { xs: 2.5, md: '12px' },
  },
  searchContent: {
    flex: 1,
    position: 'relative',
  },
  panelActionButtonsRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  historyButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    ml: 'auto',
  },
};

const EMOJI_REGEX = /[\p{Emoji_Presentation}\p{Emoji}\u200d]+/u;

function TitleContent({ title }: { title: ReactNode }) {
  const { containerRef, isTruncated } = useTruncated();

  const content = (
    <Box sx={styles.titleBox} ref={containerRef}>
      {title}
    </Box>
  );

  if (!isTruncated) return content;
  return <SimpleTooltip title={title}>{content}</SimpleTooltip>;
}

function IconTitle({ title, titleIcon }: IconTitleProps) {
  return (
    <Box sx={styles.title}>
      {typeof titleIcon === 'string' && !EMOJI_REGEX.test(titleIcon) ? (
        <Icon name={titleIcon} width={16} height={16} />
      ) : (
        titleIcon
      )}
      <TitleContent title={title} />
    </Box>
  );
}

function PanelScrollArea({ sx, ...rest }: ScrollbarProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return <Scrollbar sx={[styles.scroll, ...sxProps]} {...rest} />;
}

function PanelContent({ sx, ...rest }: BoxProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return <Box sx={[styles.scrollContent, ...sxProps]} {...rest} />;
}

function PanelSearchWrapper({ sx, ...rest }: BoxProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return <Box sx={[styles.searchWrapper, ...sxProps]} {...rest} />;
}

function PanelSearchInput({ sx, ...rest }: BoxProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return <Box sx={[styles.searchInput, ...sxProps]} {...rest} />;
}

function PanelSearchContent({ sx, ...rest }: BoxProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return <Box sx={[styles.searchContent, ...sxProps]} {...rest} />;
}

function PanelActionButtons({
  onExpandPageClick,
}: {
  onExpandPageClick?: () => void;
}) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { rightPanelHistory: history } = useBaseRightPanel();

  const expandPageButton = onExpandPageClick && (
    <TipButton
      customSize={mdUp ? 24 : 28}
      onClick={onExpandPageClick}
      title="Open in Full Page"
    >
      <ActionExpandPageIcon />
    </TipButton>
  );

  if (!mdUp) return expandPageButton;

  return (
    <Box sx={styles.panelActionButtonsRoot}>
      <Box sx={styles.historyButtons}>
        <TipButton
          customSize={mdUp ? 24 : 28}
          onClick={history.back}
          disabled={!history.hasPrevious}
          title="Go Back"
        >
          <ActionChevronLeftIcon />
        </TipButton>
        <TipButton
          customSize={mdUp ? 24 : 28}
          onClick={history.forward}
          disabled={!history.hasNext}
          title="Go Forward"
        >
          <ActionChevronRightIcon />
        </TipButton>
      </Box>

      {expandPageButton}
    </Box>
  );
}

function Toolbar({ toolComponent }: { toolComponent?: ReactNode }) {
  return <Box sx={styles.toolbar}>{toolComponent}</Box>;
}

function BaseLayoutRightPanel({
  children,
  title,
  titleIcon,
  toolComponent,
  actionComponent,
  sx,
  inactive,
  backIcon,
  onIconClick,
  resizable = false,
  maximizeWidth = false,
  autoClose = true,
  onExpandPageClick,
}: BaseLayoutRightPanelProps) {
  const {
    rightPanelOpened,
    closeRightPanel,
    setRightPanelResizable,
    setRightPanelAutoClose,
    setRightPanelMaximizeWidth,
    setRightPanelWidth,
  } = useBaseRightPanel();
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (inactive) return;
    setRightPanelResizable(resizable);
  }, [inactive, resizable, rightPanelOpened, setRightPanelResizable]);

  useEffect(() => {
    if (inactive) return;
    setRightPanelMaximizeWidth(maximizeWidth);
    if (maximizeWidth) {
      setRightPanelWidth(config.rightPanelMaxWidth);
    }
  }, [
    inactive,
    maximizeWidth,
    rightPanelOpened,
    setRightPanelMaximizeWidth,
    setRightPanelWidth,
  ]);

  useEffect(() => {
    if (inactive) return;
    setRightPanelAutoClose(autoClose);
  }, [inactive, autoClose, setRightPanelAutoClose]);

  useEffect(() => {
    if (!rightPanelOpened) {
      // reset resizable when RHS closed
      setRightPanelResizable(false);
      setRightPanelMaximizeWidth(false);
    }
  }, [rightPanelOpened, setRightPanelMaximizeWidth, setRightPanelResizable]);

  const handleBackClick = () => {
    if (onIconClick) onIconClick();
    else {
      closeRightPanel();
    }
  };
  return (
    <Box
      sx={[
        styles.root,
        inactive && styles.inactive,
        !mdDown && maximizeWidth && { width: config.rightPanelMaxWidth },
        ...sxProps,
      ]}
      className={`base-layout-right-panel ${
        rightPanelOpened ? 'is-opened' : ''
      } ${inactive ? 'is-inactive' : 'is-active'}`}
      data-testid="base-layout-right-panel"
    >
      <TitleBar
        sx={styles.bar}
        title={
          titleIcon ? <IconTitle titleIcon={titleIcon} title={title} /> : title
        }
        backIcon={backIcon ?? 'collapse'}
        customAction={
          <PanelActionButtons onExpandPageClick={onExpandPageClick} />
        }
        onBackClick={handleBackClick}
        toolComponent={<Toolbar toolComponent={toolComponent} />}
      />
      <Box sx={styles.content}>{children}</Box>
      {actionComponent && (
        <Box sx={styles.action} className="bottom-action">
          {actionComponent}
        </Box>
      )}
    </Box>
  );
}

BaseLayoutRightPanel.ScrollArea = PanelScrollArea;
BaseLayoutRightPanel.Content = PanelContent;
BaseLayoutRightPanel.SearchWrapper = PanelSearchWrapper;
BaseLayoutRightPanel.SearchInput = PanelSearchInput;
BaseLayoutRightPanel.SearchContent = PanelSearchContent;

export default BaseLayoutRightPanel;
