import React from 'react';
import Box from '@mui/material/Box';
import { gridInSettings } from '@lib/web/editor';
import { GridInOption } from '@lib/web/quiz';

import usePracticeQuestionAnswers from '../../../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionEvent from '../../../../../hooks/usePracticeQuestionEvent';
import usePracticeQuestionResult from '../../../../../hooks/usePracticeQuestionResult';
import usePracticeQuestionStatus from '../../../../../hooks/usePracticeQuestionStatus';

export type QuizAnswerGridInProps = {
  readonly?: boolean;
};

export default function QuizAnswerGridIn({ readonly }: QuizAnswerGridInProps) {
  const { onChangeAnswers } = usePracticeQuestionEvent();
  const { answers: currentAnswers } = usePracticeQuestionAnswers();
  const { isSubmitted, startAt } = usePracticeQuestionStatus();
  const { isCorrect, correctAnswerValues } = usePracticeQuestionResult();

  const gridInValue = currentAnswers[0] || '';

  return (
    <Box>
      <GridInOption
        value={gridInValue}
        onChange={(newValue: string) => onChangeAnswers([newValue])}
        disabled={!startAt || readonly}
        inputCount={4}
        valuesRegex={gridInSettings.valuesRegex}
        acceptedRegex={gridInSettings.acceptedRegex}
        hints={gridInSettings.hints}
        answer={correctAnswerValues[0]}
        isSubmitted={isSubmitted}
        success={isCorrect}
        error={!isCorrect}
        autoFocus
      />
    </Box>
  );
}
