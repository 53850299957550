import Box from '@mui/material/Box';
import { getRightQuestionContentHtml } from '@lib/web/editor';
import EditorComposerRenderer from '@lib/web/editor/EditorTextComposer/EditorComposerRenderer';

import QuizAudioRender from '../../../../../QuizAudioRender';
import usePracticeQuestion from '../../../../hooks/usePracticeQuestion';

export default function QuizQuestion() {
  const { creatorQuestionInfo, contentEnricher } = usePracticeQuestion();

  const content = getRightQuestionContentHtml(creatorQuestionInfo?.components);

  if (content === '') {
    return null;
  }

  return (
    <Box mb={3}>
      <EditorComposerRenderer
        html={contentEnricher(content)}
        audioRender={QuizAudioRender}
      />
    </Box>
  );
}
