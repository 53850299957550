import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  RefObject,
  SetStateAction,
  useRef,
} from 'react';
import { useChannelsHierarchy } from '@lib/web/thread/hooks/channels/useChannelsHierarchy';
import {
  useUnreadChannels,
  useWatchUnreadChannels,
} from '@lib/web/thread/hooks/channels/useUnreadChannels';
import { useThreadUsers } from '@lib/web/thread/hooks/core/useThreadUsers';
import {
  StreamChatGenerics,
  ThreadLocationDisplay,
  ThreadLocationDisplayGetter,
  ThreadMessageExternalPayloadGetter,
  ThreadNotificationMessagePayloadGetter,
  ThreadUserGetter,
} from '@lib/web/thread/types';
import { Channel, ChannelFilters, Event, StreamChat } from 'stream-chat';

import { useStreamClient } from '../hooks/core/useStreamClient';
import { useWatchingThreadEvents } from '../hooks/core/useWatchingThreadEvents';
import { useThreadMessageExternalPayload } from '../hooks/message/useThreadMessageExternalPayload';
import { useThreadNotificationMessagePayloads } from '../hooks/message/useThreadNotificationMessagePayloads';

export type WatchingView = {
  scope: string;
  key: string;
  filters: ChannelFilters;
  channels: Channel[];
  setChannels: Dispatch<SetStateAction<Channel[]>>;
  reloadChannels: () => void;
};

export type WatchingEvent = {
  scope: string;
  key: string;
  callback: (event: Event<StreamChatGenerics>) => void;
};

type ThreadContextValue = {
  chatClient?: StreamChat | null;
  chatUser?: { id: string; name?: string; image?: string } | null;
  contextProvided: boolean;
  watchingViewsRef: MutableRefObject<WatchingView[]>;
  watchingEventsRef: MutableRefObject<WatchingEvent[]>;
  getThreadUser: ThreadUserGetter;
  getThreadLocationDisplay: ThreadLocationDisplayGetter;
  getThreadNotificationMessagePayload: ThreadNotificationMessagePayloadGetter;
  getThreadMessageExternalPayload: ThreadMessageExternalPayloadGetter;
  unreadChannels: Channel<StreamChatGenerics>[];
  reloadUnreadChannels: () => void;
  resetThreadChannelSortFilter: MutableRefObject<() => void>;
  resetThreadMessageSortFilter: MutableRefObject<() => void>;
  threadTitleTextRef: RefObject<HTMLInputElement>;
  typingEffectMessageIdsRef: MutableRefObject<Set<string>>;
  openMentionMenuMap: MutableRefObject<Map<string, () => void>>;
  insertContent: MutableRefObject<(text: string) => void>;
};

export const ThreadContext = createContext<ThreadContextValue>({
  contextProvided: false,
  watchingViewsRef: { current: [] } as MutableRefObject<WatchingView[]>,
  watchingEventsRef: { current: [] } as MutableRefObject<WatchingEvent[]>,
  getThreadUser: () => null,
  getThreadNotificationMessagePayload: () => null,
  getThreadMessageExternalPayload: () => null,
  getThreadLocationDisplay: () => ({} as ThreadLocationDisplay),
  unreadChannels: [],
  reloadUnreadChannels: () => {},
  resetThreadChannelSortFilter: { current: () => {} } as MutableRefObject<
    () => void
  >,
  resetThreadMessageSortFilter: { current: () => {} } as MutableRefObject<
    () => void
  >,
  threadTitleTextRef: {} as RefObject<HTMLInputElement>,
  typingEffectMessageIdsRef: { current: new Set() },
  openMentionMenuMap: { current: new Map([]) } as MutableRefObject<
    Map<string, () => void>
  >,
  insertContent: { current: () => {} } as MutableRefObject<() => void>,
});

/**
 * The values of inner context depends on outer context, so we need to make it 2 levels provider
 */
type InnerThreadContextValue = {
  parentCidToChildChannels: Record<string, Channel<StreamChatGenerics>[]>;
  checkChannelsHierarchy: (channels: Channel<StreamChatGenerics>[]) => void;
};

export const InnerThreadContext = createContext<InnerThreadContextValue>({
  parentCidToChildChannels: {},
  checkChannelsHierarchy: () => {},
});

function ThreadProviderInner({ children }: { children: ReactNode }) {
  useWatchUnreadChannels();

  const { parentCidToChildChannels, checkChannelsHierarchy } =
    useChannelsHierarchy();

  return (
    <InnerThreadContext.Provider
      value={{
        parentCidToChildChannels,
        checkChannelsHierarchy,
      }}
    >
      {children}
    </InnerThreadContext.Provider>
  );
}

type ThreadProviderProps = {
  children: ReactNode;
} & Partial<ThreadContextValue>;

export function ThreadProvider({ children, ...props }: ThreadProviderProps) {
  const { chatClient, chatUser } = useStreamClient();
  const watchingViewsRef = useRef<WatchingView[]>([]);
  const watchingEventsRef = useRef<WatchingEvent[]>([]);
  const { getThreadUser } = useThreadUsers();
  const { getThreadNotificationMessagePayload } =
    useThreadNotificationMessagePayloads();
  const { getThreadMessageExternalPayload } = useThreadMessageExternalPayload();
  const { unreadChannels, reloadUnreadChannels } =
    useUnreadChannels(chatClient);
  const resetThreadChannelSortFilter = useRef(() => {});
  const resetThreadMessageSortFilter = useRef(() => {});

  useWatchingThreadEvents(watchingViewsRef, watchingEventsRef, chatClient);

  const threadTitleTextRef = useRef<HTMLInputElement>(null);

  const typingEffectMessageIdsRef = useRef(new Set<string>());

  // Thread Composer
  const openMentionMenuMap = useRef(new Map());
  const insertContent = useRef(() => {});

  return (
    <ThreadContext.Provider
      value={{
        chatClient,
        chatUser,
        watchingViewsRef,
        watchingEventsRef,
        contextProvided: true,
        getThreadUser,
        getThreadNotificationMessagePayload,
        getThreadMessageExternalPayload,
        getThreadLocationDisplay: () => null,
        unreadChannels,
        reloadUnreadChannels,
        resetThreadChannelSortFilter,
        resetThreadMessageSortFilter,
        threadTitleTextRef,
        typingEffectMessageIdsRef,
        openMentionMenuMap,
        insertContent,
        ...props,
      }}
    >
      <ThreadProviderInner>{children}</ThreadProviderInner>
    </ThreadContext.Provider>
  );
}
