import React, { MouseEvent, useState } from 'react';
import {
  alpha,
  Box,
  ButtonBase,
  ListItemText,
  MenuItem,
  SxProps,
  Theme,
} from '@mui/material';
import { StickyContainer } from '@front/helper';
import { ResponsivePopper } from '@front/ui';

import Icon from '../../../components/Icon';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    typography: 'body2',
    height: '27px',
    ml: '-24px',
    mt: '-27px',
    pr: 1,
    backgroundColor: 'background.menu',
    color: 'text.primary',
    borderRadius: '4px',
    boxShadow: 9,
    border: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? '1px solid rgba(8, 8, 8, 0.05)'
        : '1px solid rgba(255, 255, 255, 0.1)',
  },
  item: {
    fontFamily: 'Ubuntu',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    px: 1.5,
    py: '3.5px',
    '& .prefix-icon': {
      mr: 1,
    },
    '& .suffix-icon': {
      ml: 0.5,
    },
  },
  info: {
    color: 'primary.light',
    gap: 0.5,
  },
  button: {
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.05),
      },
      '&:active': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  selectedButton: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  subMenu: {
    mt: '4px !important',
    '& .MuiPaper-root': {
      maxWidth: 265,
    },
  },
  subMenuItem: {
    '& .MuiListItemText-root': {
      typography: 'body2',
      py: '3.5px',
      display: 'grid',
      gap: 0.5,
    },
    '& .MuiListItemText-secondary': {
      typography: 'caption',
      whiteSpace: 'initial',
    },
  },
  hintInline: {
    '& .MuiListItemText-root': {
      gap: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiListItemText-secondary': {
      typography: 'body2',
      color: 'text.primary',
    },
  },
};

type ActionType = {
  icon?: string;
  iconSxProps?: SxProps;
  hint?: string;
  text: string;
  textSxProps?: SxProps;
  hintInline?: boolean;
  subActions?: ActionType[];
  onClick: (ev: MouseEvent) => void;
  onHover?: (ev: MouseEvent) => void;
};

export type IaTableToolbarProps = {
  anchorEl: HTMLDivElement | null;
  numberOfSelected: number;
  actions: ActionType[];
  hintInline?: boolean;
};

type IaTableToolbarButtonProps = ActionType;

function IaTableToolbarButton({
  text,
  textSxProps,
  icon,
  iconSxProps,
  subActions,
  hintInline = false,
  onClick,
  onHover,
}: IaTableToolbarButtonProps) {
  const [menuEl, setMenuEl] = useState<Element | null>(null);

  const handleOpenMenu = (ev: MouseEvent) => {
    setMenuEl(ev.currentTarget);
  };

  const iconSx = Array.isArray(iconSxProps) ? iconSxProps : [iconSxProps];
  const textSx = Array.isArray(textSxProps) ? textSxProps : [textSxProps];

  return (
    <>
      <ButtonBase
        key={text}
        sx={[styles.item, menuEl ? styles.selectedButton : styles.button]}
        onClick={subActions ? handleOpenMenu : onClick}
        onMouseEnter={onHover}
        disableRipple
      >
        {icon && (
          <Box sx={[styles.icon, ...iconSx]}>
            <Icon className="prefix-icon" name={icon} width={16} height={16} />
          </Box>
        )}
        <Box sx={textSx} component="span">
          {text}
        </Box>
        {!!subActions?.length && (
          <Icon
            className="suffix-icon"
            name="ActionChevronDown"
            width={16}
            height={16}
          />
        )}
      </ButtonBase>
      {!!menuEl && !!subActions?.length && (
        <ResponsivePopper
          open
          onClose={() => setMenuEl(null)}
          menuProps={{
            sx: styles.subMenu,
            anchorEl: menuEl,
            disablePortal: true,
            placement: 'bottom-start',
          }}
          sheetProps={{
            fixedHeight: true,
          }}
        >
          {subActions?.map((event, i) => (
            <MenuItem
              key={i}
              sx={[styles.subMenuItem, hintInline && styles.hintInline]}
              onClick={(ev) => {
                event.onClick(ev);
                setMenuEl(null);
              }}
            >
              <ListItemText primary={event.text} secondary={event.hint} />
            </MenuItem>
          ))}
        </ResponsivePopper>
      )}
    </>
  );
}

export default function IaTableToolbar({
  anchorEl,
  numberOfSelected,
  actions,
}: IaTableToolbarProps) {
  return (
    <StickyContainer
      anchorEl={anchorEl}
      offset={{
        top: 35, // mt: 27 height + 8 buffer
        left: 24, // ml: 24 width
      }}
    >
      <Box sx={styles.root} className="ia-table-toolbar">
        <Box sx={[styles.item, styles.info]}>{numberOfSelected} selected</Box>
        {actions.map(
          ({
            icon,
            iconSxProps,
            text,
            textSxProps,
            hintInline,
            subActions,
            onClick,
            onHover,
          }) => (
            <IaTableToolbarButton
              key={text}
              icon={icon}
              iconSxProps={iconSxProps}
              text={text}
              textSxProps={textSxProps}
              subActions={subActions}
              hintInline={hintInline}
              onClick={onClick}
              onHover={onHover}
            />
          )
        )}
      </Box>
    </StickyContainer>
  );
}
