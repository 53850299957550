import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SquareAvatar } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import RichText from '@lib/ia/src/components/RichText';

import { ProfileLayoutDetailsArea } from '../types';

const styles = {
  root: {
    wordWrap: 'break-word',
  },
  title: {
    fontWeight: 700,
    mb: 1,
  },
  item: {
    lineHeight: '24px',
    py: '2px',
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
  },
  itemIcon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
};

export type AreaDetailProps = ProfileLayoutDetailsArea;

export default function AreaDetail({
  title,
  titleTypography,
  propertyTypography,
  properties,
}: AreaDetailProps) {
  return (
    <Box sx={styles.root}>
      {title && (
        <Typography sx={[{ typography: titleTypography }, styles.title]}>
          {title}
        </Typography>
      )}
      {properties.map(({ key, iconType, icon, text }) => (
        <Box key={key} sx={[{ typography: propertyTypography }, styles.item]}>
          {icon && (
            <Box sx={styles.itemIcon}>
              {iconType === 'image' && <SquareAvatar src={icon} size={16} />}

              {iconType === 'icon' && icon && (
                <Icon name={icon} width={16} height={16} />
              )}
              {iconType === 'emoji' && icon}
            </Box>
          )}

          <RichText text={text} />
        </Box>
      ))}
    </Box>
  );
}
