import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getBreadcrumbsValuesByRoute as mainLayoutGetBreadcrumbsValuesByRoute } from '@app/web/src/layouts/breadcrumbs/useRouteBreadcrumbsValues';
import { getBreadcrumbsValuesByRoute as editAgentGetBreadcrumbsValuesByRoute } from '@app/web/src/widgets/AgentsPage/EditAgent/EditAgentLayout/hooks/useRouteBreadcrumbsValues';
import { getBreadcrumbsValuesByRoute as editClubGroupGetBreadcrumbsValuesByRoute } from '@app/web/src/widgets/ClubGroupPage/EditClubGroup/EditClubGroupLayout/hooks/useRouteBreadcrumbsValues';
import { getBreadcrumbsValuesByRoute as editClubGetBreadcrumbsValuesByRoute } from '@app/web/src/widgets/ClubsPage/EditClub/EditClubLayout/hooks/useRouteBreadcrumbsValues';
import { useCollectUniqueKeys } from '@front/helper';
import { useIaClubs, useQuizResultSummaryMultiple } from '@lib/web/apis';
import { ThreadLocationDisplayGetter } from '@lib/web/thread/types';
import { getClubIconPath } from '@lib/web/utils';

export const useThreadLocationDisplay = () => {
  const { t } = useTranslation();
  const [clubIds, collectClubIds] = useCollectUniqueKeys<string>();
  const [quizIds, collectQuizIds] = useCollectUniqueKeys<string>();

  const { data: clubData } = useIaClubs(
    {
      filter: 'clubId;clubLogo;clubName;clubSlug',
      search: `clubId:${clubIds.join(',')}`,
      searchFields: 'clubId:in',
    },
    {
      enable: clubIds.length > 0,
    }
  );
  const { data: quizData } = useQuizResultSummaryMultiple({
    quizIds,
  });

  const getThreadLocationDisplay: ThreadLocationDisplayGetter = useCallback(
    (locationDetail) => {
      if (!locationDetail?.location) {
        return null;
      }

      if (
        locationDetail.location === '/club/[slug]/editor/[questionId]' ||
        locationDetail.location === '/club/[slug]/review/all/[questionId]' ||
        locationDetail.location === '/club/[slug]/review/[userId]/[questionId]'
      ) {
        collectClubIds(locationDetail.clubId);
        const club = clubData?.data.items.find(
          (c) => c.clubId === locationDetail.clubId
        );

        return club
          ? {
              type: 'avatar',
              avatarUrl: club.clubLogo || getClubIconPath(club.clubName),
              name: `Question Editor ID-${
                locationDetail.shortId?.substring(0, 3) || ''
              }`,
            }
          : null;
      }

      if (
        locationDetail.location ===
          '/club/[slug]/practice/[quizId]/[roundNo]' ||
        locationDetail.location === '/club/[slug]/exam/[quizId]/[roundNo]'
      ) {
        collectClubIds(locationDetail.clubId);
        collectQuizIds(locationDetail.quizId);

        const club = clubData?.data.items.find(
          (c) => c.clubId === locationDetail.clubId
        );

        const quiz = locationDetail.quizId
          ? quizData?.[locationDetail.quizId]
          : null;

        return club && quiz
          ? {
              type: 'avatar',
              avatarUrl: club.clubLogo || getClubIconPath(club.clubName),
              name: `${quiz.quiz.quizCustomName} Q${locationDetail.questionNo}`,
            }
          : null;
      }

      if (
        locationDetail.location ===
          '/club/[slug]/practice/[quizId]/[roundNo]/result' ||
        locationDetail.location ===
          '/club/[slug]/exam/[quizId]/[roundNo]/result'
      ) {
        return {
          type: 'icon',
          icon: 'TestScoreTally',
          name: 'Results',
        };
      }

      let breadcrumbValues = mainLayoutGetBreadcrumbsValuesByRoute(
        locationDetail.location,
        locationDetail.query,
        t
      );

      if (
        breadcrumbValues.length === 0 &&
        locationDetail.location.startsWith('/shared')
      ) {
        breadcrumbValues = mainLayoutGetBreadcrumbsValuesByRoute(
          locationDetail.location,
          locationDetail.query,
          t
        );
      }

      if (
        breadcrumbValues.length === 0 &&
        locationDetail.location.startsWith('/club/form')
      ) {
        breadcrumbValues = editClubGetBreadcrumbsValuesByRoute(
          locationDetail.location,
          locationDetail.query,
          t
        );
      }

      if (
        breadcrumbValues.length === 0 &&
        locationDetail.location.includes('/agent/form')
      ) {
        breadcrumbValues = editAgentGetBreadcrumbsValuesByRoute(
          locationDetail.location,
          locationDetail.query,
          t
        );
      }

      if (
        breadcrumbValues.length === 0 &&
        locationDetail.location.startsWith('/club-group/form')
      ) {
        breadcrumbValues = editClubGroupGetBreadcrumbsValuesByRoute(
          locationDetail.location,
          locationDetail.query,
          t
        );
      }

      const firstBreadcrumbText = breadcrumbValues[0]?.text;
      const firstBreadcrumbIcon = breadcrumbValues[0]?.icon;

      if (firstBreadcrumbText && typeof firstBreadcrumbIcon === 'string') {
        return {
          type: 'icon',
          icon: firstBreadcrumbIcon,
          name: firstBreadcrumbText,
        };
      }

      return null;
    },
    [clubData?.data.items, collectClubIds, collectQuizIds, quizData, t]
  );

  return useMemo(
    () => ({
      getThreadLocationDisplay,
    }),
    [getThreadLocationDisplay]
  );
};
