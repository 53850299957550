import { nonNullable } from '@front/helper';
import { ThreadViewType } from '@lib/web/apis';
import {
  LocationDetail,
  ThreadLocationDisplay,
  ThreadUserGetter,
} from '@lib/web/thread/types';
import { parseFilterConfig } from '@lib/web/thread/utils/viewUtils';
import crypto from 'crypto';
import { TFunction } from 'i18next';
import { isNil, omitBy } from 'lodash';
import { ChannelData, ChannelFilters } from 'stream-chat';

/**
 * for dm views, we need to ensure same memberIds combination only have 1 view,
 * so we use a hash string to make it unique
 * (given a fixed referenceId, back-end api will help us ensure this view is unique)
 */
export const hashDmViewReferenceId = (memberIds: string[]): string => {
  const HASH_LENGTH = 32;
  const inputString = memberIds.sort().join(',');
  const hash = crypto.createHash('sha256').update(inputString).digest('hex');
  return hash.substring(0, HASH_LENGTH);
};

export const hashLocationKey = (locationDetail: LocationDetail): string => {
  const HASH_LENGTH = 32;
  const inputString = Object.values(locationDetail).join(',');
  const hash = crypto.createHash('sha256').update(inputString).digest('hex');
  return hash.substring(0, HASH_LENGTH);
};

const parseViewMemberIds = ({
  view,
  myMemberId,
}: {
  view?: GetThreadViewRes;
  myMemberId?: string;
}) => {
  const threadViewUser = view?.threadViewUsers.find(
    (user) => user.memberId === myMemberId
  );

  const { memberIds: parsedMemberIds } = parseFilterConfig(
    threadViewUser?.filterConfig
  );

  switch (view?.type) {
    // in dm threads, the filterConfig will contain all the members
    case ThreadViewType.DmOneOnOne:
    case ThreadViewType.DmGroup:
    case ThreadViewType.DmEveryone:
      return parsedMemberIds;

    // in club threads, we will not put self into filterConfig, but we still need it to fetch channels
    case ThreadViewType.ClubAllThread:
    case ThreadViewType.Club:
    case ThreadViewType.Location:
      return myMemberId ? [...parsedMemberIds, myMemberId] : parsedMemberIds;

    default:
      return [];
  }
};

export const parseViewDetail = ({
  view,
  myMemberId,
}: {
  view?: GetThreadViewRes;
  myMemberId?: string;
}) => {
  const threadViewUser = view?.threadViewUsers.find(
    (user) => user.memberId === myMemberId
  );

  const { clubId: viewClubId, tag: viewTag } = parseFilterConfig(
    threadViewUser?.filterConfig
  );

  const viewMemberIds = parseViewMemberIds({ view, myMemberId });

  const viewUserIds = viewMemberIds.filter((id) => !id.startsWith('agent_'));
  const viewAgentIds = viewMemberIds
    .filter((id) => id.startsWith('agent_'))
    .map((id) => id.replace('agent_', ''));

  const viewId = view?.id;
  const viewType = view?.type;
  const threadViewUserId = threadViewUser?.id;

  return {
    view,
    myMemberId,
    threadViewUser,
    viewClubId,
    viewMemberIds,
    viewUserIds,
    viewAgentIds,
    viewId,
    viewType,
    viewTag,
    threadViewUserId,
  };
};

export const getViewName = ({
  view,
  myMemberId,
  threadViewUser,
  viewMemberIds,
  viewUserIds,
  viewAgentIds,
  getThreadUser,
  t,
}: ReturnType<typeof parseViewDetail> & {
  getThreadUser: ThreadUserGetter;
  t: TFunction;
}) => {
  if (!view || !threadViewUser) return '';

  if (view.type === ThreadViewType.ClubAllThread) {
    return t('club.All Threads');
  }

  if (view.type === ThreadViewType.DmEveryone) {
    return t('dm.view.Everyone');
  }

  if (threadViewUser.customName) return threadViewUser.customName;
  if (view.name) return view.name;

  const isOnlySelf =
    viewUserIds.length === 1 &&
    viewAgentIds.length === 0 &&
    viewUserIds[0] === myMemberId;

  if (isOnlySelf) {
    return getThreadUser(myMemberId)?.name || '';
  }

  return viewMemberIds
    .filter((id) => id !== myMemberId)
    .map((id) => getThreadUser(id)?.name)
    .filter((id) => !!id)
    .join(', ');
};

export const getViewIcon = ({
  view,
  myMemberId,
  threadViewUser,
  viewMemberIds,
  viewUserIds,
  viewAgentIds,
  viewTag,
  locationDisplay,
  getThreadUser,
}: ReturnType<typeof parseViewDetail> & {
  locationDisplay: ThreadLocationDisplay | null;
  getThreadUser: ThreadUserGetter;
}) => {
  if (!view || !threadViewUser) return null;

  if (view.type === ThreadViewType.ClubAllThread) {
    return {
      type: 'icon',
      icon: 'ThreadsThreadView',
    };
  }

  if (view.type === ThreadViewType.DmEveryone) {
    return {
      type: 'icon',
      icon: 'PrivacyFriends',
    };
  }

  if (view.type === ThreadViewType.Location) {
    if (viewTag === 'solution') {
      return {
        type: 'icon',
        icon: 'TestSolution',
      };
    }

    return locationDisplay
      ? locationDisplay.type === 'icon'
        ? {
            type: 'icon',
            icon: locationDisplay.icon,
          }
        : {
            type: 'avatar',
            avatar: locationDisplay.avatarUrl,
            avatarTitle: locationDisplay.name,
          }
      : null;
  }

  if (threadViewUser?.customEmoji) {
    return {
      type: 'emoji',
      emoji: threadViewUser.customEmoji,
    };
  }
  if (view?.emoji) {
    return {
      type: 'emoji',
      emoji: view?.emoji,
    };
  }

  const isOnlySelf =
    viewUserIds.length === 1 &&
    viewAgentIds.length === 0 &&
    viewUserIds[0] === myMemberId;

  if (isOnlySelf) {
    const meThreadUser = getThreadUser(myMemberId);
    return {
      type: 'avatar',
      avatar: meThreadUser?.image,
      avatarTitle: meThreadUser?.name,
      avatarBadgeCount: 0,
    };
  }

  const [firstOtherMember] = viewMemberIds
    .filter((id) => id !== myMemberId)
    .map((id) => getThreadUser(id));

  if (firstOtherMember) {
    return {
      type: 'avatar',
      avatar: firstOtherMember?.image,
      avatarTitle: firstOtherMember?.name,
      avatarBadgeCount: viewMemberIds.length - 1,
    };
  }

  return null;
};

export const getChannelFilters = (
  {
    view,
    viewClubId,
    viewMemberIds,
    viewTag,
  }: ReturnType<typeof parseViewDetail>,
  locationDetail: LocationDetail
) => {
  const filters: ChannelFilters = {};

  if (!view) return filters;

  if (
    view.type === ThreadViewType.DmOneOnOne ||
    view.type === ThreadViewType.DmGroup
  ) {
    filters.members = { $eq: viewMemberIds };
    filters.type = 'team';
    return filters;
  }

  if (view.type === ThreadViewType.DmEveryone) {
    filters.members = { $in: viewMemberIds };
    filters.type = 'public';
    return filters;
  }

  filters.$and = [
    viewMemberIds.length > 1
      ? {
          members: { $eq: viewMemberIds }, // user has applied member filter, so we use $eq
        }
      : {
          $or: [
            {
              type: 'team',
              members: { $in: viewMemberIds }, // user doesn't apply member filter, so we use $in
            },
            {
              type: 'public',
            },
          ],
        },
  ];

  if (viewClubId) {
    filters.$and.push({
      clubId: { $eq: viewClubId },
    });
  }

  if (viewTag) {
    filters.$and.push({
      tag: { $eq: viewTag },
    });
  }

  if (
    view.type === ThreadViewType.ClubAllThread ||
    view.type === ThreadViewType.Club
  ) {
    return filters;
  }

  if (view.type === ThreadViewType.Location) {
    if (locationDetail.questionId) {
      filters.$and.push({ questionId: { $eq: locationDetail.questionId } });
    } else {
      filters.$and.push({
        location: { $eq: locationDetail.location },
      });
    }
  }
  return filters;
};

export const getViewChannelData = ({
  viewClubId,
  viewMemberIds,
  viewTag,
}: ReturnType<typeof parseViewDetail>): ChannelData => {
  return omitBy(
    {
      members: viewMemberIds,
      clubId: viewClubId,
      tag: viewTag,
    },
    isNil
  );
};

export const getDefaultThreadViewUsersId = (clubId?: string): string => {
  return `all-thread-${clubId}`;
};

export const getViewNameMembers = ({
  view,
  myMemberId,
  threadViewUser,
  viewMemberIds,
  viewUserIds,
  viewAgentIds,
  getThreadUser,
}: ReturnType<typeof parseViewDetail> & {
  getThreadUser: ThreadUserGetter;
  t: TFunction;
}) => {
  if (!view || !threadViewUser) return null;

  if (
    view.type === ThreadViewType.ClubAllThread ||
    view.type === ThreadViewType.DmEveryone ||
    threadViewUser.customName ||
    view.name
  ) {
    return null;
  }

  const isOnlySelf =
    viewUserIds.length === 1 &&
    viewAgentIds.length === 0 &&
    viewUserIds[0] === myMemberId;

  if (isOnlySelf) {
    return [getThreadUser(myMemberId)].filter(nonNullable);
  }

  return viewMemberIds
    .filter((id) => id !== myMemberId)
    .map((id) => getThreadUser(id))
    .filter(nonNullable);
};
