import React, { ReactElement } from 'react';
import Link from 'next/link';
import { alpha, Box, BoxProps, SxProps, Theme } from '@mui/material';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { DisplayTableLayoutCell, DisplayTableLayoutRow } from '../types';

import IaDisplayTableCell from './IaDisplayTableCell';

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    textAlign: 'left',
  },
  hoverable: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
};

type IaDisplayTableRowProps = {
  sx?: BoxProps['sx'];
  row: DisplayTableLayoutRow;
  columnOrder: string[];
  component?: React.ElementType;
  currentState?: string;
  states?: Record<string, SxProps<Theme>>;
  hoverable?: boolean;
  children: (
    cell: DisplayTableLayoutCell
  ) => ReactElement<typeof IaDisplayTableCell>;
};

export default function IaDisplayTableRow({
  sx,
  row,
  columnOrder,
  component,
  currentState = '',
  states = {},
  hoverable = false,
  children,
}: IaDisplayTableRowProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const stateSx = states[currentState] || {};
  const stateSxProps = Array.isArray(stateSx) ? stateSx : [stateSx];

  const { getIaAction } = useIaAction();

  const eventActionProps = {
    onClick: () =>
      getIaAction<DisplayTableLayoutRow>(row?.clickAction?.value)?.action(row),
  };
  const linkActionProps = {
    component: Link,
    href: row?.clickAction?.value,
  };
  const clickActionType = row?.clickAction?.type;

  const Component = component || Box;

  return (
    <Component
      sx={[
        styles.root,
        hoverable && styles.hoverable,
        ...sxProps,
        ...stateSxProps,
      ]}
      {...(clickActionType === 'event'
        ? eventActionProps
        : clickActionType === 'link'
        ? linkActionProps
        : {})}
    >
      {columnOrder.map((col, index) => (
        <React.Fragment key={index}>{children(row.cells[col])}</React.Fragment>
      ))}
    </Component>
  );
}
