/**
 * Sometimes we only need to record what happened in the system,
 * we don't want to ask user to fill in the error report form, so we use this function
 */
export const silentCaptureException = (
  description: string,
  error: Error | unknown
) => {
  const err = error as Error;
  err.message = description;

  if (process.env.SENTRY_DSN) {
    import('@sentry/nextjs').then((Sentry) => {
      console.warn(error);
      Sentry.captureException(err, {
        extra: {
          silent: true,
        },
      });
    });
  } else {
    console.error(error);
  }
};
