import React from 'react';
import { Box, Theme, Typography } from '@mui/material';

import { PinArrowDirection, PinChatVariant } from '../types';

import PinActiveIcon from './PinActiveIcon';
import PinNewIcon from './PinNewIcon';
import PinViewedIcon from './PinViewedIcon';

const styles = {
  pin: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    span: {
      position: 'relative',
      color: 'background.default',
      fontWeight: 'bold',
    },
    svg: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  getPinDirectionStyle: (direction?: PinArrowDirection) => (theme: Theme) => {
    switch (direction) {
      case 'right-top':
        return {
          [theme.breakpoints.down('md')]: {
            top: -32,
          },
          [theme.breakpoints.up('md')]: {
            bottom: -32,
            left: -32,
            svg: {
              transform: 'scale(-1, -1)',
            },
          },
        };
      case 'left-top':
        return {
          [theme.breakpoints.down('md')]: {
            top: -32,
          },
          [theme.breakpoints.up('md')]: {
            bottom: -32,
            svg: {
              transform: 'scaleY(-1)',
            },
          },
        };
      case 'right-bottom':
        return {
          [theme.breakpoints.down('md')]: {
            top: -32,
          },
          [theme.breakpoints.up('md')]: {
            top: -32,
            left: -32,
            svg: {
              transform: 'scaleX(-1)',
            },
          },
        };
      default:
        return {
          // mobile only support for "right-bottom"
          top: -32,
        };
    }
  },
};

const getPinIcon = (variant?: PinChatVariant) => {
  switch (variant) {
    case 'new':
      return PinNewIcon;
    case 'active':
      return PinActiveIcon;
    default:
      return PinViewedIcon;
  }
};

export type PinStepProps = {
  step: number;
  arrowDirection?: PinArrowDirection;
  variant?: PinChatVariant;
  onClick?: () => void;
};
export default function PinStep({
  arrowDirection,
  variant,
  step,
  onClick,
}: PinStepProps) {
  const PinIcon = getPinIcon(variant);
  return (
    <Box
      sx={[styles.pin, styles.getPinDirectionStyle(arrowDirection)]}
      onClick={onClick}
    >
      <PinIcon />
      <Typography variant="caption">{step}</Typography>
    </Box>
  );
}
