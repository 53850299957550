import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

import { isDev } from './src/config/constants';

function fetchUserLanguage() {
  return fetch('/api/auth/language')
    .then((res) => res.json())
    .then(({ language }) => language || 'en')
    .catch((error) => {
      console.error('Failed to fetch user language preference:', error);
      return 'en';
    });
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: isDev,
    ns: [
      'common',
      'club',
      'analytics',
      'playlist',
      'profile',
      'quiz',
      'marketplace',
      'breadcrumb',
      'notification',
      'editor',
      'agent',
      'thread',
    ],
    parseMissingKeyHandler: (key) => {
      const keys = key.split('.');
      return keys[keys.length - 1];
    },
    defaultNS: 'common',
    fallbackNS: 'common',
    nsSeparator: '::',
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          defaultVersion: 'v1.0.245',
          store: typeof window !== 'undefined' ? window.localStorage : null,
        },
        {
          allowMultiLoading: true,
          loadPath: '/api/locale?lang={{lng}}&ns={{ns}}',
          parse(res: string) {
            const data = JSON.parse(res);

            return data;
          },
        },
      ],
    },
  });

fetchUserLanguage().then((userLanguage) => {
  i18n.changeLanguage(userLanguage);
});

export default i18n;
