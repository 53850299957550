import React from 'react';

export default function ActionArrowDown({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.3356 19.7474C11.7145 20.0842 12.2855 20.0842 12.6644 19.7474L17.1644 15.7474C17.5772 15.3805 17.6143 14.7484 17.2474 14.3356C16.8805 13.9229 16.2484 13.8857 15.8356 14.2526L13 16.7732V5C13 4.44771 12.5523 4 12 4C11.4477 4 11 4.44771 11 5V16.7732L8.16437 14.2526C7.75159 13.8857 7.11952 13.9229 6.7526 14.3356C6.38568 14.7484 6.42286 15.3805 6.83565 15.7474L11.3356 19.7474Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
