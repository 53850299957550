import { useCallback } from 'react';
import { ComposerBlock } from '@lib/web/composer';
import { extractTextFromHtml } from '@lib/web/utils';
import { v4 } from 'uuid';

import { AgentMaterial, StreamChatGenerics } from '../../types';

/**
 * stream SDK only allows a maximum of 5KB for a message and a channel's custom data,
 * we use 4KB as a buffer
 */
const MAX_MESSAGE_KB = 4;

export const useBuildMessageData = () => {
  const buildMessageData = useCallback(
    ({
      text,
      blocks,
      agentMaterials,
    }: {
      text: string;
      blocks: ComposerBlock[];
      agentMaterials?: AgentMaterial[];
    }): StreamChatGenerics['messageType'] => {
      /**
       * Transforming from HTML to text helps us save tokens when querying GPT.
       * It's said that GPT reads text just as well as HTML,
       * but if we find it more reasonable to use HTML, we can change it back.
       */
      const plainText = extractTextFromHtml(text);

      /**
       * blocks is the most large data in a message, so we only use this to determine
       * whether we should use external payload
       */
      const blocksKB = new Blob([JSON.stringify(blocks)]).size / 1024;
      const useExternalMessagePayload = blocksKB > MAX_MESSAGE_KB;

      return {
        text: plainText,
        customBlocks: useExternalMessagePayload ? [] : blocks,
        agentMaterials,
        externalPayloadId: useExternalMessagePayload ? v4() : undefined,
      };
    },
    []
  );

  return {
    buildMessageData,
  };
};
