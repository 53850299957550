import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import useTableSettings from '../../../hooks/useTableSettings';
import { TextGroupCell } from '../../../types';

export type IaTextGroupCellProps = {
  cell: TextGroupCell;
};

const styles = {
  root: {
    px: 1,
    py: '5.5px',
    display: 'grid',
    minHeight: '32px',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  nowrap: {
    height: '32px',
  },
  text: {
    br: {
      // make html element render on one line
      content: '" "',
      display: 'none',
    },
  },
  textNowrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '& [data-content-type="paragraph"] > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};
export default function IaTextGroupCell({ cell }: IaTextGroupCellProps) {
  const { value } = cell;
  const { wrap } = useTableSettings();

  return (
    <Box sx={[styles.root, !wrap && styles.nowrap]} className="group-cell">
      <Typography
        variant="body2"
        sx={[styles.text, !wrap && styles.textNowrap]}
      >
        {value}
      </Typography>
    </Box>
  );
}
