import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { BetaTag } from '@app/web/src/components/ClubStatusTags';
import { useMemberNftAvatar } from '@app/web/src/hooks/utils/useMemberNftAvatar';
import usePlaylistTitle from '@app/web/src/hooks/utils/usePlaylistTitle';
import { getAwardCategoryByPath } from '@app/web/src/utils/award';
import ChallengeIcon from '@app/web/src/widgets/PlaylistDetailPage/ChallengeTitleIcon';
import { useDateFormat } from '@front/helper';
import {
  OtherCalendarDay as OtherCalendarDayIcon,
  OtherPhone as OtherPhoneIcon,
  ProfileAwards as ProfileAwardsIcon,
} from '@front/icon';
import { SquareAvatar } from '@front/ui';
import AvatarGridFolder from '@lib/ia/src/components/AvatarGridFolder';
import {
  AgentViewSlug,
  QuizGroupType,
  useAgents,
  useQuizResultSummary,
} from '@lib/web/apis';
import { useCurrentExam, useIaClub, useIaClubGroup } from '@lib/web/hooks';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import ThreadMemberTitle from '@lib/web/thread/ThreadMemberTitle';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';
import { getAgentIconPath, getClubIconPath } from '@lib/web/utils';

import { useClubGroupClubs } from '../../ia/clubGroup/useClubGroupClubs';

export function PlaylistBreadcrumb({
  title,
  markId,
  quizId,
  icon,
  dynamicType,
  customDate,
}: {
  title?: string;
  markId?: string;
  quizId?: string;
  icon?: ReactNode;
  dynamicType?: QuizGroupType;
  customDate?: string;
}) {
  const { sectionId } = useCurrentExam();

  const { data } = useQuizResultSummary({
    quizId,
    sectionId,
    sourceId: markId,
    dynamicType,
    customDate,
  });

  const playlistTitle = usePlaylistTitle(data?.data, {
    dynamicType,
    dynamicTitle: title,
  });

  return (
    <>
      <Box>{icon}</Box>
      <Box>{playlistTitle || <Skeleton width={100} />}</Box>
    </>
  );
}

export function CreatorPlaylistBreadcrumb({
  quizId,
  iconSize = 16,
}: {
  quizId: string;
  iconSize?: number;
}) {
  const { data } = useQuizResultSummary({ quizId });

  const avatar =
    data?.data.quiz.quizOwner?.nftAvatar || data?.data.quiz.quizOwner?.avatar;
  const displayName =
    data?.data.quiz.quizOwner?.displayName ||
    data?.data.quiz.quizOwner?.distinctName;

  return (
    <>
      <SquareAvatar size={iconSize} src={avatar}>
        {displayName}
      </SquareAvatar>
      <Box>{displayName ?? <Skeleton width={100} />}</Box>
    </>
  );
}

export function ChallengeBreadcrumb({
  quizId,
  iconSize = 16,
}: {
  quizId: string;
  iconSize?: number;
}) {
  const { data } = useQuizResultSummary({ quizId });

  return (
    <>
      <ChallengeIcon size={iconSize} quizId={quizId} />
      <Box>{data?.data.quiz.quizCustomName ?? <Skeleton width={100} />}</Box>
    </>
  );
}

export function AnalyticsSummaryCustomDateBreadcrumb({
  date,
  iconSize = 24,
}: {
  date: string;
  iconSize?: number;
}) {
  const { displaySpecificDateFormat } = useDateFormat();
  return (
    <>
      <OtherCalendarDayIcon width={iconSize} height={iconSize} />
      <Box textTransform="capitalize">{displaySpecificDateFormat(date)}</Box>
    </>
  );
}

export function InvitePhoneNumberBreadcrumb() {
  const { t } = useTranslation('profile');

  return (
    <>
      <Box>
        <OtherPhoneIcon />
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {t('invite.phone.title')}
        </Typography>
      </Box>
    </>
  );
}

export function ReviewQuestionDetailBreadcrumb({
  userId,
  iconSize = 16,
}: {
  userId: string;
  iconSize?: number;
}) {
  const { isLoading, nftDisplay, nftAvatar } = useMemberNftAvatar({
    userId,
  });

  return (
    <>
      <Box>
        <SquareAvatar size={iconSize} src={nftAvatar}>
          {nftDisplay}
        </SquareAvatar>
      </Box>
      <Box>{isLoading ? <Skeleton width={100} /> : nftDisplay}</Box>
    </>
  );
}

export function ClubBreadcrumb({ slug }: { slug: string }) {
  const { club } = useIaClub(slug);
  const validIconPath = club
    ? club.clubLogo || getClubIconPath(club.clubName)
    : '';

  if (!club)
    return (
      <Box>
        <Skeleton width={100} />
      </Box>
    );
  return (
    <>
      <Box>
        <SquareAvatar size={16} src={validIconPath} blackAndWhite>
          {club.clubName}
        </SquareAvatar>
      </Box>
      <Box display="flex" gap={1}>
        {club.clubName}
        <BetaTag slug={club.clubSlug} />
      </Box>
    </>
  );
}

export function ClubGroupBreadcrumb({ groupId }: { groupId: string }) {
  const { clubGroup } = useIaClubGroup(groupId);
  const { dataset } = useClubGroupClubs(groupId);

  const clubAvatars = dataset?.slice(0, 4)?.map((club) => ({
    avatarUrl: club.clubLogo || getClubIconPath(club.clubName),
    name: club.clubName,
  }));

  if (!clubGroup)
    return (
      <Box>
        <Skeleton width={100} />
      </Box>
    );
  return (
    <>
      <Box>
        {clubGroup.logo ? (
          <SquareAvatar
            size={16}
            src={clubGroup.logo}
            blackAndWhite
            showStacked
          >
            {clubGroup.name}
          </SquareAvatar>
        ) : (
          <AvatarGridFolder
            avatars={clubAvatars}
            avatarsCount={clubGroup?.clubCount}
            size={16}
          />
        )}
      </Box>
      <Box display="flex" gap={1}>
        {clubGroup.name}
      </Box>
    </>
  );
}

export function AgentBreadcrumb({ agentId }: { agentId: string }) {
  const { dataset } = useAgents({
    viewSlug: AgentViewSlug.ProfileCenterAgentDefault,
    search: `agentId:${agentId}`,
    searchFields: 'agentId:eq',
  });
  const agent: GetIaAgentProfileCenterAgentDefaultRes | undefined = useMemo(
    () => dataset[0],
    [dataset]
  );

  if (!agent)
    return (
      <Box>
        <Skeleton width={100} />
      </Box>
    );
  return (
    <>
      <Box>
        <SquareAvatar
          size={16}
          src={agent.agentPhoto || getAgentIconPath(agent.agentName)}
        >
          {agent.agentName}
        </SquareAvatar>
      </Box>
      <Box>{agent.agentName}</Box>
    </>
  );
}

export function ThreadViewBreadcrumb({ viewId }: { viewId: string }) {
  const { findDmView } = useDirectMessageViews();
  const view = findDmView(viewId);
  const { viewName, viewNameMembers } = useThreadViewDetail(view);

  if (!view) {
    return (
      <Box>
        <Skeleton width={100} />
      </Box>
    );
  }

  if (viewNameMembers) {
    return (
      <>
        <Box>
          <ThreadViewIcon view={view} size="sm" />
        </Box>
        <Box>
          <ThreadMemberTitle members={viewNameMembers} badgeSize={16} />
        </Box>
      </>
    );
  }
  return (
    <>
      <Box>
        <ThreadViewIcon view={view} size="sm" />
      </Box>
      <Box>{viewName}</Box>
    </>
  );
}

export function AwardCategoryBreadcrumb({
  categoryPath,
  iconSize = 24,
}: {
  categoryPath: string;
  iconSize?: number;
}) {
  const { t } = useTranslation('profile');
  const category = getAwardCategoryByPath(categoryPath);
  if (!category)
    return (
      <Box>
        <Skeleton width={100} />
      </Box>
    );
  return (
    <>
      <Box>
        <ProfileAwardsIcon width={iconSize} height={iconSize} />
      </Box>
      <Box>{t(`award.category.title.${category}`)}</Box>
    </>
  );
}
