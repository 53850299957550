import { Box, Typography } from '@mui/material';
import { TagSelect, TagSelectOption } from '@front/ui';
import { useSelectFilter } from '@lib/ia/src/filter/FilterCondition/hooks/useSelectFilter';

import useInputEvent from '../hooks/useInputEvent';
import { FormLayoutMultipleSelectInput } from '../types';

import LabelIcon from './LabelIcon';

type MultipleSelectInputProps = {
  item: FormLayoutMultipleSelectInput;
};

const styles = {
  root: {
    display: 'flex',
    gap: 1,
    flexDirection: 'column',
    '.MuiAutocomplete-tag': {
      px: '6px',
    },
    '.MuiChip-icon': {
      mx: 0,
      display: 'flex',
    },
    '.MuiChip-label': {
      px: 1,
    },
    '.MuiChip-deleteIcon': {
      mx: 0,
    },
  },
  labelContent: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
};

export default function MultipleSelectInput({
  item,
}: MultipleSelectInputProps) {
  const {
    desktopAutocompleteProps,
    mobileAutocompleteProps,
    mobileBottomSheetProps,
    scrollHeight,
    filteredOptions,
    handleOptionClick,
    successState,
    onBlur,
    error,
  } = useSelectFilter({
    item: item.field,
    multiple: true,
    customRules: item.rules,
  });

  const onChange = (newOption: TagSelectOption): void => {
    const targetOption = item.field.options.find(
      (option) => option.value === newOption.value
    );
    if (targetOption) {
      handleOptionClick(targetOption);
    }
  };

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onBlur,
    onChange,
  });

  const handleBlur = (): void => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = async (newOption: TagSelectOption): Promise<void> => {
    await handleInputChange(newOption);
    void successState.handleChange();
  };

  const handleClick = (newOption: TagSelectOption): void => {
    handleChange(newOption);
    handleBlur();
  };

  return (
    <Box
      className={`ia-form-layout_multiple-select-input ${item.className || ''}`}
      sx={styles.root}
    >
      <Typography sx={styles.labelContent} variant="caption">
        {item.icon && <LabelIcon icon={item.icon} />}
        {item.label}
      </Typography>
      <TagSelect
        desktopAutocompleteProps={desktopAutocompleteProps}
        mobileAutocompleteProps={mobileAutocompleteProps}
        mobileBottomSheetProps={mobileBottomSheetProps}
        options={filteredOptions}
        disabled={item.disabled}
        error={error}
        placeholder={item.placeholder}
        scrollHeight={scrollHeight}
        showCheckbox
        handleClick={handleClick}
      />
    </Box>
  );
}
