import React from 'react';

export default function ActionArrowRightUp({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.008 6.05209C17.5141 6.08185 17.9178 6.48558 17.9476 6.99164L18.3011 13.002C18.3336 13.5534 17.9129 14.0266 17.3616 14.059C16.8102 14.0915 16.337 13.6708 16.3046 13.1195L16.0818 9.33208L7.75691 17.657C7.36639 18.0475 6.73322 18.0475 6.3427 17.657C5.95217 17.2664 5.95217 16.6333 6.3427 16.2427L14.6676 7.91787L10.8802 7.69508C10.3288 7.66265 9.90819 7.18942 9.94062 6.63809C9.97305 6.08675 10.4463 5.6661 10.9976 5.69853L17.008 6.05209Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
