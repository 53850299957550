import { ReactNode } from 'react';

export type IaDisplayTableBodyProps = {
  children: ReactNode;
};

export default function IaDisplayTableBody({
  children,
}: IaDisplayTableBodyProps) {
  return children;
}
