import {
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, SxProps, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Scrollbar, SimpleTooltip } from '@front/ui';
import { BasicComposerSchema, ComposerBlock } from '@lib/web/composer';
import {
  TextComposerContext,
  TextComposerProvider,
} from '@lib/web/composer/TextComposer/context/TextComposerContext';
import { TextComposerProps } from '@lib/web/composer/TextComposer/TextComposer';
import { ThreadComposerCustomContext } from '@lib/web/thread/contexts/threadComposerCustomContext';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import { useSendMessageDisabled } from '@lib/web/thread/ThreadComposer/hooks/useSendMessageDisabled';
import ThreadTextComposer from '@lib/web/thread/ThreadTextComposer';

import { useThread } from '../hooks/core/useThread';

import Actions, { ActionsProps } from './components/Actions';
import SendPubliclyCheckbox from './components/SendPubliclyCheckbox';
import useThreadMentionItems from './hooks/useThreadMentionItems';
import { validateThreadElementsBeforeInserting } from './utils/validateThreadElementsBeforeInserting';

const styles = {
  root: {
    borderTop: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    background: (theme: Theme) => theme.palette.background.body,
    position: 'relative',
    backgroundPosition: 'right center',
    backgroundAttachment: 'fixed',
    backgroundSize: '100vw 100vh',
    width: 0,
    minWidth: '100%',
  },
  messageBox: {
    px: 2.5,
    pt: 2,
    pb: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '& .textfield-label': {
      display: 'none',
    },
    '& .textfield-message': {
      display: 'none',
    },
  },
  textContainer: {
    position: 'relative',
    width: '100%',
  },
  textScroll: {
    minHeight: '17px',
    maxHeight: '416px', // figma: Maximum composer height = 416 px (half of center pane’s height, excluding top bar)
  },
  disabled: {
    opacity: 0.5,
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      ml: 2.5,
    },
  },
};

export type ThreadComposerProps = {
  sx?: SxProps;
  disabled?: boolean;
  disabledTip?: string;
  placeholder?: string;
  onChange?: (text: string) => void;
  onSubmit?: (params: {
    event: MouseEvent<HTMLButtonElement> | KeyboardEvent;
    text: string;
    blocks: ComposerBlock[];
  }) => void | Promise<void>;
  autoFocus?: boolean;
  disableSubmitWhileSubmitting?: boolean;
  renderTextComposer?: (
    props: TextComposerProps<BasicComposerSchema>
  ) => ReactNode;
  cid?: string;
  addElementItems?: ActionsProps['addElementItems'];
};

function ThreadComposer({
  sx,
  disabled,
  disabledTip,
  placeholder,
  onChange,
  onSubmit,
  autoFocus,
  disableSubmitWhileSubmitting = true,
  renderTextComposer = (props) => <ThreadTextComposer {...props} />,
  cid,
  addElementItems,
}: ThreadComposerProps) {
  const { t } = useTranslation('thread');
  const { commands } = useContext(TextComposerContext);
  const { composerBlocksToHtml } = useContext(ThreadComposerCustomContext);
  const sxProps = Array.isArray(sx) ? sx : [sx];

  const sendMessageDisabled = useSendMessageDisabled();
  const {
    text,
    selectedAction,
    aiActionState,
    threadMemberIds,
    setSelectedAction,
  } = useThreadComposer();
  const { openMentionMenuMap, insertContent } = useThread();
  const [blocks, setBlocks] = useState<ComposerBlock[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const slashMenuShowRef = useRef(false);

  const displayPlaceholder =
    placeholder ||
    (selectedAction === 'ai'
      ? t('composer.placeholder.ai')
      : t('composer.placeholder.default'));

  const clearText = useCallback(() => {
    setBlocks([]);
    commands.clearContent();
  }, [commands]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.stopPropagation(); // prevent trigger other keyboard shortcut such as quiz navigation shortcuts

      const pressEnter = event.key === 'Enter' && !event.shiftKey;
      const notUsingSlashMenu = !slashMenuShowRef.current;

      if (
        onSubmit &&
        pressEnter &&
        !sendMessageDisabled &&
        notUsingSlashMenu &&
        (!disableSubmitWhileSubmitting || !submitting)
      ) {
        setTimeout(() => {
          // use setTimeout to prevent editor abnormal status cause system crash
          clearText();
        });
        setSelectedAction(undefined);
        aiActionState.setSelectedAgent(null);

        setSubmitting(true);
        event.preventDefault();
        Promise.resolve(onSubmit({ event, text, blocks })).then(() =>
          setSubmitting(false)
        );
        return true; // return true to prevent default behavior
      }
      return undefined;
    },
    [
      aiActionState,
      blocks,
      clearText,
      disableSubmitWhileSubmitting,
      onSubmit,
      sendMessageDisabled,
      setSelectedAction,
      submitting,
      text,
    ]
  );

  const handleSendButtonClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      setTimeout(() => {
        // use setTimeout to prevent editor abnormal status cause system crash
        clearText();
      });
      setSelectedAction(undefined);
      aiActionState.setSelectedAgent(null);

      setSubmitting(true);
      await onSubmit?.({ event, text, blocks });
      setSubmitting(false);
    },
    [aiActionState, blocks, clearText, onSubmit, setSelectedAction, text]
  );

  const handleBlockChange = useCallback(
    (changedBlocks: ComposerBlock[]) => {
      setBlocks(changedBlocks);

      /**
       * We will both save 'blocks' and 'html' to a thread's message,
       * inside app logic, we only use 'blocks' to render,
       * but we would like to keep html inside message for further usage (e.g. make it more readable for LLM)
       */
      onChange?.(composerBlocksToHtml(changedBlocks));
    },
    [composerBlocksToHtml, onChange]
  );

  const { dataset: mentionItems, setQueryText: setMentionQueryText } =
    useThreadMentionItems(threadMemberIds);

  useEffect(() => {
    if (cid) {
      openMentionMenuMap.current.set(cid, (): void => {
        commands.openMentionMenu();
      });
    }
  }, [cid, commands, openMentionMenuMap]);

  useEffect(() => {
    insertContent.current = (content: string): void => {
      commands.insertContent(content);
    };
  }, [commands, insertContent]);

  const renderTextField = (
    <>
      <Box
        sx={[styles.root, disabled && styles.disabled, ...sxProps]}
        className="thread-composer-root"
      >
        <Box sx={styles.messageBox}>
          <Box sx={styles.textContainer}>
            <Scrollbar sx={styles.textScroll}>
              {renderTextComposer({
                autoFocus: autoFocus,
                defaultBlocks: [],
                onBlocksChange: handleBlockChange,
                placeholder: displayPlaceholder,
                onKeyDown: handleKeyDown,
                onSlashMenuShowHide: (show) =>
                  (slashMenuShowRef.current = show),
                disabled: disabled,
                mentionItems: mentionItems,
                onMentionQueryChange: setMentionQueryText,
                validateDroppableFile: validateThreadElementsBeforeInserting,
              })}
            </Scrollbar>
          </Box>
        </Box>
      </Box>
      <SendPubliclyCheckbox disabled={disabled} />
      <Actions
        onSendButtonClick={handleSendButtonClick}
        submitting={submitting}
        disabled={disabled}
        addElementItems={addElementItems}
      />
    </>
  );

  return disabledTip ? (
    <SimpleTooltip
      title={disabledTip}
      followCursor
      slotProps={{
        popper: {
          sx: styles.tooltip,
        },
      }}
    >
      <div>{renderTextField}</div>
    </SimpleTooltip>
  ) : (
    renderTextField
  );
}

export default function ThreadComposerWrapper(props: ThreadComposerProps) {
  return (
    <TextComposerProvider>
      <ThreadComposer {...props} />
    </TextComposerProvider>
  );
}
