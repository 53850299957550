import React from 'react';

export default function ActionArrowLeftDown({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.9074 9.62012C7.45031 9.59971 7.92573 10.0244 7.96927 10.5687L8.26838 14.3076L16.3018 6.27417C16.6786 5.89732 17.3029 5.9106 17.6962 6.30384C18.0894 6.69708 18.1027 7.32136 17.7258 7.69821L9.69242 15.7316L13.4313 16.0307C13.9756 16.0743 14.4003 16.5497 14.3799 17.0926C14.3595 17.6355 13.9017 18.0403 13.3574 17.9968L7.42396 17.5221C6.92438 17.4822 6.51785 17.0756 6.47788 16.576L6.00321 10.6426C5.95967 10.0983 6.36449 9.64053 6.9074 9.62012Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
