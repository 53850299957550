import { useContext, useEffect } from 'react';
import { QueueWorkerContext } from '@front/helper';

export default function useQueueWorkerInterrupt() {
  const { queueMap, workerIsProgressMap } = useContext(QueueWorkerContext);

  useEffect(() => {
    const routeChange = (ev: BeforeUnloadEvent) => {
      const isAnyTaskInsideAnyQueue =
        queueMap?.current &&
        Object.values(queueMap?.current).some(
          (q) => q?.filter((item) => item.shouldInterrupt).length
        );

      const isAnyWorkerInProgress =
        workerIsProgressMap?.current &&
        Object.values(workerIsProgressMap?.current).some(
          (item) => item?.shouldInterrupt
        );

      if (isAnyTaskInsideAnyQueue || isAnyWorkerInProgress) {
        ev.preventDefault();

        return (ev.returnValue =
          'You have unsaved changes - are you sure you wish to close?');
      }
      return '';
    };

    window.addEventListener('beforeunload', routeChange);

    return () => {
      window.removeEventListener('beforeunload', routeChange);
    };
    // ignore it because eslint does not recognize queues is a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
