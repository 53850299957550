import { createContext, ReactNode } from 'react';

type CreatorQuestionConfigContextValue = {
  statusType: 'publish' | 'review';
  getQuestionPath: (questionId: string) => string;
  enableFeature: {
    createNewQuestion: boolean;
    toolbarMoreAction?: boolean;
  };
};

const defaultValue: CreatorQuestionConfigContextValue = {
  statusType: 'publish',
  getQuestionPath: () => '',
  enableFeature: {
    createNewQuestion: false,
    toolbarMoreAction: false,
  },
};

export const CreatorQuestionConfigContext =
  createContext<CreatorQuestionConfigContextValue>(defaultValue);

type CreatorQuestionConfigProviderProps = {
  children: ReactNode;
  value?: CreatorQuestionConfigContextValue;
};

export function CreatorQuestionConfigProvider({
  value = defaultValue,
  children,
}: CreatorQuestionConfigProviderProps) {
  return (
    <CreatorQuestionConfigContext.Provider value={value}>
      {children}
    </CreatorQuestionConfigContext.Provider>
  );
}
