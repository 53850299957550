import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { OtherInfoSolid as OtherInfoSolidIcon } from '@front/icon';

import { SimpleTooltip } from '../../atoms';

const styles = {
  inputLabel: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
  titleSuffix: {
    ml: 'auto',
    color: '#BDBDBD',
    fontSize: 12,
  },
  labelTooltip: {
    maxWidth: '200px',
    '& ul': {
      m: 0,
      pl: '10px',
    },
  },
  labelTooltipIcon: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.5,
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        opacity: 1,
      },
    },
  },
};

type LabelIconProps = {
  icon?: ReactNode | null;
  suffixIcon?: ReactNode;
  label?: ReactNode;
  suffix?: ReactNode;
  required?: boolean;
  labelTooltip?: string;
};

function LabelIcon({
  icon,
  suffixIcon,
  label,
  suffix,
  required,
  labelTooltip,
}: LabelIconProps) {
  return (
    <Box sx={styles.inputLabel}>
      {icon}
      <Typography variant="caption">{label}</Typography>
      {required && (
        <Typography variant="caption" color="#E62937">
          *
        </Typography>
      )}
      {labelTooltip && (
        <SimpleTooltip
          title={
            <Box
              dangerouslySetInnerHTML={{
                __html: labelTooltip,
              }}
            />
          }
          slotProps={{
            popper: {
              sx: styles.labelTooltip,
            },
          }}
        >
          <Box sx={styles.labelTooltipIcon}>
            <OtherInfoSolidIcon width={16} height={16} />
          </Box>
        </SimpleTooltip>
      )}
      {suffixIcon}
      {suffix && (
        <Typography variant="caption" sx={styles.titleSuffix}>
          {suffix}
        </Typography>
      )}
    </Box>
  );
}

export default LabelIcon;
