import { Fragment, MouseEvent, ReactNode, useState } from 'react';
import { ListSubheader } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {
  ActionChevronFilledRight as ActionChevronFilledRightIcon,
  ActionChevronLeft as ActionChevronLeftIcon,
} from '@front/icon';
import { uniq } from 'lodash';

import Scrollbar from '../../../atoms/Scrollbar';
import BottomSheet, { BottomSheetProps } from '../../BottomSheet';

type BaseOption<T> = {
  display: ReactNode;
  options?: T[];
  section?: string;
};

export type BottomDropdownProps<T extends BaseOption<T>> = {
  options: T[];
  open: boolean;
  label?: string;
  maxShowItem?: number;
  value?: string | null;
  onClick?: (option: T, ev: MouseEvent) => void;
  renderOption?: (option: T, disabled: boolean) => ReactNode;
  isOptionDisabled?: (option: T) => boolean;
  isOptionEqualToValue?: (option: T) => boolean;
  onClose: () => void;
  bottomSheetProps?: Omit<BottomSheetProps, 'open' | 'onClose' | 'children'>;
};

const styles = {
  button: {
    height: 45,
    px: 2.5,
    width: '100%',
    justifyContent: 'flex-start',
    typography: 'body1',
  },
  main: {
    flex: 1,
    display: 'flex',
  },
  header: {
    px: 2.5,
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 2,
  },
  label: {
    opacity: 0.5,
  },
  selectedButton: {
    backgroundColor: 'text.primary',
    color: 'background.darker',
  },
  toggleIcon: {
    ml: 'auto',
    width: 24,
    height: 24,
  },
};
export default function BottomDropdown<T extends BaseOption<T>>({
  label,
  options,
  open,
  maxShowItem = 8,
  onClose,
  onClick,
  renderOption,
  isOptionDisabled,
  isOptionEqualToValue,
  bottomSheetProps = {},
}: BottomDropdownProps<T>) {
  const [activeItem, setActiveItem] = useState<T | null>(null);

  const labelHeight = label || activeItem ? 48 : 0; // header's height
  const optionsLength = (activeItem?.options || options).length;
  const scrollHeight = Math.min(optionsLength, maxShowItem) * 45 + 12; // padding bottom

  const displayOptions = activeItem?.options || options;
  const sectionDisplays = uniq(displayOptions.map((option) => option.section));
  const sections = sectionDisplays.map((display) => ({
    display: display || '',
    sectionOptions: displayOptions.filter(
      (option) => option.section === display
    ),
  }));

  const handleClick = (option: T, ev: MouseEvent) => {
    if (option.options) {
      setActiveItem(option);
    } else {
      onClick?.(option, ev);
    }
  };

  const handleBackClick = () => {
    setActiveItem(null);
  };

  const defaultHeight = scrollHeight + labelHeight + 40;
  return (
    <BottomSheet
      open={open}
      onClose={onClose}
      defaultHeight={defaultHeight}
      maxHeight={defaultHeight}
      disableDrag
      simpleBackdrop
      {...bottomSheetProps}
    >
      {activeItem ? (
        <ButtonBase sx={styles.header} onClick={handleBackClick}>
          <ActionChevronLeftIcon />
          <Typography sx={styles.label} variant="subtitle2" fontWeight={400}>
            {activeItem.display}
          </Typography>
        </ButtonBase>
      ) : (
        <Box>
          {!!label && (
            <Box sx={styles.header}>
              <Typography
                sx={styles.label}
                variant="subtitle2"
                fontWeight={400}
              >
                {label}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Scrollbar sx={{ height: scrollHeight }}>
        {sections.map(({ display, sectionOptions }) => (
          <Fragment key={display}>
            {display && <ListSubheader>{display}</ListSubheader>}
            {sectionOptions.map((option, i) => {
              const selected = isOptionEqualToValue?.(option) || false;
              const disabled = isOptionDisabled
                ? isOptionDisabled(option)
                : false;

              return (
                <ButtonBase
                  key={i}
                  sx={[styles.button, selected && styles.selectedButton]}
                  onClick={(ev) => handleClick(option, ev)}
                >
                  <Box sx={styles.main}>
                    {renderOption
                      ? renderOption(option, disabled)
                      : option.display}
                  </Box>
                  {!!option.options && (
                    <Box sx={styles.toggleIcon}>
                      <ActionChevronFilledRightIcon />
                    </Box>
                  )}
                </ButtonBase>
              );
            })}
          </Fragment>
        ))}
      </Scrollbar>
    </BottomSheet>
  );
}
