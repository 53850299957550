import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

type Options = {
  horizontal?: boolean;
};

export const useTruncated = <T extends HTMLElement = HTMLElement>(
  options: Options = {}
) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const containerRef = useRef<T>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const { horizontal } = options;

  useEffect(() => {
    if (!horizontal) {
      setIsTruncated(
        Boolean(
          mdUp &&
            containerRef.current &&
            containerRef.current.scrollWidth > containerRef.current.clientWidth
        )
      );
    } else {
      setIsTruncated(
        Boolean(
          mdUp &&
            containerRef.current &&
            containerRef.current.scrollHeight >
              containerRef.current.clientHeight
        )
      );
    }
  }, [mdUp, horizontal]);

  return {
    containerRef,
    isTruncated,
  };
};
