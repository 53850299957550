import { ParsedUrlQuery } from 'querystring';

export const getAllBreadcrumbsValues = (
  pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const clubSlug = query.clubSlug as string;
  const prefix = clubSlug ? `/club/form/${clubSlug}` : '/club/form';

  return {
    overview: {
      text: t('breadcrumb::club.create.Overview'),
      icon: 'MainApps',
      href: `${prefix}/overview`,
    },
    examSettings: {
      text: t('breadcrumb::club.create.examSettings'),
      icon: 'ProfileSetting',
      href: `${prefix}/exam-settings`,
    },
    editorSettings: {
      text: t('breadcrumb::club.create.editorSettings'),
      icon: 'ProfileSetting',
      href: `${prefix}/exam-settings`,
    },
    formats: {
      text: t('breadcrumb::club.create.Formats'),
      icon: 'ChatQuestionList',
      href: `${prefix}/formats`,
    },
    members: {
      text: t('breadcrumb::club.create.Members'),
      icon: 'PrivacyFriends',
      href: `${prefix}/members`,
    },
    manageBots: {
      text: t('breadcrumb::club.create.ManageBots'),
      icon: 'ProfileManageAIBot',
      href: `${prefix}/manage-bots`,
    },
    requests: {
      text: t('breadcrumb::club.create.Requests'),
      icon: 'OtherAddFriend',
      href: `${prefix}/requests`,
    },
  };
};
