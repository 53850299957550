import { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import { useAuth } from '@lib/web/apis';

export default function ReferQueryKeeper() {
  const { query, asPath } = useRouter();
  const { member } = useAuth();

  useEffect(() => {
    const currentRefer = query.refer;
    const newRefer = member?.distinctName;

    if (!newRefer || currentRefer === newRefer || query.blocker) return;

    const hashIndex = asPath.indexOf('#');
    const currentHash = hashIndex !== -1 ? asPath.substring(hashIndex) : '';

    void Router.replace(
      {
        hash: currentHash,
        query: {
          ...query,

          /**
           * In this hook, our URL always contains "refer"
           * When a student shares the page with friends, and friends use this referral information to sign up,
           * the student receives referral points.
           */
          refer: newRefer,
        },
      },
      undefined,
      { shallow: true }
    );
  }, [asPath, member, query]);

  return null;
}
