import { TableLayoutFooterCell } from '../../types';

import IaFooterNumberCell from './IaFooterNumberCell';
import IaFooterTextCell from './IaFooterTextCell';

export type IaTableFooterCellProps = {
  cell: TableLayoutFooterCell;
};

export default function IaTableFooterCell({ cell }: IaTableFooterCellProps) {
  switch (cell.type) {
    case 'text':
      return <IaFooterTextCell cell={cell} />;
    case 'number':
      return <IaFooterNumberCell cell={cell} />;
    default:
      console.warn('Unknown body cell type:', cell);
      return null;
  }
}
