import { createContext } from 'react';

type ItemStatus = {
  disabled?: boolean;
  selected?: boolean;
  loading?: boolean;
  focused?: boolean;
  disabledTooltips?: string[];
};

export type IaItemStatusContextValue<T> = {
  getItemStatus?: (ev: T) => ItemStatus;
  getItemStatusAsync?: (ev: T) => Promise<ItemStatus | null>;
};

const IaItemStatusContext = createContext<IaItemStatusContextValue<any>>({});

export default IaItemStatusContext;
