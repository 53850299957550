import { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMeasure from 'react-use-measure';
import { Popper } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useFollowMouseAnchor } from '@front/helper';
import { Icon, TextButton } from '@front/ui';
import { ResizeObserver } from '@juggle/resize-observer';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3Selection from 'd3-selection';

const shortName = (name: string, maxLength = 8) => {
  if (name.length <= maxLength) return name;
  return `${name.slice(0, maxLength)}...`;
};

const styles = {
  root: {
    width: '100%',
  },
  seeMore: {
    display: 'flex',
    justifyContent: 'flex-end',
    mb: 3,
  },
  seeLess: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chart: {
    width: '100%',

    '.x-axis': {
      typography: 'caption',
      color: 'grey.300',
      path: {
        color: 'alpha.lightA10',
      },
      line: {
        color: 'alpha.lightA10',
      },
    },
    '.y-axis': {
      typography: 'body1',

      '.tick text': {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
      },
    },
    '.bars': {
      typography: 'caption',
      fontWeight: 700,

      g: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
      },
    },
  },
  svg: {
    width: '100%',
    height: '100%',
  },
};

export type BarChartDataItem = {
  label: string;
  value: number;
  tooltip?: {
    content: ReactNode;
  };
  onClick?: () => void;
};

const BAR_HEIGHT = 24;
const BAR_PADDING = 16;
const X_AXIS_TOP_PADDING = 0;
const X_AXIS_PADDING_TOP = 8;
const X_AXIS_FONT_LINE_HEIGHT = 17;
const X_AXIS_PADDING_BOTTOM = 8;

const PADDING = {
  top: 0,
  right: 16, // buffer for x-axis label
  bottom: 30, // height of x-axis label
  left: 100, // width of y-axis label
};

const useDrawBarChart = ({
  data,
  hoveredIndex,
  handleMouseMove,
  handleMouseLeave,
  handleClick,
}: {
  data: BarChartDataItem[];
  hoveredIndex: number | null;
  handleMouseMove: (ev: MouseEvent, index: number) => void;
  handleMouseLeave: () => void;
  handleClick: (ev: MouseEvent, index: number) => void;
}) => {
  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver });

  const barsHeight = useMemo(() => {
    return BAR_HEIGHT * data.length + BAR_PADDING * data.length;
  }, [data]);

  const chartHeight = useMemo(() => {
    return (
      barsHeight +
      X_AXIS_TOP_PADDING +
      X_AXIS_PADDING_TOP +
      X_AXIS_FONT_LINE_HEIGHT +
      X_AXIS_PADDING_BOTTOM
    );
  }, [barsHeight]);

  const yScale = useMemo(() => {
    return d3Scale
      .scaleBand()
      .domain(data.map((d) => shortName(d.label)))
      .range([PADDING.top, PADDING.top + barsHeight])
      .paddingOuter(0);
  }, [data, barsHeight]);

  const xScale = useMemo(() => {
    const maxValue = d3Array.max(data, (d) => d.value) || 0;
    return d3Scale
      .scaleLinear()
      .domain([0, maxValue])
      .nice()
      .range([PADDING.left, width - PADDING.right]);
  }, [data, width]);

  const chartData = useMemo(() => {
    return data.map((d) => ({
      ...d,
      y: yScale(shortName(d.label)) || 0,
      x: xScale(0),
      width: xScale(d.value) - xScale(0),
      height: BAR_HEIGHT,
      bandWidth: yScale.bandwidth(),
    }));
  }, [data, yScale, xScale]);

  const xAxisTickValues = useMemo(() => {
    const maxValue = xScale.domain()[1];
    if (maxValue < 4) return xScale.ticks(maxValue);
    return [0, maxValue / 3, (2 * maxValue) / 3, maxValue];
  }, [xScale]);

  const xAxis = useMemo(() => {
    return d3Axis
      .axisBottom(xScale)
      .tickValues(xAxisTickValues)
      .tickFormat(d3Format.format('.2d'))
      .tickPadding(X_AXIS_PADDING_TOP);
  }, [xScale, xAxisTickValues]);

  const yAxis = useMemo(
    () => d3Axis.axisLeft(yScale).tickSize(0).tickPadding(8),
    [yScale]
  );

  const xAxisRef = useCallback(
    (node: SVGGElement | null) => {
      if (node) d3Selection.select(node).attr('class', 'x-axis').call(xAxis);
    },
    [xAxis]
  );

  const yAxisRef = useCallback(
    (node: SVGGElement | null) => {
      if (node) {
        const yAxisSelection = d3Selection
          .select(node)
          .attr('class', 'y-axis')
          .style('text-anchor', 'start')
          .call(yAxis);

        yAxisSelection.selectAll('path').remove();

        yAxisSelection
          .selectAll('.tick text')
          .style('opacity', (_, i) =>
            hoveredIndex === null || hoveredIndex === i ? 1 : 0.3
          )
          .on('mouseenter', (event, d) => {
            handleMouseMove(event, yScale.domain().indexOf(d as string));
          })
          .on('mouseleave', () => {
            handleMouseLeave();
          })
          .on('click', (event, d) => {
            handleClick(event, yScale.domain().indexOf(d as string));
          });
      }
    },
    [
      yAxis,
      hoveredIndex,
      yScale,
      handleMouseMove,
      handleMouseLeave,
      handleClick,
    ]
  );

  return {
    ref,
    xScale,
    xAxisTickValues,
    xAxisRef,
    yAxisRef,
    chartData,
    barsHeight,
    chartHeight,
  };
};

export type BarChartProps = Omit<BoxProps, 'children'> & {
  data: BarChartDataItem[];
  color?: string;
  maxBars?: number;
  getSx?: (showAll: boolean) => BoxProps['sx'];
};
export default function BarChart({
  sx,
  data,
  color = '#FF2951',
  maxBars,
  getSx,
  ...rest
}: BarChartProps) {
  const { t } = useTranslation();

  const { palette } = useTheme();

  const enableSeeMore = maxBars && data.length > maxBars;
  const [showMore, setShowMore] = useState(false);
  const {
    hoveredIndex,
    anchor: tooltipAnchor,
    virtualReference: tooltipVirtualReference,
    handleMouseMove,
    handleMouseLeave,
  } = useFollowMouseAnchor();

  const sxProps = Array.isArray(sx) ? sx : [sx];
  const additionalSx = getSx?.(!enableSeeMore || showMore);
  const additionalSxProps = Array.isArray(additionalSx)
    ? additionalSx
    : [additionalSx];

  const dataToShow = useMemo(() => {
    if (!enableSeeMore) return data;
    return showMore ? data : data.slice(0, maxBars);
  }, [data, showMore, enableSeeMore, maxBars]);

  const handleClick = useCallback(
    (_: MouseEvent, i: number) => {
      data[i].onClick?.();
    },
    [data]
  );

  const {
    ref,
    xAxisTickValues,
    xAxisRef,
    xScale,
    yAxisRef,
    chartData,
    barsHeight,
    chartHeight,
  } = useDrawBarChart({
    data: dataToShow,
    hoveredIndex,
    handleMouseMove,
    handleMouseLeave,
    handleClick,
  });

  return (
    <Box sx={[styles.root, ...sxProps, ...additionalSxProps]}>
      {enableSeeMore && !showMore && (
        <Box sx={styles.seeMore}>
          <TextButton
            suffixIcon={
              <Icon name="ActionChevronRightSmall" width={16} height={16} />
            }
            onClick={() => setShowMore(true)}
          >
            {t('See more')}
          </TextButton>
        </Box>
      )}
      <Box
        ref={ref}
        sx={[styles.chart, { height: `${chartHeight}px` }]}
        {...rest}
      >
        <svg style={styles.svg}>
          {xAxisTickValues.map((tick, i) => (
            <line
              key={i}
              x1={xScale(tick)}
              y1={PADDING.top}
              x2={xScale(tick)}
              y2={PADDING.top + barsHeight + X_AXIS_TOP_PADDING}
              stroke={palette.alpha.lightA10}
              strokeDasharray={i !== 0 ? '4,2' : undefined}
            />
          ))}
          <g
            transform={`translate(0,${
              PADDING.top + barsHeight + X_AXIS_TOP_PADDING
            })`}
            ref={xAxisRef}
          />
          <g transform={`translate(8,0)`} ref={yAxisRef} />

          <g className="bars">
            {chartData.map((d, i) => (
              <g
                key={i}
                onMouseMove={(ev) => handleMouseMove(ev, i)}
                onMouseLeave={handleMouseLeave}
                onClick={d.onClick}
                opacity={hoveredIndex !== null && hoveredIndex !== i ? 0.3 : 1}
              >
                <rect
                  x={d.x}
                  y={d.y + (d.bandWidth - BAR_HEIGHT) / 2}
                  rx={4}
                  width={d.width}
                  height={d.height}
                  fill={color}
                />
                <text
                  x={d.x + 8}
                  y={d.y + (d.bandWidth - BAR_HEIGHT) / 2 + BAR_HEIGHT / 2}
                  dy=".35em"
                  fill="white"
                >
                  {d.value}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </Box>
      {enableSeeMore && showMore && (
        <Box sx={styles.seeLess}>
          <TextButton
            suffixIcon={<Icon name="ActionChevronUp" width={16} height={16} />}
            onClick={() => setShowMore(false)}
          >
            {t('Hide')}
          </TextButton>
        </Box>
      )}
      {hoveredIndex !== null &&
        tooltipAnchor &&
        chartData[hoveredIndex].tooltip && (
          <Popper
            open
            anchorEl={tooltipVirtualReference}
            placement="bottom-start"
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [15, 15],
                  },
                },
              ],
            }}
          >
            {chartData[hoveredIndex].tooltip?.content}
          </Popper>
        )}
    </Box>
  );
}
