import { alpha, Theme } from '@mui/material';
import Box from '@mui/material/Box';

import useTableSettings from '../../hooks/useTableSettings';
import { TableLayoutFooterRow } from '../../types';

import IaTableFooterCell from './IaTableFooterCell';

export type IaTableFooterProps = {
  row?: TableLayoutFooterRow;
  showCheckboxBackground?: boolean;
  hasBottomButtons?: boolean;
};

const styles = {
  root: {
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  rowBorder: {
    ':not(:first-child)': {
      boxShadow: (theme: Theme) =>
        `inset 0 1px 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
  rootCheckboxPadding: {
    ml: '-24px',
  },
};

export default function IaTableFooter({
  row,
  showCheckboxBackground = false,
  hasBottomButtons = false,
}: IaTableFooterProps) {
  const { layoutSetting } = useTableSettings();

  if (!row) return null;

  const { gridTemplateColumns, cells } = row;
  return (
    <Box
      sx={[
        styles.root,
        !!layoutSetting?.showRowBorder && hasBottomButtons && styles.rowBorder,
        showCheckboxBackground && styles.rootCheckboxPadding,
        { gridTemplateColumns },
      ]}
    >
      {cells.map((cell, index) => (
        <IaTableFooterCell key={index} cell={cell} />
      ))}
    </Box>
  );
}
