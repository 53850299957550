import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { TableLayoutFooterNumberCell } from '../../types';

export type IaFooterNumberCellProps = {
  cell: TableLayoutFooterNumberCell;
};

const styles = {
  root: {
    px: 1,
    py: '5.5px',
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  text: {
    textAlign: 'right',
    typography: 'numberBody3',
  },
};
export default function IaFooterNumberCell({ cell }: IaFooterNumberCellProps) {
  const { value } = cell;

  return (
    <Box sx={[styles.root]}>
      <Typography variant="body2" sx={[styles.text]}>
        {value}
      </Typography>
    </Box>
  );
}
