import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { BreadcrumbsValue } from '@front/ui';

import { getAllBreadcrumbsValues } from './breadcrumbsValues';

export const getBreadcrumbsValuesByRoute = (
  pathname: string,
  query: any,
  t: any
): BreadcrumbsValue[] => {
  const values = getAllBreadcrumbsValues(pathname, query, t);

  if (pathname.includes('overview')) {
    return [values.overview];
  }
  if (pathname.includes('exam-settings')) {
    return [values.examSettings];
  }
  if (pathname.includes('editor-settings')) {
    return [values.editorSettings];
  }
  if (pathname.includes('formats')) {
    return [values.formats];
  }
  if (pathname.includes('members')) {
    return [values.members];
  }
  if (pathname.includes('manage-bots')) {
    return [values.manageBots];
  }
  if (pathname.includes('requests')) {
    return [values.requests];
  }
  return [];
};

export default function useRouteBreadcrumbsValues(): BreadcrumbsValue[] {
  const { pathname, query } = useRouter();
  const { t } = useTranslation();

  return getBreadcrumbsValuesByRoute(pathname, query, t);
}
