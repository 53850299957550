import { useContext, useMemo } from 'react';
import Box from '@mui/material/Box';

import ContentItem from './components/ContentItem';
import MessageItem from './components/ContentItem/MessageItem';
import NotificationItem from './components/ContentItem/NotificationItem';
import MessageActionArea from './components/MessageActionArea';
import { ChannelLayoutContext } from './contexts/channelLayoutContext';
import { ChannelLayoutConfig } from './types';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export type ChannelLayoutProps = ChannelLayoutConfig;

export default function ChannelLayout({ items, settings }: ChannelLayoutProps) {
  const { pinMessageItems } = useContext(ChannelLayoutContext);

  const displayItems = useMemo(() => {
    return [...(pinMessageItems || []), ...items];
  }, [pinMessageItems, items]);

  return (
    <Box sx={styles.root}>
      {displayItems.map((_, index) => {
        let itemData = _;
        if (settings?.reverseItems) {
          const revertIndex = displayItems.length - 1 - index;
          itemData = displayItems[revertIndex];
        }
        return (
          <ContentItem
            {...itemData}
            hoverDisabled={settings?.hoverDisabled}
            size={settings?.size || 'md'}
            key={itemData.key || itemData.id || `${index}`}
          />
        );
      })}
    </Box>
  );
}

ChannelLayout.MessageActionArea = MessageActionArea;
ChannelLayout.MessageItem = MessageItem;
ChannelLayout.NotificationItem = NotificationItem;
