import React from 'react';

export default function ActionArrowLeft({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.66436 6.7526C10.0771 7.11952 10.1143 7.75159 9.74741 8.16437L7.22684 11L19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13L7.22684 13L9.74741 15.8356C10.1143 16.2484 10.0771 16.8805 9.66436 17.2474C9.25158 17.6143 8.61951 17.5772 8.25259 17.1644L4.25259 12.6644C3.9158 12.2855 3.9158 11.7145 4.25259 11.3356L8.25259 6.83565C8.61951 6.42286 9.25158 6.38568 9.66436 6.7526Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
