import React from 'react';

export default function ActionArrowUp({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.3356 4.25259C11.7145 3.9158 12.2855 3.9158 12.6644 4.25259L17.1644 8.25259C17.5772 8.61951 17.6143 9.25158 17.2474 9.66436C16.8805 10.0771 16.2484 10.1143 15.8356 9.74741L13 7.22684V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V7.22684L8.16437 9.74741C7.75159 10.1143 7.11952 10.0771 6.7526 9.66436C6.38568 9.25158 6.42286 8.61951 6.83565 8.25259L11.3356 4.25259Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
